import React, {ReactElement} from 'react';
import cx from 'classnames';
import styles from './ScrollTo.module.scss';

type ScrollToProps = {
    children: ReactElement | string;
    className?: string;
    defaultStyles?: boolean;
    scrollPosition?: number;
    scrollElementId?: string;
};

function getElementScrollPosition(id: string | undefined) {
    if (typeof id === "string") {
        // @ts-ignore
        const yOffset = -30;
        const element = document.getElementById(id);
        // @ts-ignore
        return element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    }
    return 0;
}

const ScrollTo: React.FC<ScrollToProps> = ({
    children,
    className,
    defaultStyles = true,
    scrollPosition = 0, // top by default
    scrollElementId
}) => (
    <a
        className={cx(className, { [styles.scrollTo]: defaultStyles })}
        onClick={() =>
            window.scrollTo({
                top: scrollPosition ? scrollPosition : getElementScrollPosition(scrollElementId),
                behavior: 'smooth'
            })
        }
    >
        {children}
    </a>
);

export default ScrollTo;
