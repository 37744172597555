import playSoundIfNotMuted from './playSound';

const isLastItem = (index: number, array: any[]) => index === array.length - 1;


let timeoutId: number;
const handleNext = (
    currentIntroIndex: number,
    incrementIndex: () => void,
    introData: any,
    onFinish: () => void,
) => {
    if (timeoutId) {
        clearTimeout(timeoutId);
    }
    if (!isLastItem(currentIntroIndex, introData)) {
        incrementIndex();

        const voiceFile = introData[currentIntroIndex + 1].voiceFile;
        if (voiceFile) {
            const { voiceTimeOut } = introData[currentIntroIndex + 1];

            voiceTimeOut ?
                timeoutId = setTimeout(() => playSoundIfNotMuted(voiceFile), voiceTimeOut) as unknown as number :
                playSoundIfNotMuted(voiceFile);
        }
    } else {
        onFinish();
    }
};

export default handleNext;