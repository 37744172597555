import React, {useState, useEffect, useMemo, useCallback} from 'react';
import debounce from 'lodash.debounce';
import moment from 'moment';
import cx from 'classnames';
import {DayPickerRangeController, FocusedInputShape} from 'react-dates';
import Checkbox from '../Checkbox/Checkbox';
import {FilterData, LabelData} from '../../filterData';
import {CalendarEventFilteringParams} from '../../../../services/calendar';

import styles from './FilterMenu.module.scss';

type FilterMenuProps = {
    filterSections: FilterData;
    onFilterUpdate: (filter: CalendarEventFilteringParams) => void;
}
const today = moment();

const FilterMenu: React.FC<FilterMenuProps> = ({filterSections, onFilterUpdate}) => {
    const [isActive, setActive] = useState(false);
    const [filtersChecked, setFiltersChecked] = useState({
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
    } as CalendarEventFilteringParams);
    const [datesChecked, setDatesChecked] = useState({} as CalendarEventFilteringParams); // this has to be separate, cos we only use the dates to filter after specific user actions
    const [showPicker, setShowPicker] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [expandedCards, setExpandedCards] = useState([] as string[]);
    const [startDate, setStartDate] = useState(null as null | moment.Moment);
    const [endDate, setEndDate] = useState(null as null | moment.Moment);
    const [focusedInput, setFocusedInput] = useState('startDate' as string | null);
    const debouncedUpdate = useMemo(
        () => debounce(
            (filter: CalendarEventFilteringParams) => onFilterUpdate(filter),
            1000,
        ),
        [onFilterUpdate],
    );

    useEffect(() => {
        if (Object.keys(filtersChecked).length) {
            debouncedUpdate(filtersChecked)
        }
    }, [filtersChecked]);

    const currentStartEndDate = useMemo(() => ({
        startDate: (datesChecked && datesChecked.startDate) || filtersChecked.startDate,
        endDate: (datesChecked && datesChecked.endDate) || filtersChecked.endDate,
    }), [datesChecked, filtersChecked]);

    const handleCheckUpdate = (sectionName: string, labelData: LabelData) => (e: React.ChangeEvent<HTMLInputElement>) => {
        // reference based fields use the reference ID value as a checked/unchecked property - other labels use boolean
        let changedLabel = labelData.value
            ? {[labelData.label]: e.target.checked ? labelData.value : ''}
            : {[labelData.label]: e.target.checked};
        setFiltersChecked({
            ...filtersChecked,
            [sectionName]: {
                ...filtersChecked[sectionName],
                ...changedLabel,
            },
            ...currentStartEndDate,
        } as CalendarEventFilteringParams);
    };

    const handleDatesUpdate = ({startDate: start, endDate: end}: { startDate: moment.Moment | null, endDate: moment.Moment | null }) => {
        if (!startDate || (start && !start.isSame(startDate))) {
            setStartDate(start);
        }
        if (!endDate || (end && !end.isSame(endDate))) {
            setEndDate(end);
            setFocusedInput('startDate');
        }
    };

    const handleDatesAdd = useCallback((addMonth: number) => () => {
        const {startDate, endDate} = currentStartEndDate;
        setActive(true);

        const newDates = {
            startDate: moment(startDate).add(addMonth, 'M'),
            endDate: moment(endDate).add(addMonth, 'M'),
        } as CalendarEventFilteringParams;

        if ((moment(newDates.startDate).format('Y-M') == moment().format('Y-M'))) {
            newDates.startDate = moment().startOf('day');
        }

        if (datesChecked.startDate && datesChecked.endDate) {
            setDatesChecked(newDates);
        }

        const startDateTmp = newDates.startDate.clone();

        if ((addMonth < 0 && (moment(newDates.startDate).format('Y-M') == moment().format('Y-M'))) || (moment(startDateTmp).add(-1, 'M').format('Y-M') == moment().format('Y-M'))) {

            if (moment(newDates.startDate).format('Y-M') == moment().format('Y-M')) {
                setActive(false);
            }
        }

        setFiltersChecked({
            ...filtersChecked,
            ...newDates,
        });
    }, [filtersChecked, datesChecked]);

    const handlePickerClose = (e?: any) => {
        // if the toggle button was clicked do not close
        if (!e || !e.target.className.includes('togglePicker')) {
            setShowPicker(false);
            setFocusedInput('startDate');
        }
    };

    const renderSection = (sectionName: string, labels: LabelData[]) => (
        <section>
            {
                labels.map(checkboxItem => (
                    <Checkbox
                        key={`${sectionName}-${checkboxItem.label}`}
                        label={checkboxItem.label}
                        checked={filtersChecked[sectionName] && (filtersChecked[sectionName] as any)[checkboxItem.label]}
                        onChange={handleCheckUpdate(sectionName, checkboxItem)}
                    />
                ))
            }
        </section>
    );

    const renderAcceptButton = () => (
        <div className={styles.calendarButton}>
            <span onClick={handleDateApproved}>WÄHLEN</span>
        </div>
    );

    const handleDateApproved = useCallback(() => {
        if (endDate && startDate) {
            const approvedDates = {
                startDate,
                endDate,
            } as CalendarEventFilteringParams;
            setDatesChecked(approvedDates);
            setFiltersChecked({
                ...filtersChecked,
                ...approvedDates,
            });
            handlePickerClose();
        }
    }, [filtersChecked, startDate, endDate]);

    const handleClearFilterClick = useCallback(() => {
        setFiltersChecked({
            ...currentStartEndDate,
        } as CalendarEventFilteringParams);
    }, [currentStartEndDate]);

    const handleClearDatesClick = useCallback(() => {
        setStartDate(null);
        setEndDate(null);
        setFiltersChecked({
            ...filtersChecked,
            startDate: moment(datesChecked.startDate).startOf('M'),
            endDate: moment(datesChecked.startDate).endOf('M'),
        } as CalendarEventFilteringParams);
        setDatesChecked({} as CalendarEventFilteringParams);
    }, [filtersChecked, datesChecked]);

    const toggleCardExpanded = (cardTitle: string) => () => {
        const card = expandedCards.find(title => title === cardTitle);
        if (card) {
            setExpandedCards(expandedCards.filter(title => title !== cardTitle));
        } else {
            setExpandedCards([...expandedCards, cardTitle]);
        }
    };

    return (
        filterSections
            ? <>
                <div
                    className={styles.expandFilterButton}
                    onClick={() => setShowFilter(!showFilter)}
                >
                    FILTERN
                </div>
                <div className={cx(styles.filterWrapper, {[styles.shown]: showFilter})}>
                    <div
                        className={styles.clearFilter}
                        onClick={handleClearFilterClick}
                    >
                        <span>FILTER ZURÜCKSETZEN</span>
                    </div>
                    {
                        Object.keys(filterSections).map(sectionName => {
                            const card = filterSections[sectionName];
                            const expanded = expandedCards.includes(card.title);
                            return (
                                <div key={sectionName}
                                     className={cx(styles[`${sectionName}Card`], {[styles.expanded]: expanded})}>
                                    <h3>{card.title}</h3>
                                    <div
                                        className={cx(styles.expandCardButton, {[styles.expanded]: expanded})}
                                        onClick={toggleCardExpanded(card.title)}
                                    />
                                    <div className={cx(styles.cardBody, {[styles.expanded]: expanded})}>
                                        {renderSection(sectionName, card.sections.left)}
                                        {card.sections.right && renderSection(sectionName, card.sections.right)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={styles.dateWrapper}>
                    <div className={styles.currentMonth}>
                        <div className={cx(styles.prevMonth, (isActive) ? '' : styles.prevent)} onClick={handleDatesAdd(-1)}/>
                        <span>{filtersChecked && filtersChecked.startDate && filtersChecked.startDate.format('MMMM')}</span>
                        <div className={styles.nextMonth} onClick={handleDatesAdd(1)}/>
                    </div>
                    <div className={styles.datePicker}>
                        <div
                            className={styles.togglePicker}
                            onClick={() => setShowPicker(!showPicker)}
                        >
                            ZEITRAUM WÄHLEN
                        </div>
                        <div className={styles.datesWrapper}>
                            {
                                datesChecked && datesChecked.startDate && datesChecked.endDate &&
                                <>
                                    <div className={styles.currentDates} onClick={() => setShowPicker(!showPicker)}>
                                        {datesChecked.startDate.format('dd, D.MM.YY')} - {datesChecked.endDate.format('dd, D.MM.YY')}
                                    </div>
                                    <div
                                        className={styles.clearDatesButton}
                                        onClick={handleClearDatesClick}
                                    />
                                </>
                            }
                        </div>
                        <div className={styles.calendarWrapper}>
                            {/*
                                // @ts-ignore */}
                            {showPicker && <DayPickerRangeController
                                startDate={startDate}
                                endDate={endDate}
                                onDatesChange={handleDatesUpdate}
                                focusedInput={focusedInput as FocusedInputShape}
                                onFocusChange={focusedInput => setFocusedInput(focusedInput ? '' + focusedInput : 'startDate')}
                                initialVisibleMonth={endDate ? () => endDate : undefined}
                                keepOpenOnDateSelect={true}
                                minimumNights={0}
                                onOutsideClick={handlePickerClose}
                                onClose={handlePickerClose}
                                renderCalendarInfo={renderAcceptButton}
                                minDate={today}
                            />
                            }
                        </div>
                    </div>
                </div>
            </>
            : null
    )
}

export default FilterMenu;
