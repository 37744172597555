import React, {CSSProperties, useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import throttle from 'lodash.throttle';
import styles from '../../games/Detective/containers/DetectiveOutro/DetectiveOutro.module.scss';
import playSoundIfNotMuted from '../../helpers/playSound';
import outroModalData from './outroModalData';
import Modal, {ModalType} from '../Modal/Modal';
import ModalImage from './assets/rhino-outro-modal.png';
import {INLINES} from "@contentful/rich-text-types";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";

interface OutroModalProps extends Pick<RouteComponentProps, 'history'> {
    style?: CSSProperties;
    infoForVisitorsSlideNumber: number,
    winning_text: any,
    voiceFile?: string,
}

const isDesktop = () => window.innerWidth > parseInt(styles.desktopWidth);

const OutroModal: React.FC<OutroModalProps> = ({history, style, infoForVisitorsSlideNumber, winning_text, voiceFile}) => {

    useEffect(() => {
        if (voiceFile) {
            playSoundIfNotMuted(voiceFile)
        }
    }, [voiceFile]);

    const [modalType, setModalType] = useState(ModalType.withImage);

    const handleResize = () => {
        if (isDesktop() && modalType === ModalType.long) {
            setModalType(ModalType.withImage);
        } else if (!isDesktop() && modalType === ModalType.withImage) {
            setModalType(ModalType.long);
        }
    };

    useEffect(() => {
        handleResize();
        const throttledHandleResize = throttle(handleResize, 16);
        window.addEventListener('resize', throttledHandleResize);
        return () => window.removeEventListener('resize', throttledHandleResize);
    });

    const website_url = window.location.href;
    const options = {
        renderNode: {
            [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
                return ( <a href={node.data.target.fields.file.url} target="_blank"> {children} </a> )
            },
            [INLINES.HYPERLINK]: (node: any) => {
                return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
            }
        }
    };
    const winning_info_text = documentToReactComponents(winning_text.text, options);

    return (
        <Modal
            alternateButtonCopy={outroModalData.alternativeButtonText}
            buttonCopy={outroModalData.buttonText}
            onButtonClick={() => {
                history.push('/besucherinfo', {isKid: true, currentSlide: infoForVisitorsSlideNumber})
            }}
            onAlternateButtonClick={() => {
                history.push('/spiele')
            }}
            style={style}
            shouldShowGift={modalType === ModalType.long}
            imgSrc={modalType === ModalType.withImage ? ModalImage : undefined}
            type={modalType}
        >
            <>
                <h1>{winning_text.title}</h1>
                <div className={styles.modalCopy}>{winning_info_text}</div>
            </>
        </Modal>
    );
};

export default OutroModal;
