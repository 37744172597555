import React from 'react';
import Helmet from 'react-helmet';
import {Dispatch} from 'redux';
import {setCurrentScreen} from '../../../actions/common';
import {State} from '../../../interfaces/State';
import {ScreenName} from '../../../reducers/common';
import styles from './RestorerGame.module.scss';
import RestorerCore from './RestorerCore/RestorerCore';
import RestorerIntro from './RestorerIntro/RestorerIntro';
import {connect} from 'react-redux';
import RestorerOutro from './RestorerOutro/RestorerOutro';
import {isGameFinished, selectCurrentScreen} from '../selectors';
import {actionTypes} from '../actions';

type RestorerGameProps = {
    isGameFinished: boolean,
    handleGoToCore: () => void,
    handleGoToConversion: () => void,
    currentScreen: ScreenName,
}

const RestorerGame: React.FC<RestorerGameProps> = ({
   currentScreen,
   handleGoToCore,
   isGameFinished,
}) => {
    return (
        <div className={styles.RestorerGame}>
            <Helmet>
                <body className={styles.memoryGameBody} />
            </Helmet>
            {
                !isGameFinished
                &&
                currentScreen === 'intro'
                &&
                <RestorerIntro onFinish={handleGoToCore}/>
            }
            {
                !isGameFinished
                &&
                currentScreen === 'core'
                &&
                <RestorerCore/>
            }
            {
                isGameFinished
                &&
                (currentScreen === 'outro')
                &&
                <RestorerOutro />
            }
        </div>
    );
};

const mapStateToProps = (state: State) => ({
    isGameFinished: isGameFinished(state),
    currentScreen: selectCurrentScreen(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    handleGoToCore: () => dispatch(setCurrentScreen(actionTypes)('core')),
});

export default connect(mapStateToProps, mapDispatchToProps)(RestorerGame);
