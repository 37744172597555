import {AnyAction} from 'redux';
import cloneDeep from 'lodash.clonedeep';
import {setCurrentScreen} from '../../../reducers/common';
import {SetRoundAction} from '../actions/SetRoundAction';
import {SetShuffleAction} from '../actions/SetShuffleAction';
import {MemoryImageData, MemoryState, Score} from '../interfaces/MemoryState';
import {actionTypes} from '../actions';
import {AddPointAction} from '../actions/AddPointAction';

const initialState: MemoryState = {
    chosenTiles: {},
    chosenTilesHistory: {},
    currentPlayer: 'user',
    currentRound: 0,
    currentScreen: 'intro',
    currentShuffle: 0,
    currentIntroIndex: 0,
    gameScore: {
        user: 0,
        cpu: 0,
    },
    hasRoundJustStarted: true,
    hasRoundJustFinished: false,
    images: [],
    isFinished: false,
    isInterfaceLocked: false,
    points: {},
    roundScore: {
        user: 0,
        cpu: 0,
    },
    tiles: [],
};

const getInitialGameStateFromImages = (
    state: MemoryState,
    {images}: { images: MemoryImageData[][] },
): MemoryState => {

    return {
        ...state,
        images,
    }
};

const setRound = (state: MemoryState, {payload: {roundNumber}}: SetRoundAction): MemoryState => ({
    ...state,
    currentRound: roundNumber,
    currentShuffle: 0,
    hasRoundJustStarted: true,
    isInterfaceLocked: false,
    hasRoundJustFinished: false,
    currentPlayer: 'user',
});

const setShuffle = (
    state: MemoryState,
    {payload: {shuffleNumber, shuffledTiles}}: SetShuffleAction): MemoryState => {
    return ({
        ...state,
        currentShuffle: shuffleNumber,
        tiles: shuffledTiles,
    });
};

const addChosenTile = (state: MemoryState, action: AnyAction): MemoryState => ({
    ...state,
    chosenTiles: {
        ...state.chosenTiles,
        [action.payload.tileId]: action.payload.imageId,
    },
    chosenTilesHistory: {
        ...state.chosenTilesHistory,
        [action.payload.tileId]: action.payload.imageId,
    },
});

const addPoint = (state: MemoryState, action: AddPointAction): MemoryState => ({
    ...state,
    points: {
        ...state.points,
        [action.payload.imageId]: action.payload.player,
    },
});

const removeTiles = (state: MemoryState, action: AnyAction): MemoryState => {
    const stateClone = cloneDeep(state);
    action.payload.tiles.forEach((id: number) => {
        stateClone.tiles[id] = undefined;
    });
    action.payload.tiles.forEach((id: number) => {
        delete stateClone.chosenTilesHistory[id];
    });
    return stateClone;
};

const removeChosenTiles = (state: MemoryState, action: AnyAction): MemoryState => {
    const stateClone = cloneDeep(state);
    action.payload.chosenTiles.forEach((id: number) => {
        delete stateClone.chosenTiles[id];
    });
    return stateClone;
};

const setCurrentPlayer = (state: MemoryState, action: AnyAction): MemoryState => ({
    ...state,
    currentPlayer: action.payload.playerName,
});

const setInterfaceLockStatus = (state: MemoryState, action: AnyAction): MemoryState => ({
    ...state,
    isInterfaceLocked: action.payload.on,
});

const setRoundScore = (state: MemoryState, {roundScore}: { roundScore: Score }): MemoryState => ({
    ...state,
    roundScore,
});

const setGameScore = (state: MemoryState, {gameScore}: { gameScore: Score }): MemoryState => ({
    ...state,
    gameScore,
    hasRoundJustFinished: true,
});

const startRound = (state: MemoryState) => ({
    ...state,
    hasRoundJustStarted: false,
    isInterfaceLocked: false,
    roundScore: {
        cpu: 0,
        user: 0,
    },
    points: {},
});

const memoryReducer = (state: MemoryState = initialState, action: AnyAction): MemoryState => {
    switch (action.type) {
        case actionTypes.getImagesSuccess:
            return getInitialGameStateFromImages(state, action.payload);
        case actionTypes.addChosenTile:
            return addChosenTile(state, action);
        case actionTypes.addPoint:
            return addPoint(state, action as AddPointAction);
        case actionTypes.removeTiles:
            return removeTiles(state, action);
        case actionTypes.removeChosenTiles:
            return removeChosenTiles(state, action);
        case actionTypes.setCurrentPlayer:
            return setCurrentPlayer(state, action);
        case actionTypes.setCurrentScreen:
            return setCurrentScreen(state, action.payload);
        case actionTypes.setCurrentIntroIndex:
            return ({ ...state, currentIntroIndex: action.payload.index });
        case actionTypes.setRound:
            return setRound(state, action as SetRoundAction);
        case actionTypes.setGameScore:
            return setGameScore(state, action.payload);
        case actionTypes.setRoundScore:
            return setRoundScore(state, action.payload);
        case actionTypes.startRound:
            return startRound(state);
        case actionTypes.setShuffle:
            return setShuffle(state, action as SetShuffleAction);
        case actionTypes.setInterfaceLockStatus:
            return setInterfaceLockStatus(state, action);
        case actionTypes.finish:
            return { ...state, isFinished: true };
        default:
            return state;
    }
};

export default memoryReducer;