import React, {CSSProperties} from 'react';
import cx from 'classnames';
import styles from './QuizAnswer.module.scss';

type QuizAnswerProps = {
    answer: string,
    onClick?: () => void,
    style: CSSProperties,
    isChecked: boolean,
    isCorrect: boolean,
    isAnsweredCorrectly: boolean,
}

const QuizAnswer: React.FC<QuizAnswerProps> = ({answer, isChecked, isCorrect, isAnsweredCorrectly, onClick, style}) => (
    <div className={cx(styles.quizAnswer, {[styles.isChecked]: isChecked, [styles.correct]: (isAnsweredCorrectly || isCorrect)})}
         style={style} onClick={onClick}>
        {answer}
    </div>
);

export default QuizAnswer;