import {Dispatch} from 'redux';
import {stopSound} from '../../helpers/playSound';

export const setMutedActionType = 'playSound/setMuted';

export const setMuted = (isMuted: boolean) => (dispatch: Dispatch) => {
    stopSound();

    dispatch({
        type: setMutedActionType,
        payload: {
            isMuted,
        },
    });
};

