import React, {ReactElement} from 'react';
import cx from 'classnames';
import styles from './Button.module.scss';

type ButtonProps = {
    active?: boolean,
    children: ReactElement|string,
    className?: string,
    onClick?: () => void,
}

const Button: React.FC<ButtonProps> = ({ active = true, children, className, onClick }) => (
    <button className={cx(styles.button, className, { [styles.active]: active })} onClick={onClick}>
        {children}
    </button>
);

export default Button;