import React from 'react';
import ConstructionSiteData from "./ConstructionSiteIntroData";
import RhinoSpritesheet from "./assets/nele.png";
import PuppySpritesheet from "./assets/puppy.png";
import CraneSpritesheet from "./assets/crane.png";
import ConstructionSiteDecorationBox from "../components/ConstructionSiteDecorationBox/ConstructionSiteDecorationBox";
import {setApplicationCounterTimer} from "../../../../actions/common";

type ConstructionSiteIntroProps = {
    onFinish: () => void,
}

const ConstructionSiteIntro: React.FC<ConstructionSiteIntroProps> = ({
    onFinish
}) => {
    setApplicationCounterTimer();
    return (
        <ConstructionSiteDecorationBox
            puppyWidthFrame={380}
            puppyHeightFrame={245}
            PuppySpritesheet={PuppySpritesheet}
            rhinoWidthFrame={757}
            rhinoHeightFrame={1264}
            RhinoSpritesheet={RhinoSpritesheet}
            craneWidthFrame={1024}
            craneHeightFrame={889}
            CraneSpritesheet={CraneSpritesheet}
            constructionSiteScene={'Intro'}
            bubbleData={ConstructionSiteData}
            onFinish={onFinish}
        />
    );
};

export default ConstructionSiteIntro;
