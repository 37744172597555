import React, {Component} from 'react';
// @ts-ignore
import ModalVideo from 'react-modal-video';
import cx from 'classnames';
import styles from './VideoModalElement.module.scss';
import VideoBox from '../VideoBox/VideoBox';

interface VideoModalElementState {
    isOpen: boolean,
}

type VideoModalElementProps = {
    imgSrc: any,
    className?: string,
    classNameTv?: string,
    classNameWrapper?: string,
    channel: string,
    videoId: string,
};

class VideoModalElement extends Component<VideoModalElementProps, VideoModalElementState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: false
        };
        this.openModal = this.openModal.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    openModal () {
        this.setState({isOpen: true})
    }

    handleClose = () => {
        this.setState({
            isOpen: false
        })
    };

    render() {

        const {isOpen} = this.state;
        const {
            imgSrc,
            className,
            classNameTv,
            classNameWrapper,
            channel,
            videoId
        } = this.props;

        return (
            <div className={cx(styles.wrapper, {[styles.isOpen]: isOpen}, classNameWrapper)}>
                <ModalVideo
                    channel={channel}
                    isOpen={isOpen}
                    videoId={videoId}
                    onClose={this.handleClose}
                />
                <VideoBox
                    imgSrc={imgSrc}
                    onClick={this.openModal}
                    className={className}
                    classNameTv={classNameTv}
                />
            </div>
        )
    }
}

export default VideoModalElement;