import React from 'react';
import cx from 'classnames';
import styles from './GameInformation.module.scss';
import Counter from '../../../../../../components/Counter/Counter';

type GameInformationProps = {
    className?: string,
    dataLeft: number,
    dataRight?: number,
    divider?: string,
    text: string,
}

const GameInformation: React.FC<GameInformationProps> = ({className, dataLeft, dataRight, divider, text}) => (
    <div className={cx(styles.gameScore, className)}>
        <div className={styles.header}>{text}</div>
        <Counter
            dataLeft={dataLeft}
            divider={divider}
            dataRight={dataRight}
        />
    </div>
);

export default GameInformation;