// TODO keep this near mute component?
import {Dispatch} from 'redux';
import {store} from '../containers/App/App';

// this is kept outside function to ensure there is only one sound played at the same time
const audioHandle = new Audio();

export const stopSound = () => {
    audioHandle.load();
};

export const stopSpecificSound = (soundFile: string) => {
    if (soundFile === audioHandle.src) {
        audioHandle.load();
    }
};

const playSoundIfNotMuted = async (soundSrc: string) => {
    stopSound();
    audioHandle.src = soundSrc;

    // TODO make selector for it:
    if (!store.getState().isMuted) {
        try {
            await audioHandle.play();
        } catch {
            console.log(soundSrc);
            console.trace();
        }
    }
};

export default playSoundIfNotMuted;