import React from 'react';
import {connect} from 'react-redux';
import {AnyAction, Dispatch} from 'redux';
import {decrementCurrentIntroIndex, incrementCurrentIntroIndex} from '../../../../actions/common';
import Logo from '../../../../components/Logo/Logo';
import MuteButton from '../../../../components/MuteButton/MuteButton';
import Navigation from '../../../../components/Navigation/Navigation';
import OptionBox from '../../../../components/OptionBox/OptionBox';
import {State} from '../../../../interfaces/State';
import {actionTypes} from '../../actions';
import {selectCurrentIntroIndex} from '../../selectors';
import styles from './DetectiveIntro.module.scss';
import introData from './introData';
import IntroIndoor from './IntroIndoor/IntroIndoor';
import IntroOutdoor from './IntroOutdoor/IntroOutdoor';

type DetectiveIntroProps = {
    currentIntroIndex: number,
    decrementIndex: () => void,
    incrementIndex: () => void,
    onFinish: () => void;
};

const DetectiveIntro: React.FC<DetectiveIntroProps> = ({
    currentIntroIndex,
    decrementIndex,
    incrementIndex,
    onFinish,
}) => {
    // if introData file will change this might need too!
    const firstAnimationIndex: number = 2;
    const lastAnimationIndex: number = 6;

    const fadeAnimation = () => {
        setTimeout(() => {
            incrementIndex();
        }, 3000);
    };

    const finishAnimation = () => {
        setTimeout(() => {
            onFinish();
        }, 3000);
    };

    const Layer =
        (
            <div className={styles.hideLayer}>
                <p className={styles.layerText}>
                    {currentIntroIndex === firstAnimationIndex ? introData[0].layerText : null}
                </p>
            </div>
        );
    const HideLayer = currentIntroIndex === firstAnimationIndex ? Layer : null;
    const HideAnimation = currentIntroIndex === firstAnimationIndex ? fadeAnimation() : null;

    const lastHideLayer = currentIntroIndex === lastAnimationIndex ? Layer : null;
    const lastHideAnimation = currentIntroIndex === lastAnimationIndex ? finishAnimation() : null;

    return (

        <div className={styles.wrapper}>
            <div className={styles.margeSign}/>
            <OptionBox/>
            <MuteButton />
            <header>
                <Logo/>
            </header>
            {HideLayer}
            {HideAnimation}
            {lastHideLayer}
            {lastHideAnimation}
            {
                currentIntroIndex < (firstAnimationIndex + 1)
                    ?
                    (<IntroOutdoor
                        currentIntroIndex={currentIntroIndex}
                        onFinish={onFinish}
                        animationIndex={firstAnimationIndex}
                        incrementIndex={incrementIndex}
                    />)
                    :
                    (<IntroIndoor
                        currentIntroIndex={currentIntroIndex}
                        incrementIndex={incrementIndex}
                        onFinish={onFinish}
                    />)
            }
            <Navigation
                currentIndex={currentIntroIndex}
                decrementIndex={decrementIndex}
                mockData={introData}
                onFinish={onFinish}
                incrementIndex={incrementIndex}
            />
        </div>
    );
};

const mapStateToProps = (state: State) => ({
    currentIntroIndex: selectCurrentIntroIndex(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    decrementIndex: () => dispatch(decrementCurrentIntroIndex(actionTypes, selectCurrentIntroIndex) as unknown as AnyAction),
    incrementIndex: () => dispatch(incrementCurrentIntroIndex(actionTypes, selectCurrentIntroIndex) as unknown as AnyAction),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetectiveIntro);