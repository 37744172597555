import React from 'react';
import cx from 'classnames';
import styles from './BackLink.module.scss';

interface BackLinkProps {
    className?: string,
    href: string,
}

const BackLink: React.FC<BackLinkProps> = ({className, href}) => {
    return (
        <a
            className={cx(styles.linkBox, className)}
            href={`${process.env.PUBLIC_URL}${href}`}
        >
            <div className={styles.arrowIcon} />
            <span className={styles.linkText}>
                ZURÜCK
            </span>
        </a>
    )
};

export default BackLink;
