import React, {useState} from 'react';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import NavigationTopBar from '../../components/NavigationTopBar/NavigationTopBar';
import Footer from '../../components/Footer/Footer';
import PageTitle from '../../components/PageTitle/PageTitle';
import {getClient} from '../../services/contentfulClient';
import styles from './Offer.module.scss';
import {INLINES} from "@contentful/rich-text-types";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {calculateImageSize} from "../../helpers/calculateImageSize";

type OfferProps = {};
let Heading_box_top: string,
    Heading_site: string,
    Header_box: any,
    Offers_box_1: any,
    Offers_box_2: any,
    Offers_box_3: any
;

let imgWidth = calculateImageSize(456),
    imgHeight = calculateImageSize(294);

const Offer: React.FC<OfferProps> = () => {
    const [isLoading, setIsLoading] = useState(true);

    const promise_heading_box_top = getHeading('nAp2VlLc39VwMb4bd8Jdm'),
        promise_heading_site = getHeading('2EU6duyAptg01CyJl9Bj77'),
        promise_header_box = getClient().getEntry('4c1PMto4OBFWfzqXorB5oC')
            .then(function (entry: any) {
                return entry.fields;
            }),
        promise_offers_box_1 = getClient().getEntry('6HGnQ9JW3C6s5dEa6bpk3R')
            .then(function (entry: any) {
                return entry.fields;
            }),
        promise_offers_box_2 = getClient().getEntry('3EvYl0iZd5D37JuQoxDJmx')
            .then(function (entry: any) {
                return entry.fields;
            }),
        promise_offers_box_3 = getClient().getEntry('6wnSqGp2t0zcJoWpnokGX1')
            .then(function (entry: any) {
                return entry.fields;
            })
    ;

    Promise.all([promise_offers_box_1, promise_offers_box_2, promise_offers_box_3, promise_heading_box_top, promise_heading_site, promise_header_box]).then(data => {
        Offers_box_1 = data[0];
        Offers_box_2 = data[1];
        Offers_box_3 = data[2];
        Heading_box_top = data[3];
        Heading_site = data[4];
        Header_box = data[5];
        setIsLoading(false);
    })
    return (
        <>
            <ContentWrapper defaultStyles>
                <NavigationTopBar type="kinderangebote" />
                <PageTitle>{Heading_box_top}</PageTitle>
                <section className={styles.introText}>
                    <h2 className={styles.subTitle}>{Header_box ? Header_box.title : ''}</h2>
                    {Header_box ? parseContentfulText(Header_box.text) : ''}
                </section>
                <section className={styles.subpages}>
                    <div className={styles.subPage1}>
                        <h2 className={styles.subTitle}>{Offers_box_1 ? Offers_box_1.title : ''}</h2>
                        <div className={styles.imgContainer}>
                            <img className={styles.image1} src={Offers_box_1 ? Offers_box_1.image.fields.file.url +'?w=' + imgWidth + '&h=' + imgHeight : ''} alt={Offers_box_1 ? Offers_box_1.title : ''} />
                            <a className={styles.imgLink} href={`${process.env.PUBLIC_URL}/kinderangebote/belgeitend`}>
                                MEHR ERFAHREN
                            </a>
                        </div>
                    </div>
                    <div className={styles.subPage2}>
                        <h2 className={styles.subTitle}>{Offers_box_2 ? Offers_box_2.title : ''}</h2>
                        <div className={styles.imgContainer}>
                            <img className={styles.image2} src={Offers_box_2 ? Offers_box_2.image.fields.file.url +'?w=' + imgWidth + '&h=' + imgHeight : ''} alt={Offers_box_2 ? Offers_box_2.title : ''} />
                            <a className={styles.imgLink} href={`${process.env.PUBLIC_URL}/kinderangebote/programmformate`}>
                                MEHR ERFAHREN
                            </a>
                        </div>
                    </div>
                    <div className={styles.subPage3}>
                        <h2 className={styles.subTitle}>{Offers_box_3 ? Offers_box_3.title : ''}</h2>
                        <div className={styles.imgContainer}>
                            <img className={styles.image3} src={Offers_box_3 ? Offers_box_3.image.fields.file.url +'?w=' + imgWidth + '&h=' + imgHeight : ''} alt={Offers_box_3 ? Offers_box_3.title : ''} />
                            <a
                                className={styles.imgLink}
                                href={`${process.env.PUBLIC_URL}/kinderangebote/buchbar`}
                            >
                                MEHR ERFAHREN
                            </a>
                        </div>
                    </div>
                </section>
            </ContentWrapper>
            <Footer />
        </>
    );
};

function parseContentfulText(text: any) {
    const website_url = window.location.href;
    const options = {
        renderNode: {
            [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
                return ( <a href={node.data.target.fields.file.url} target="_blank"> {children} </a> )
            },
            [INLINES.HYPERLINK]: (node: any) => {
                return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
            }
        }
    };
    return documentToReactComponents(text, options);
}

function getHeading(contentful_id: string) {
    return getClient().getEntry(contentful_id)
        .then(function (entry: any) {
            return entry.fields.heading;
        });
}

export default Offer;
