import {BubbleData} from '../../../../components/Bubble/Bubble';

export default [
    {
        text: 'Wusstest Du schon?',
        variant: 'bubble3',
        //voiceFile: Audio1,
    },
    {
        text: 'Vor 400 Jahren hat der Künstler Claude Mellan dieses Gesicht gezeichnet. ',
        variant: 'bubble3',
        //voiceFile: Audio1,
    },
    {
        text: 'Mit nur einem Strich, der an der Nase beginnt und in Kreisen weiterläuft.',
        variant: 'bubble3',
        //voiceFile: Audio1,
    },
] as BubbleData[];
