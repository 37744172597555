import {BubbleData} from '../../../../components/Bubble/Bubble';

export default [
    {
        text: 'Wusstest Du schon …?',
        variant: 'bubble3',
        //voiceFile: Audio1,
    },
    {
        text: 'Der Kunstpalast hat nicht nur die allerschönsten Verstecke.',
        variant: 'bubble3',
        //voiceFile: Audio1,
    },
    {
        text: 'Er besitzt auch mehr als 100.000 Kunstwerke! Verteilt auf Depots in ganz Düsseldorf.',
        variant: 'bubble3',
        //voiceFile: Audio1,
    },
] as BubbleData[];
