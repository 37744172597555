import cloneDeep from 'lodash.clonedeep';
import {AnyAction} from 'redux';
import {setCurrentScreen} from '../../../reducers/common';
import {QuestionData, QuizState} from '../interfaces/QuizState';
import {actionTypes} from '../actions';

const initialState: QuizState = {
    currentQuestionId: 0,
    currentScreen: 'intro',
    isFinished: false,
    isFunFactOn: false,
    isInterfaceLocked: false,
    questions: [],
    currentIntroIndex: 0,
};

const setAnswer = (state: QuizState, {answerId, questionId}: { answerId: number, questionId: number }): QuizState => {
    const stateCopy = cloneDeep(state);
    stateCopy.questions[questionId] = {...state.questions[questionId], userAnswer: answerId};
    return stateCopy;
};
const setCurrentQuestionId = (state: QuizState, {id}: { id: number }): QuizState => ({
    ...state,
    currentQuestionId: id,
});

const setFunFactStatus = (state: QuizState, {on}: { on: boolean }): QuizState => ({
    ...state,
    isFunFactOn: on,
});

const setInterfaceLockStatus = (state: QuizState, {on}: { on: boolean }): QuizState => ({
    ...state,
    isInterfaceLocked: on,
});


const setQuestions = (
    state: QuizState,
    {questions}: { questions: QuestionData[] },
): QuizState => {
    return {
        ...state,
        questions,
    }
};

const quizReducer = (state: QuizState = initialState, {type, payload}: AnyAction): QuizState => {
    switch (type) {
        case actionTypes.setAnswer:
            return setAnswer(state, payload);
        case actionTypes.setCurrentIntroIndex:
            return ({...state, currentIntroIndex: payload.index});
        case actionTypes.setCurrentQuestionId:
            return setCurrentQuestionId(state, payload);
        case actionTypes.setCurrentScreen:
            return setCurrentScreen(state, payload);
        case actionTypes.setFunFactStatus:
            return setFunFactStatus(state, payload);
        case actionTypes.setInterfaceLockStatus:
            return setInterfaceLockStatus(state, payload);
        case actionTypes.getQuestionsSuccess:
            return setQuestions(state, payload);
        default:
            return state;
    }
};

export default quizReducer;