type OutroModalData = {
    header: string,
    copy: string,
    buttonText: string,
    alternativeButtonText: string,
}

export default {
        header: 'Preis!',
        copy: 'Frage Deine Eltern nach einem Besuch im Museum. Dort bekommst Du Deinen Preis. Einen Rhino Magneten!',
        buttonText: 'Plane deinen Besuch',
        alternativeButtonText: 'Mehr zum Entdecken!',
} as OutroModalData;
