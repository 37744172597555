import moment from 'moment';
import mergeWith from 'lodash.mergewith';
import contentful from './contentful';
import { ICalendarEvent } from './schema/contentful';

type CalendarEventPagingParams = {
    skip: number,
    limit: number,
}

export type CalendarEventCollection = {
    entries: ICalendarEvent[],
    isLastPage: boolean,
    loadNextPage?: () => Promise<CalendarEventCollection>,
}

export type CalendarEventFilteringParams = {
    [sectionName: string]: { [filterName: string]: boolean | string } | undefined,
} & {
    startDate: moment.Moment,
    endDate: moment.Moment,
}

export type CalendarRange = { id: string, label: string };

/**
 * Fetches calendar event data from Contentful.
 *
 * @param {CalendarEventPagingParams}   pageParams           Defines which page of results to fetch.
 * @param {CalendarEventPagingParams}   filterParams         Defines which filters were chosen by the user - subject to mapping.
 *
 * @return {Promise<CalendarEventCollection>} Custom object representing calendar entries @see CalendarEventCollection.
 */
const fetch = ({ pageParams = { skip: 0, limit: 100 }, filterParams } : {
    pageParams?: CalendarEventPagingParams,
    filterParams?: CalendarEventFilteringParams,
}): Promise<CalendarEventCollection> => {
    return contentful
        .fetchEntriesForContentType('calendarEvent', {
            ...pageParams,
            ...mapFilterParams(filterParams),
            order: 'fields.date,fields.startTime',
        })
        .then(response => {
            const isLastPage = response.total <= response.limit + response.skip;
            return {
                entries: response.items.map(item => item as ICalendarEvent),
                isLastPage,
                loadNextPage: isLastPage
                    ? undefined
                    : () => fetch({
                        pageParams: {
                            skip: response.skip + response.limit,
                            limit: response.limit,
                        },
                        filterParams,
                    })
            }
        });
};

const fetchRanges = (contentTypeName: string): Promise<CalendarRange[]> => {
    return contentful
        .fetchEntriesForContentType(contentTypeName, { limit: undefined })
        .then(response => response.items.map(item => {
            return {
                id: item.sys.id,
                label: item.fields.rangeLabel,
            };
        }));
};

/**
 * Fetches calendar age range data from Contentful.
 *
 * @return {Promise<CalendarRange[]>} Custom object representing calendar age range entries @see CalendarRange.
 */
const fetchAgeRanges = (): Promise<CalendarRange[]> => {
    return fetchRanges('calendarEventAgeGroup');
};

/**
 * Fetches calendar age range data from Contentful.
 *
 * @return {Promise<CalendarRange[]>} Custom object representing calendar age range entries @see CalendarRange.
 */
const fetchHourRanges = (): Promise<CalendarRange[]> => {
    return fetchRanges('calendarEventHourRange');
};

const kidsOnlyEventTypes = [
    'Kinderführung',
    'Kinderworkshop',
    'Kinderkurs',
    'Kinderferienkurs',
    'Kunst Klub',
].join(',');

const mapFilterParams = (filterParams?: CalendarEventFilteringParams): any => {
    let result = {};
    if (filterParams) {
        result = Object.keys(filterParams).map(filterName => {
            switch(filterName) {
                case 'startDate':
                    if (filterParams.startDate) {
                        if (filterParams.startDate.format('Y-M') == moment().format('Y-M')) {
                            return {
                                'fields.date[gte]': moment().startOf('day').toISOString(true),
                            }
                        }

                        return {
                            'fields.date[gte]': filterParams.startDate.startOf('day').toISOString(true),
                        }
                    }
                    break;
                case 'endDate':
                    if (filterParams.endDate) {
                        return {
                            'fields.date[lte]': filterParams.endDate.endOf('D').toISOString(true),
                        }
                    }
                    break;
                case 'age':
                    const checkedAges: string[] = getCheckedItems(filterParams.age);
                    if (checkedAges.length) {
                        return {
                            'fields.ageRanges.sys.id[in]': checkedAges.join(','),
                        }
                    }
                    break;
                case 'dayTime':
                    const checkedDayTimes: string[] = getCheckedItems(filterParams.dayTime);
                    if (checkedDayTimes.length) {
                        return {
                            'fields.hourRanges.sys.id[in]': checkedDayTimes.join(','),
                        }
                    }
                    break;
                case 'eventType':
                    const checkedEventTypes: string[] = getCheckedItems(filterParams.eventType);
                    if (checkedEventTypes.length) {
                        return {
                            'fields.eventType[in]': checkedEventTypes.join(','),
                        }
                    }
                    break;
                case 'familyChildren':
                    const checkedFamilyTypes: string[] = getCheckedItems(filterParams.familyChildren);
                    if (checkedFamilyTypes.length === 1) {
                        // if both filters are checked we are showing all events anyway
                        return checkedFamilyTypes[0].toLowerCase() === 'kinder' ? {
                            'fields.eventType[in]': kidsOnlyEventTypes,
                        } : {
                            'fields.eventType[nin]': kidsOnlyEventTypes,
                        }
                    }
                    break;
                case 'week':
                    const checkedIsWeekend: string[] = getCheckedItems(filterParams.week);
                    if (checkedIsWeekend.length === 1) {
                        // if both filters are checked we are showing all events anyway
                        return {
                            'fields.isOnWeekend': checkedIsWeekend[0].toLowerCase() === 'am wochenende',
                        };
                    }
                    break;
            }
        }).reduce((finalFilters, filterItem) =>
            // the call below merges the objects and joins string properties (with a ','), instead of just using the last value that is not undefined
            mergeWith({}, finalFilters, filterItem, (objValue, srcValue) => {
                if (objValue) {
                    const merged = `${objValue},${srcValue}`;
                    // this gets rid of duplicated values in the string - to save URL length
                    return Array.from(new Set(merged.split(','))).join(',');
                }
                return undefined;
            })
        , {});
    }
    return result;
};

const getCheckedItems = (filterItems: any): any[] => {
    // for referenced based filters return the check label value (which is id of the reference)
    return Object.keys(filterItems)
        .map(key => filterItems && filterItems[key]
            ? (typeof filterItems[key] === 'string' ? filterItems[key] : key)
            : undefined)
        .filter(item => item);
};

/**
 * Formats dates in ISO string to a calendar-specific date format.
 *
 * @param {string}   date           Date in ISO format.
 * @param {string}   startTime      Starting time in hour format (HH:mm).
 * @param {string}   endTime        Ending time in hour format (HH:mm).
 *
 * @return Custom object representing formatted date.
 */
const formatDate = (date: string, startTime: string, endTime: string): { dayOfWeek: string, dayAndMonth: string, hour: string, end: string } => {
    const m = moment(date);
    return {
        dayOfWeek: m.format('dd,'),
        dayAndMonth: m.format('D.MM.'),
        hour: startTime,
        end: endTime + ' Uhr',
    };
};

const umlautMap: { [key: string]: string } = {
    '\u00fc': 'ue',
    '\u00e4': 'ae',
    '\u00f6': 'oe',
    '\u00df': 'ss',
};

/**
 * Helper method, to remove diactritics from event type names (can be then used in CSS class names).
 *
 * @param {string}   eventType           Event type name.
 *
 * @return {string} Normalizaed event type name.
 */
const getEventClassName = (eventType: string): string => {
    if (!eventType) {
        return '';
    }
    const result = eventType
        .replace(/\s/g, '')
        .toLowerCase()
        .replace('&', '')
        .replace(new RegExp('['+Object.keys(umlautMap).join('|')+']', 'g'),
            (a) => umlautMap[a]
        );
    return result;
}

export default {
    fetch,
    fetchAgeRanges,
    fetchHourRanges,
    formatDate,
    getEventClassName,
}
