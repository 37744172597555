import {combineReducers} from "redux";
import {isMutedReducer} from '../components/MuteButton/reducers';
import detectiveReducer from '../games/Detective/reduces';
import memoryReducer from "../games/Memory/reducers";
import quizReducer from "../games/Quiz/reducers";

const rootReducer = combineReducers({
   detective: detectiveReducer,
   memory: memoryReducer,
   quiz: quizReducer,
   isMuted: isMutedReducer,
});

export default rootReducer;