import React, {CSSProperties, useEffect, useState} from 'react';
import cx from 'classnames';
import styles from './AI.module.scss';

import Footer from '../../components/Footer/Footer';
import PictureBox from './components/PictureBox/PictureBox';
import EventPostit from './components/EventPostit/EventPostit';
import ScrollTo from '../../components/ScrollTo/ScrollTo';
import NavigationTopBar from '../../components/NavigationTopBar/NavigationTopBar';

import {IAI} from '../../services/schema/contentful';
import ai, {AICollection} from '../../services/ai';
import {getClient} from "../../services/contentfulClient";
import {INLINES} from "@contentful/rich-text-types";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {calculateImageSize} from "../../helpers/calculateImageSize";
import VideoModalElement from "../Kunstklick/components/VideoModalElement/VideoModalElement";

type TemporaryAIProps = {isDIY: boolean, isTop5: boolean};
let Header_box: any,
    Heading_site: string,
    imgWidth = calculateImageSize(650),
    imgHeight = calculateImageSize(497),
    imgWidthMobile = calculateImageSize(306),
    imgHeightMobile = calculateImageSize(234);

const iframeContainer: CSSProperties = {
    position: 'relative',
    height: 0,
    overflow: 'hidden',
    maxWidth: '84%',
    paddingBottom: ' 56.25%',
    margin: '0 auto 50px auto',
    backgroundImage: 'url(assets/square.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
}, iframe: CSSProperties = {
    position: 'absolute',
    top: '6%',
    left: '3%',
    width: '93%',
    height: '89%',
}

const AI: React.FC<TemporaryAIProps> = (props) => {
    const [tipItemList, settipItemList] = useState([] as IAI[]);

    useEffect(() => {
        ai.fetch().then(parsetipItemListResponse);
    }, []);

    const parsetipItemListResponse = (response: AICollection) => {
        settipItemList(response.entries);
    };


    const [isLoading, setIsLoading] = useState(true);

    const promise_header_box = getClient().getEntry('5gtqLlDaBsi8sGNtwkO6OM')
        .then(function (entry: any) {
            return entry.fields;
        }),
        promise_heading_site = getHeading('4XRsGBakmV9Fbu2TMEh4r1');

    Promise.all([promise_header_box, promise_heading_site]).then(data => {
        Header_box = data[0];
        Heading_site = data[1];

        setIsLoading(false);
    })


    let currentList = tipItemList;

    const tipItemListOrdered = tipItemList.sort((a, b) => a.fields.order > b.fields.order ? -1 : 1)

    currentList = tipItemListOrdered.filter(function(currentElement) {
        return (!currentElement.fields.top5Category && !currentElement.fields.diyCategory) ? currentElement : false;
    });

    const isMobile = () => window.innerWidth < 760;
    const isBigDesktop = () => window.innerWidth > 1679;

    function renderTipList(tipItemListOrdered: any) {
        return tipItemListOrdered.map((element: any, id: number) => {

            const elementData = element.fields;
            const imageDesktop = (element.fields.image && element.fields.image.fields) ? element.fields.image.fields.file.url + '?w=' + imgWidth + '&h=' + imgHeight : '';
            const imageMobile = (element.fields.imageMobile && element.fields.imageMobile.fields) ? element.fields.imageMobile.fields.file.url + '?w=' + imgWidthMobile + '&h=' + imgHeightMobile : '';

            return (
                <div
                    key={id}
                    id={element.fields.anchor}
                    className={cx(
                        styles.exhibitionBox,
                        {[styles.smallElement]: element.subPostitSize === 'small'}
                    )}
                >
                    <div>
                        {element.fields.iFrame ?
                            <div>
                                <div style={iframeContainer}
                                >
                                    <iframe style={iframe} src={element.fields.iFrame}
                                            frameBorder="0"
                                            allowFullScreen></iframe>
                                </div>
                            </div>
                            :
                            element.fields.youtubeVideoId ?
                                    <div className={styles.movieBox} key={id}>
                                        <VideoModalElement
                                            channel='youtube'
                                            videoId={element.fields.youtubeVideoId}
                                            imgSrc={isMobile() ? imageMobile : imageDesktop}
                                        />
                                        <div className={styles.videoTeaser}>
                                            <h3>{element.fields.tipName}</h3>
                                            {element.fields.mainData ? parseText(element.fields.mainData) : ''}
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className={styles.eventBox}>
                                            <PictureBox
                                                className={styles.artTopSectionMiddle}
                                                artworkSrc={isMobile() ? imageMobile : imageDesktop}
                                                artworkName={elementData.authorName}
                                                artworkDescription={elementData.imageDescription}
                                                tapeTypeNumber={elementData.tapeTypeNumber}
                                            />
                                            <EventPostit
                                                className={styles.cartTopSectionMiddle}
                                                exhibitionName={elementData.tipName}
                                                date={elementData.dateRange}
                                                mainData={elementData.mainData}
                                                kurator={elementData.kuratorName}
                                                smallCartText={elementData.subtitleText}
                                                subPostitBig={elementData.subTextBig}
                                            />
                                        </div>
                                    </div>

                        }
                    </div>
                </div>
            )
        })

    }

    // TODO: ugly temporary solution with scroll, need to be more flexible if content will change
    return (
        <>
            <div
                className={styles.wrapper}
            >
                <NavigationTopBar/>
                <section className={styles.sectionTop}>
                    <h1 className={styles.sectionTitle}>
                        {Heading_site}
                    </h1>

                </section>
                <section className={styles.introText}>
                    <h2 className={styles.subTitle}>{Header_box ? Header_box.title : ''}</h2>
                    {Header_box ? parseContentfulText(Header_box.text) : ''}
                </section>
                {renderTipList(currentList)}
            </div>
            <section className={styles.topLinkSection}>
                <ScrollTo className={styles.topLink}>NACH OBEN</ScrollTo>
            </section>
            <Footer/>
        </>
    )
};

function parseContentfulText(text: any) {
    const website_url = window.location.href;
    const options = {
        renderNode: {
            [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
                return ( <a href={node.data.target.fields.file.url} target="_blank"> {children} </a> )
            },
            [INLINES.HYPERLINK]: (node: any) => {
                return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
            }
        }
    };
    return documentToReactComponents(text, options);
}

function getHeading(contentful_id: string) {
    return getClient().getEntry(contentful_id)
        .then(function (entry: any) {
            return entry.fields.heading;
        });
}

function parseText(string: any) {
    return documentToReactComponents(string, {
        renderNode: {
            [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
                return ( <a href={node.data.target.fields.file.url} target="_blank"> {children} </a> )
            },
            [INLINES.HYPERLINK]: (node: any) => {
                return <a href={node.data.uri} target={`${node.data.uri.startsWith(window.location.href) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(window.location.href) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
            }

        },
        renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text])
    })
}

export default AI;
