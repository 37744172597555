import DogImgUrl from './assets/Hund.jpg';
import Dog2ImgUrl from './assets/Hund2.jpg';
import FishImgUrl from './assets/Fisch.jpg';
import SnailImgUrl from './assets/Schnecke.jpg';
import SnakeImgUrl from './assets/Schlange.jpg';
import HorseImgUrl from './assets/Pferd.jpg';
import GoatImgUrl from './assets/Ziege.jpg';
import BoreImgUrl from './assets/Wildschwein.jpg';
import BirdImgUrl from './assets/Vogel.jpg';
import RatImgUrl from './assets/Ratte.jpg';

import DogHouseImgUrl from './assets/houses/doghouse_1.png';
import Dog2HouseImgUrl from './assets/houses/doghouse_2.png';
import FishHouseImgUrl from './assets/houses/fishbowl.png';
import SnailHouseImgUrl from './assets/houses/snail_leaf.png';
import SnakeHouseImgUrl from './assets/houses/snake_basket.png';
import HorseHouseImgUrl from './assets/houses/horse_stable.png';
import GoatHouseImgUrl from './assets/houses/goat_hay_stack.png';
import BoreHouseImgUrl from './assets/houses/bush.png';
import BirdHouseImgUrl from './assets/houses/birdhouse.png';
import RatHouseImgUrl from './assets/houses/rat_cheese.png';

import Fish from './assets/animalsInHouses/fish.png';
import Dog1 from './assets/animalsInHouses/dog_1.png';
import Snail from './assets/animalsInHouses/snail.png';
import Snake from './assets/animalsInHouses/snake.png';
import Goat from './assets/animalsInHouses/goat.png';
import Horse from './assets/animalsInHouses/horse.png';
import Pig from './assets/animalsInHouses/pig.png';
import Dog2 from './assets/animalsInHouses/dog_2.png';
import Rat from './assets/animalsInHouses/rat.png';
import Bird from './assets/animalsInHouses/bird.png';

const Audio1 = require('./assets/voice/65.mp3');
const Audio2 = require('./assets/voice/66.mp3');
const DogAudio = require('./assets/voice/67.mp3');
const Audio3 = require('./assets/voice/68.mp3');
const SnakeAudio = require('./assets/voice/69.mp3');
const Audio4 = require('./assets/voice/70.mp3');
const Audio5 = require('./assets/voice/71.mp3');
const Audio6 = require('./assets/voice/72.mp3');
const GoatAudio = require('./assets/voice/73.mp3');
const Audio7 = require('./assets/voice/74.mp3');
const Audio8 = require('./assets/voice/75.mp3');
const Audio9 = require('./assets/voice/76.mp3');
const Audio10 = require('./assets/voice/77.mp3');

const defaultAreaProps = {
    name: 'target',
    shape: 'rect',
    fillColor: 'transparent',
    strokeColor: 'transparent',
    lineWidth: 0,
    preFillColor: 'transparent',
};

export default [
    {
        rhinoText: 'Ich habe das Tier gefunden! Soll ich es dir zeigen? Klicke auf die Lupe und dann auf das Tier, damit wir es mitnehmen können.',
        remindText: 'Wir müssen uns beeilen und als erstes den Fisch finden, der trocknet doch so schnell aus! Wo kann er nur sein?',
        subRemindTextStart: 'Suche den ',
        subRemindTextEnd: 'und klicke ihn an, damit er wieder schnell ins Wasser kann!',
        animalNameText: 'Fisch ',
        helpImgUrl: FishHouseImgUrl,
        image: {
            url: FishImgUrl,
            height: 705,
            width: 812,
            map: {
                name: 'imgMap', // TODO should probably be taken out for code deduplication
                areas: [
                    {
                        ...defaultAreaProps,
                        coords: [736, 632, 811, 704],
                    },
                ],
            },
        },
        endingText: 'It can breath again!',
        correctAnswer: 1,
        taskNumber: 1,
        animal: Fish,
        animalName: 'Fish',
        voiceFile: Audio1,
    },
    {
        rhinoText: 'Ich habe das Tier gefunden! Soll ich es dir zeigen? Klicke auf die Lupe und dann auf das Tier, damit wir es mitnehmen können.',
        remindText: 'Hörst Du auch das bellen? Das ist bestimmt unser nächster Ausreiẞer. Wir suchen einen Hund, der genauso schön grau ist wie ich. Wo kann er nur sein?',
        subRemindTextStart: 'Schau genau hin und suche den grauen ',
        subRemindTextEnd: '!',
        animalNameText: 'Hund',
        helpImgUrl: DogHouseImgUrl,
        image: {
            url: DogImgUrl,
            height: 1525,
            width: 2048,
            map: {
                name: 'imgMap', // TODO should probably be taken out for code deduplication
                areas: [
                    {
                        ...defaultAreaProps,
                        coords: [1005, 662, 851, 543],
                    },
                ],
            },
        },
        endingText: 'It can breath again!',
        correctAnswer: 1,
        taskNumber: 2,
        animal: Dog1,
        animalName: 'Dog1',
        voiceFile: Audio2,
    },
    {
        rhinoText: 'Ich habe das Tier gefunden! Soll ich es dir zeigen? Klicke auf die Lupe und dann auf das Tier, damit wir es mitnehmen können.',
        remindText: 'Die Schnecke kann ja nicht weit sein. Dafür ist sie klein und kann sich gut verstecken. Das Schneckenhaus ist schwarz-weiẞ.',
        subRemindTextStart: 'Rette schnell die ',
        subRemindTextEnd: ', bevor ich Tollpatsch aus Versehen drauf trete!',
        animalNameText: 'Schnecke',
        helpImgUrl: SnailHouseImgUrl,
        image: {
            url: SnailImgUrl,
            height: 2048,
            width: 1567,
            map: {
                name: 'imgMap', // TODO should probably be taken out for code deduplication
                areas: [
                    {
                        ...defaultAreaProps,
                        coords: [425, 975, 497, 1045],
                    },
                ],
            },
        },
        endingText: 'It can breath again!',
        correctAnswer: 1,
        taskNumber: 3,
        animal: Snail,
        animalName: 'Snail',
        voiceFile: Audio3,
    },
    {
        rhinoText: 'Ich habe das Tier gefunden! Soll ich es dir zeigen? Klicke auf die Lupe und dann auf das Tier, damit wir es mitnehmen können.',
        remindText: 'Hui, schau Dir mal die ganzen Farben und Formen an! Hier hat sich auch ein Tier versteckt.',
        subRemindTextStart: 'Kleiner Tipp: Es ist eine ',
        subRemindTextEnd: 'und die streckt uns frech die Zunge raus. Kannst Du sie finden?',
        animalNameText: 'Schlange ',
        helpImgUrl: SnakeHouseImgUrl,
        image: {
            url: SnakeImgUrl,
            height: 2048,
            width: 1658,
            map: {
                name: 'imgMap', // TODO should probably be taken out for code deduplication
                areas: [
                    {
                        ...defaultAreaProps,
                        shape: 'poly',
                        coords: [132, 1705, 142, 1830, 332, 1928, 561, 1840, 694, 1682, 691,
                            1600, 776, 1566, 785, 1475, 718, 1455, 562, 1525, 463, 1729, 306, 1806],
                    },
                ],
            },
        },
        endingText: 'It can breath again!',
        correctAnswer: 1,
        taskNumber: 4,
        animal: Snake,
        animalName: 'Snake',
        voiceFile: Audio4,
    },
    {
        rhinoText: 'Ich habe das Tier gefunden! Soll ich es dir zeigen? Klicke auf die Lupe und dann auf das Tier, damit wir es mitnehmen können.',
        remindText: 'Hier ist ja was los! Wir suchen das Pferd, das gerade so genüsslich das Heu frisst – ob wir es weglocken können?',
        subRemindTextStart: 'Wenn Du das ',
        subRemindTextEnd: 'gefunden hast, klicke es an, damit es mit uns kommt.',
        animalNameText: 'Pferd ',
        helpImgUrl: HorseHouseImgUrl,
        image: {
            url: HorseImgUrl,
            height: 1637,
            width: 2048,
            map: {
                name: 'imgMap', // TODO should probably be taken out for code deduplication
                areas: [
                    {
                        ...defaultAreaProps,
                        coords: [1578, 1288, 1793, 1443],
                    },
                ],
            },
        },
        endingText: 'It can breath again!',
        correctAnswer: 1,
        taskNumber: 5,
        animal: Horse,
        animalName: 'Horse',
        voiceFile: Audio5,
    },
    {
        rhinoText: 'Ich habe das Tier gefunden! Soll ich es dir zeigen? Klicke auf die Lupe und dann auf das Tier, damit wir es mitnehmen können.',
        remindText: 'Wir suchen eine sitzende Ziege, die anderen müssen leider hier bleiben. Suche schnell weiter, bevor die Ziegen anfangen zu meckern…',
        subRemindTextStart: 'Kannst Du die ',
        subRemindTextEnd: 'finden, die man nur von hinten sieht? Klicke sie an.',
        animalNameText: 'Ziege ',
        helpImgUrl: GoatHouseImgUrl,
        image: {
            url: GoatImgUrl,
            height: 1436,
            width: 2048,
            map: {
                name: 'imgMap', // TODO should probably be taken out for code deduplication
                areas: [
                    {
                        ...defaultAreaProps,
                        coords: [407, 1324, 481, 1396],
                    },
                ],
            },
        },
        endingText: 'It can breath again!',
        correctAnswer: 1,
        taskNumber: 6,
        animal: Goat,
        animalName: 'Goat',
        voiceFile: Audio6,
    },
    {
        rhinoText: 'Ich habe das Tier gefunden! Soll ich es dir zeigen? Klicke auf die Lupe und dann auf das Tier, damit wir es mitnehmen können.',
        remindText: 'Manche Tiere haben es sogar bis in das Glasmuseum geschafft. Das Tier sieht mir ein bisschen ähnlich, dabei ist es eigentlich ein Wildschwein.',
        subRemindTextStart: 'Finde das ',
        subRemindTextEnd: 'und klicke es an.',
        animalNameText: 'Wildschwein ',
        helpImgUrl: BoreHouseImgUrl,
        image: {
            url: BoreImgUrl,
            height: 2048,
            width: 1642,
            map: {
                name: 'imgMap', // TODO should probably be taken out for code deduplication
                areas: [
                    {
                        ...defaultAreaProps,
                        coords: [598, 1169, 1007, 1368],
                    },
                ],
            },
        },
        endingText: 'It can breath again!',
        correctAnswer: 1,
        taskNumber: 7,
        animal: Pig,
        animalName: 'Pig',
        voiceFile: Audio7,
    },
    {
        rhinoText: 'Ich habe das Tier gefunden! Soll ich es dir zeigen? Klicke auf die Lupe und dann auf das Tier, damit wir es mitnehmen können.',
        remindText: 'Unser grauer Hund hat noch einen Freund, der auf diesem Bild zu sehen ist - der hat sich nicht ganz so gut versteckt.',
        subRemindTextStart: 'Finde den grauen ',
        subRemindTextEnd: '.',
        animalNameText: 'Hund',
        helpImgUrl: Dog2HouseImgUrl,
        image: {
            url: Dog2ImgUrl,
            height: 1532,
            width: 2048,
            map: {
                name: 'imgMap', // TODO should probably be taken out for code deduplication
                areas: [
                    {
                        ...defaultAreaProps,
                        coords: [26, 1127, 359, 1313],
                    },
                ],
            },
        },
        endingText: 'It can breath again!',
        correctAnswer: 1,
        taskNumber: 8,
        animal: Dog2,
        animalName: 'Dog2',
        voiceFile: Audio8,
    },
    {
        rhinoText: 'Ich habe das Tier gefunden! Soll ich es dir zeigen? Klicke auf die Lupe und dann auf das Tier, damit wir es mitnehmen können.',
        remindText: 'Hihihi, der Junge sieht ja lustig aus in seinem Kostüm. Aber eigentlich suchen wir seinen gefiederten Freund…',
        subRemindTextStart: 'Finde schnell den ',
        subRemindTextEnd: ', bevor er wieder davon fliegt!',
        animalNameText: 'Vogel',
        helpImgUrl: BirdHouseImgUrl,
        image: {
            url: BirdImgUrl,
            height: 2048,
            width: 1588,
            map: {
                name: 'imgMap', // TODO should probably be taken out for code deduplication
                areas: [
                    {
                        ...defaultAreaProps,
                        coords: [4, 66, 281, 272],
                    },
                ],
            },
        },
        endingText: 'It can breath again!',
        correctAnswer: 1,
        taskNumber: 9,
        animal: Bird,
        animalName: 'Bird',
        voiceFile: Audio9,
    },
    {
        rhinoText: 'Ich habe das Tier gefunden! Soll ich es dir zeigen? Klicke auf die Lupe und dann auf das Tier, damit wir es mitnehmen können.',
        remindText: 'Oh oh, es wird langsam schon dunkel und das Museum macht auch bald zu, wir müssen uns beeilen! Die Tiere sehen ja ganz schön gruselig aus…',
        subRemindTextStart: 'Sammle schnell die ',
        subRemindTextEnd: 'ein und dann schnell weg hier.',
        animalNameText: 'Ratte ',
        helpImgUrl: RatHouseImgUrl,
        image: {
            url: RatImgUrl,
            height: 2048,
            width: 1594,
            map: {
                name: 'imgMap', // TODO should probably be taken out for code deduplication
                areas: [
                    {
                        ...defaultAreaProps,
                        coords: [1071, 1406, 1492, 1689],
                    },
                ],
            },
        },
        endingText: 'It can breath again!',
        correctAnswer: 1,
        taskNumber: 10,
        animal: Rat,
        animalName: 'Rat',
        voiceFile: Audio10,
    },
] as TaskData[];

export interface TaskData {
    rhinoText: string,
    remindText: string,
    subRemindTextStart: string,
    subRemindTextEnd: string,
    helpImgUrl: string,
    endingText: string,
    correctAnswer: number,
    taskNumber: number,
    image: DetectiveImage,
    animal: string,
    animalName: string,
    animalNameText: string,
    voiceFile: string,
}

export interface DetectiveImage {
    url: string,
    map: ImageMap,
    height: number,
    width: number,
}

export interface ImageMap {
    name: string,
    areas: ImageMapArea[]
}

export interface ImageMapArea {
    name: string,
    shape: string,
    coords: number[],
    fillColor: string,
    strokeColor: string,
    lineWidth: 0,
    preFillColor?: string,
}