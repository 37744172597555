import React, { useEffect, useMemo, useState, useRef } from 'react';
import cx from 'classnames';
import Spritesheet from 'react-responsive-spritesheet';
import {PlayerName} from '../../../../interfaces/MemoryState';
import styles from './PointsCounter.module.scss';
import AvatarsSpritesheet from './avatars_spritesheet.png';

type PointsCounterProps = {
    character: PlayerName,
    isActive: boolean,
    score: number,
}

const PointsCounter = ({isActive, character, score}: PointsCounterProps) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [loop, setLoop] = useState(0);
    const spriteInstance = useRef();
    
    const frames = useMemo(() => {
        let startAt: number, endAt: number;
        switch (character) {
            case 'cpu':
                startAt = 6;
                endAt = 10;
                break;
            case 'user':
            default:
                startAt = 2;
                endAt = 4;
        }
        return { startAt, endAt };
    }, [character]);

    useEffect(() => {
        if (score > 0 && spriteInstance.current) {
            setIsPlaying(true);
            const spritesheet = spriteInstance.current as any;
            spritesheet.setEndAt(frames.endAt);
            spritesheet.play();
        }
    }, [score, frames.endAt]);

    return (
        <div className={cx(styles.pointsCounter, styles[character], {[styles.active]: isActive})}>
                <Spritesheet
                    image={AvatarsSpritesheet}
                    style={{ opacity: isActive ? 'inherit' : '0' }}
                    className={cx(styles.avatar, styles[character])}
                    widthFrame={200}
                    heightFrame={230}
                    fps={9}
                    autoplay={false}
                    loop={true}
                    getInstance={(spritesheet: any) => {
                        spriteInstance.current = spritesheet;
                    }}
                    onLoopComplete={(spritesheet: any) => {
                        /* 
                            Both this spitesheet element and the div below are hidden by using style on purpose.
                            Rendering them conditionally causes stutter effects and you can see the resizing of sprite image in some cases.
                            Because of this, the spritesheet is always looping in the backround and the 'spritesheet.getInfo('completeLoopCicles')'
                            method can't be used - hence the custom loop guard below.
                         */
                        if (isPlaying) {
                            if (loop === 1) {   // equals two loops of animation
                                spritesheet.setEndAt(frames.startAt);
                                setIsPlaying(false);
                                setLoop(0);
                            } else {
                                setLoop(loop + 1);
                            }
                        }
                    }}
                    {...frames}
                    steps={10}
                />
                { /* the div below only shows inactive icons at the moment */ }
                <div 
                    className={cx(styles.avatarStatic, styles[character])}
                    style={{ display: isActive ? 'none' : 'block' }}
                ></div>
            <div className={cx(styles.score, styles[character], {[styles.active]: isActive})}>{score}</div>
        </div>
    );
};

export default PointsCounter;