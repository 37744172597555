import contentful from './contentful';
import {ISliderItem} from './schema/contentful';

export type SlideItemListCollection = {
    entries: ISliderItem[]
}

const fetch = () => {
    return contentful
        .fetchEntriesForContentType('sliderItemHome')
        .then(response => {
            return {
                entries: response.items.map(item => item as ISliderItem)
            }
        })
};

export default {fetch}
