import React, {useEffect, useState, useRef} from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import Helmet from 'react-helmet';
import moment from 'moment';
import Footer from '../../components/Footer/Footer';
import PageTitle from '../../components/PageTitle/PageTitle';
import FilterMenu from './components/FilterMenu/FilterMenu';
import EventList from './components/EventList/EventList';
import Loader from './components/Loader/Loader';
import {ICalendarEvent} from '../../services/schema/contentful';
import calendar, {CalendarEventCollection, CalendarEventFilteringParams, CalendarRange} from '../../services/calendar';
import {filterSections, FilterData, LabelData} from './filterData';

import styles from './Calendar.module.scss';
import NavigationTopBar from '../../components/NavigationTopBar/NavigationTopBar';
import ScrollTo from "../../components/ScrollTo/ScrollTo";

const Calendar: React.FC<RouteComponentProps> = ({history}) => {
    const [events, setEvents] = useState([] as ICalendarEvent[]);
    const [ageRanges, setAgeRanges] = useState([] as CalendarRange[]);
    const [hourRanges, setHourRanges] = useState([] as CalendarRange[]);
    const [filterData, setFilterData] = useState(undefined as any as FilterData);
    const [isLoading, setIsLoading] = useState(true);
    const nextPageFn = useRef(undefined as undefined | (() => Promise<CalendarEventCollection>));
    useEffect(() => {
        calendar.fetchAgeRanges().then(setAgeRanges);
        calendar.fetchHourRanges().then(setHourRanges);
        calendar.fetch({
            filterParams: {
                startDate: moment().startOf('day'),
                endDate: moment().endOf('month'),
            } as CalendarEventFilteringParams,
        }).then(parseEventsResponse);
    }, []);

    useEffect(() => {
        if (ageRanges && hourRanges) {
            // for reference based fields (ageRange and hourRange) map the section labels to a { label, value: id } format
            setFilterData({
                ...filterSections,
                age: {
                    ...filterSections.age,
                    sections: {
                        left: mapRangeIds(ageRanges, filterSections.age.sections.left),
                        right: mapRangeIds(ageRanges, filterSections.age.sections.right),
                    },
                },
                dayTime: {
                    ...filterSections.dayTime,
                    sections: {
                        left: mapRangeIds(hourRanges, filterSections.dayTime.sections.left),
                        right: mapRangeIds(hourRanges, filterSections.dayTime.sections.right),
                    },
                },
            });
        }
    }, [ageRanges, hourRanges]);

    const mapRangeIds = (targetRanges: CalendarRange[], section?: LabelData[]) => section
        ? section.map(labelData => ({
            label: labelData.label,
            value: (targetRanges.find(range => range.label === labelData.label.replace(' uhr', '')) || {id: undefined}).id,
        }))
        : [];

    const parseEventsResponse = (response: CalendarEventCollection) => {
        setEvents(response.entries);
        nextPageFn.current = response.loadNextPage;
        setIsLoading(false);
    };

    const handleFilter = (filter: CalendarEventFilteringParams) => {
        setIsLoading(true);
        calendar.fetch({filterParams: filter}).then(parseEventsResponse);
    };

    return (
        <>
            <div className={styles.calendar}>
                <Helmet>
                    <body className={styles.calendarBody}/>
                </Helmet>
                <NavigationTopBar type='kalender'/>
                <PageTitle>KALENDER</PageTitle>
                <FilterMenu
                    filterSections={filterData}
                    onFilterUpdate={handleFilter}
                />
                {isLoading
                    ? <Loader/>
                    : <EventList events={events}/>
                }
            </div>
            <section className={styles.topLinkSection}>
                <ScrollTo className={styles.topLink}>NACH OBEN</ScrollTo>
            </section>
            <Footer/>
        </>
    );
};

export default withRouter(Calendar);
