import contentful from './contentful';
import {ITip} from './schema/contentful';

export type TipCollection = {
    entries: ITip[]
}

const fetch = () => {
    return contentful
        .fetchEntriesForContentType('lexicon', {limit: 1000})
        .then(response => {
            return {
                entries: response.items.map(item => item as ITip)
            }
        })
};

export default {
    fetch
}
