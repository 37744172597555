import React, {Component} from 'react';
import {connect} from 'react-redux';
import {AnyAction, Dispatch} from 'redux';
import MuteButton from '../../../../components/MuteButton/MuteButton';
import {State} from '../../../../interfaces/State';
import {goToNextTask} from '../../actions';
import {TaskData} from '../../gameData';
import {selectCurrentTask} from '../../selectors';
import Artwork from './components/Artwork/Artwork';
import DetectivePeep from './components/DetectivePeep/DetectivePeep';
import TaskReminder from './components/TaskReminder/TaskReminder';
import styles from './DetectiveCore.module.scss';
import Counter from '../../../../components/Counter/Counter';
import Logo from '../../../../components/Logo/Logo';
import FadeOutLayer from '../../../../components/FadeOutLayer/FadeOutLayer';
import Modal from '../../../../components/Modal/Modal';
import OptionBox from '../../../../components/OptionBox/OptionBox';
import cx from 'classnames';

type DetectiveCoreProps = {
    goToNextTask: () => void,
    currentTask: TaskData,
};

class DetectiveCore extends Component<DetectiveCoreProps, {
    withHelper: boolean,
    isModal: boolean,
    showRhinoPeep: boolean,
}> {
    constructor(props: Readonly<DetectiveCoreProps>) {
        super(props);
        this.state = {
            withHelper: false,
            isModal: false,
            showRhinoPeep: true,
        };

        this.triggerNextTask = this.triggerNextTask.bind(this);
        this.triggerModal = this.triggerModal.bind(this);
    }

    triggerNextTask() {
        this.setState({isModal: true});
    }

    triggerModal() {
        this.setState(
            {
                isModal: false,
                showRhinoPeep: true,
            },
            () => this.props.goToNextTask()
        );
    }

    render() {

        const {currentTask} = this.props;

        const {isModal, showRhinoPeep} = this.state;

        const showHelper = () => {
            this.setState({withHelper: true});
        };

        const hideHelp = () => {
            this.setState({
                withHelper: false,
                showRhinoPeep: false,
            });
            this.triggerNextTask();
        };

        return (
            <div className={styles.detectiveCore}>
                <div className={styles.margeSign}/>
                <OptionBox />
                <MuteButton />
                <header>
                    <Logo/>
                </header>
                <FadeOutLayer/>
                <Counter
                    className={styles.counterComponent}
                    dataLeft={currentTask.taskNumber}
                    dataRight={10}
                    divider='/'
                />
                <div className={styles.mainLayout}>
                    <Artwork
                        onNext={this.triggerNextTask}
                        task={currentTask}
                        className={styles.artwork}
                        helperVisibility={this.state.withHelper}
                        hideHelp={hideHelp}
                        taskId={currentTask.taskNumber}
                    />
                    <div className={styles.rightColumn}>
                        <DetectivePeep
                            bubbleCopy={currentTask.rhinoText}
                            key={currentTask.rhinoText}
                            showHelper={showHelper}
                            shouldShowRhino={showRhinoPeep}
                        />
                        <TaskReminder
                            imgUrl={currentTask.helpImgUrl}
                            copy={currentTask.remindText}
                            subRemindTextStart={currentTask.subRemindTextStart}
                            subRemindTextEnd={currentTask.subRemindTextEnd}
                            animal={currentTask.animalNameText}
                            audio={currentTask.voiceFile}
                        />
                    </div>
                </div>
                {isModal &&
                    (
                        <Modal
                            buttonCopy='weiter!'
                            onButtonClick={this.triggerModal}
                        >
                            <>
                                <img
                                    src={currentTask.animal}
                                    alt=""
                                    className={
                                        cx(
                                            styles.image,
                                            [styles[`image${currentTask.animalName}`]]
                                        )
                                    }
                                />
                                <h1 className={styles.header}>GLÜCKWUNSCH!</h1>
                            </>
                        </Modal>
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = (state: State) => ({
    currentTask: selectCurrentTask(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    goToNextTask: () => dispatch(goToNextTask as unknown as AnyAction),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetectiveCore);