import React from 'react';
import cx from 'classnames';
import styles from './MapComponent.module.scss';
import {MapType} from '../../MapBox';

type MapComponentProps = {
    className?: string,
    type: MapType,
    onClick: () => void,
    title: string,
    image: string
}

const MapComponent: React.FC<MapComponentProps> = ({
    type,
    onClick,
    image,
    title
}) => {
    console.log(image);
    return (
        <div
            className={cx(
                styles.mapComponent, {
                    [styles.mapArea]: type === MapType.area,
                    [styles.mapMuseum]: type === MapType.museum
                }
            )}
        >
            <h2 className={styles.mapHeader}>
                {title}
            </h2>
            <div
                className={cx(
                    styles.mapContent, {
                        [styles.area]: type === MapType.area,
                        [styles.museum]: type === MapType.museum
                    }
                )}
                onClick={onClick}
                style={{backgroundImage: "url(" + image + ")"}}
            />
        </div>
    )
};

export default MapComponent;
