import React from 'react';
import Helmet from 'react-helmet';
import {connect} from "react-redux";
import {Transition} from 'react-spring/renderprops-universal';
import {Dispatch} from 'redux';
import {setCurrentScreen} from '../../../actions/common';
import MuteButton from '../../../components/MuteButton/MuteButton';
import {State} from '../../../interfaces/State';
import {ScreenName} from '../../../reducers/common';
import {actionTypes} from '../actions';
import {selectCurrentScreen} from '../selectors';
import QuizCore from './QuizCore/QuizCore';
import styles from './QuizGame.module.scss';
import QuizIntro from './QuizIntro/QuizIntro';
import QuizOutro from './QuizOutro/QuizOutro';
import Logo from '../../../components/Logo/Logo';
import OptionBox from '../../../components/OptionBox/OptionBox';

type QuizGameProps = {
    currentScreen: string, // TODO add enum
    setCurrentScreen: (name: ScreenName) => void,
    handleGoToCore: () => void;
}

const QuizGame: React.FC<QuizGameProps> = ({currentScreen, handleGoToCore}) => (
    <div className={styles.quizGame}>
        <Helmet>
            <body className={styles.quizGameBody}/>
        </Helmet>
        <Logo className={styles.hideOnMobile}/>
        <OptionBox/>
        <MuteButton />
        {currentScreen === 'intro' &&
        <Transition
            unique
            items={true}
            from={{opacity: 0}}
            enter={{opacity: 1}}
            leave={{opacity: 0}}
        >
            {() => (style) => <QuizIntro style={style} onFinish={handleGoToCore}/>}
        </Transition>
        }
        {currentScreen === 'core' &&
        <Transition
            unique
            items={true}
            from={{opacity: 0}}
            enter={{opacity: 1}}
            leave={{opacity: 0}}
        >
            {() => (style) => <QuizCore style={style}/>}
        </Transition>}
        {
            (currentScreen === 'outro') &&
            <Transition
                unique
                items={true}
                from={{opacity: 0}}
                enter={{opacity: 1}}
                leave={{opacity: 0}}
            >
                {() => (style) => <QuizOutro style={style}/>}
            </Transition>
        }
    </div>
);

const mapStateToProps = (state: State) => ({
    currentScreen: selectCurrentScreen(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    handleGoToCore: () => dispatch(setCurrentScreen(actionTypes)('core')),
});


export default connect(mapStateToProps, mapDispatchToProps)(QuizGame);