import React from 'react';
import {connect} from 'react-redux';
import {Dispatch, AnyAction} from 'redux';
import {State} from '../../interfaces/State';
import styles from './MuteButton.module.scss';
import {setMuted} from './actions';
import MutedIcon from './assets/muted.png';
import UnmutedIcon from './assets/unmuted.png';

type MuteButtonProps = {
    isMuted: boolean,
    setMuted: (isMuted: boolean) => void,
}

const MuteButton: React.FC<MuteButtonProps> = ({ isMuted, setMuted }) => {

    return (
        <img
            className={styles.muteButton}
            src={isMuted ? MutedIcon : UnmutedIcon }
            alt=""
            onClick={() => setMuted(!isMuted)}
        />
    );
};

const mapStateToProps = (state: State) => ({
    isMuted: state.isMuted,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
   setMuted: (isMuted: boolean) => dispatch(setMuted(isMuted) as unknown as AnyAction),
});

export default connect(mapStateToProps, mapDispatchToProps)(MuteButton);