import React, {useEffect, useState} from 'react';
import Helmet from 'react-helmet';
import {animated, config, Transition} from 'react-spring/renderprops';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import Button from '../../components/Button/Button';
import NavigationTopBar from '../../components/NavigationTopBar/NavigationTopBar';
import Spritesheet from 'react-responsive-spritesheet';
import Footer from '../../components/Footer/Footer';
import renderImages, {ImgData} from '../../helpers/renderImages';

import styles from './Home.module.scss';
import LogoSpritesheet from './assets/logoSpritesheet.png';
import Carousel from "../InfoForVisitors/components/Carousel/Carousel";
import slider, {SlideItemListCollection} from '../../services/sliderHome';
import {ISliderItem} from "../../services/schema/contentful";
import NewsPostit from "./components/NewsPostit/NewsPostit";
import {getClient} from "../../services/contentfulClient";
import {INLINES} from "@contentful/rich-text-types";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import ScrollTo from "../../components/ScrollTo/ScrollTo";

let clouds: ImgData[] = [

];

let flowers: ImgData[] = [
];
let sets = {
        easter: {
            museum_image: 'assets/sets/shared/museum.png',
            blanket_image: 'assets/sets/shared/blanket.png',
            grass_image: 'assets/sets/easter/grass.png',
            grass_background_image: 'assets/sets/shared/grass_bg.png',
            basin_image: 'assets/sets/shared/basin.png',
            cloud_1_image: 'assets/sets/easter/sheep_1.png',
            cloud_2_image: 'assets/sets/easter/sheep_1.png',
            cloud_3_image: 'assets/sets/easter/sheep_bird.png',
            flower_1_image: 'assets/sets/easter/easter_egg_1.png', // 1
            flower_2_image: 'assets/sets/easter/biddy.png', // 4
            flower_3_image: 'assets/sets/easter/chicken_and_biddy.png', // 3
            flower_4_image: 'assets/sets/easter/easter_egg_2.png', // 5
            flower_5_image: 'assets/sets/easter/rabbit_in_egg.png', // 2
            nele: '5HuyylwegFEy24VgEKLcmM'
        },
        summer: {
            museum_image: 'assets/sets/shared/museum.png',
            blanket_image: 'assets/sets/shared/blanket.png',
            grass_image: 'assets/sets/easter/grass.png',
            grass_background_image: 'assets/sets/shared/grass_bg.png',
            basin_image: 'assets/sets/shared/basin.png',
            cloud_1_image: 'assets/sets/summer/cloud_1.png',
            cloud_2_image: 'assets/sets/summer/cloud_2.png',
            cloud_3_image: 'assets/sets/summer/cloud_3.png',
            flower_1_image: 'assets/sets/easter/flower_yellow.png',
            flower_2_image: 'assets/sets/easter/flower_stalk.png',
            flower_3_image: 'assets/sets/easter/flower_red.png',
            flower_4_image: 'assets/sets/easter/flower_stalk_yellow.png',
            flower_5_image: 'assets/sets/easter/flower_purple.png',
            nele: '5HuyylwegFEy24VgEKLcmM'
        },
        winter: {
            museum_image: 'assets/sets/winter/museum_snow.png',
            blanket_image: 'assets/sets/winter/lake.png',
            grass_image: 'assets/sets/winter/snowman.png',
            grass_background_image: 'assets/sets/winter/snowscape.jpg',
            basin_image: 'assets/sets/winter/basin_with_snowman.png',
            cloud_1_image: 'assets/sets/winter/cloud_snow_1.png',
            cloud_2_image: 'assets/sets/winter/cloud_snow_2.png',
            cloud_3_image: 'assets/sets/winter/cloud_snow_3.png',
            flower_1_image: 'assets/sets/winter/snow_heap_1.png', // 1
            flower_2_image: 'assets/sets/winter/snow_heap_2.png', // 4
            flower_3_image: 'assets/sets/winter/snow_heap_3.png', // 3
            flower_4_image: 'assets/sets/winter/snowballs.png', // 5
            flower_5_image: 'assets/sets/winter/snow_heap_2.png', // 2
            nele: '5HuyylwegFEy24VgEKLcmM'
        },
        christmas: {
            museum_image: 'assets/sets/winter/museum_snow.png',
            blanket_image: 'assets/sets/winter/lake.png',
            grass_image: 'assets/sets/winter/snowman.png',
            grass_background_image: 'assets/sets/winter/snowscape.jpg',
            basin_image: 'assets/sets/winter/basin_with_snowman.png',
            cloud_1_image: 'assets/sets/winter/cloud_snow_1.png',
            cloud_2_image: 'assets/sets/winter/cloud_snow_2.png',
            cloud_3_image: 'assets/sets/winter/cloud_snow_3.png',
            flower_1_image: 'assets/sets/winter/snow_heap_1.png', // 1
            flower_2_image: 'assets/sets/winter/snow_heap_2.png', // 4
            flower_3_image: 'assets/sets/winter/snow_heap_3.png', // 3
            flower_4_image: 'assets/sets/winter/snowballs.png', // 5
            flower_5_image: 'assets/sets/winter/snow_heap_2.png', // 2
            nele: '5HuyylwegFEy24VgEKLcmM'
        }
    },
    nele: string = '',
    museum_image: string = '',
    blanket_image: string = '',
    grass_image: string = '',
    grass_background_image: string = '',
    basin_image: string = '',
    imageSet: string = ''
;

var grass_style_winter = {
    width: '68px',
    height: '54px'
};

const Home: React.FC<RouteComponentProps> = ({ history }) => {
    const [isLoadingImage, setIsImageLoading] = useState(true);
    const [sliderItemList, setSliderItemList] = useState([] as ISliderItem[]);
    useEffect(() => {
        slider.fetch().then(parseSlideItemListResponse);
    }, []);
    const parseSlideItemListResponse = (response: SlideItemListCollection) => {
        setSliderItemList(response.entries);
    };
    const sliderItemListOrdered = sliderItemList.sort((a, b) => a.fields.order > b.fields.order ? -1 : 1);

    const promise_imageSet = getClient().getEntry('hswGwVPgDZjHKrrZaLprk')
        .then(function (entry: any) {
            return entry.fields.imageSet[0].toLowerCase();
        });

    const promise_nele = getClient().getEntry('5HuyylwegFEy24VgEKLcmM')
        .then(function (entry: any) {
            const website_url = window.location.href;
            const options = {
                renderNode: {
                    [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
                        return ( <a href={node.data.target.fields.file.url} target="_blank"> {children} </a> )
                    },
                    [INLINES.HYPERLINK]: (node: any) => {
                        return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
                    }
                }
            };
            return documentToReactComponents(entry.fields.text, options);
        });

    Promise.all([promise_imageSet, promise_nele]).then(data => {
        imageSet = data[0];
        // @ts-ignore
        clouds.push({src: sets[imageSet].cloud_1_image, className: styles.cloud2});
        // @ts-ignore
        clouds.push({src: sets[imageSet].cloud_2_image, className: styles.cloud3});
        // @ts-ignore
        clouds.push({src: sets[imageSet].cloud_3_image, className: styles.cloud5});
        // @ts-ignore
        flowers.push({src: sets[imageSet].flower_1_image, className: styles.flower4});
        // @ts-ignore
        flowers.push({src: sets[imageSet].flower_2_image, className: styles.flower9});
        // @ts-ignore
        flowers.push({src: sets[imageSet].flower_3_image, className: styles.flower13});
        // @ts-ignore
        flowers.push({src: sets[imageSet].flower_4_image, className: styles.flower14});
        // @ts-ignore
        flowers.push({src: sets[imageSet].flower_5_image, className: styles.flower16});
        // @ts-ignore
        nele = data[1];

        // @ts-ignore
        grass_background_image = sets[imageSet].grass_background_image;
        // @ts-ignore
        museum_image = sets[imageSet].museum_image;
        // @ts-ignore
        basin_image = sets[imageSet].basin_image;
        // @ts-ignore
        blanket_image = sets[imageSet].blanket_image;
        // @ts-ignore
        grass_image = sets[imageSet].grass_image;

        setIsImageLoading(false);
    })

    return (
        <>
            <div className={styles.home}>

                <Helmet>
                    <body className={styles.homeBody} />
                </Helmet>
                <NavigationTopBar className={styles.homeNavigation} />
                <div className={styles.logoCover} />
                <Spritesheet
                    image={LogoSpritesheet}
                    className={styles.logoSpritesheet}
                    widthFrame={600}
                    heightFrame={398}
                    steps={27}
                    fps={4}
                    loop={false}
                    autoplay={true}
                    startAt={1}
                    endAt={27}
                    timeout={1200}
                />
                <div className={styles.lines}>
                    {clouds.map(renderImages)}
                    <div className={styles.grassArea}>
                        <div className={styles.grassBg} style={{backgroundImage: "url(" + grass_background_image + ")"}}>
                            {flowers.map(renderImages)}
                        </div>
                        <div className={styles.thread} />
                        <div className={styles.grassField}>
                            <Transition
                                unique
                                reset
                                items={0}
                                from={{opacity: 0}}
                                enter={{opacity: 1}}
                                leave={{opacity: 0}}
                                config={config.molasses}
                            >
                                {() => style => {
                                    return <div
                                        className={styles.bubble}
                                        style={style}
                                    >
                                        <animated.div
                                            className={styles.bubbleText}
                                        >
                                            {nele}
                                        </animated.div>
                                        <Button
                                            className={styles.startButton}
                                            onClick={() => history.push('/spiele')}
                                        >
                                            START
                                        </Button>
                                    </div>;
                                }}
                            </Transition>
                            <div className={styles.museum} style={{backgroundImage: "url(" + museum_image + ")"}}/>
                            {imageSet == 'winter' ? <div className={styles.bassin} style={{backgroundImage: "url(" + basin_image + ")"}}/> :
                                <Spritesheet
                                    image="assets/sets/shared/sprite_basin.png"
                                    className={styles.bassin}
                                    widthFrame={600}
                                    heightFrame={452}
                                    steps={6}
                                    fps={4}
                                    loop={true}
                                    autoplay={true}
                                />
                            }

                            <div className={styles.blanket} style={{backgroundImage: "url(" + blanket_image + ")"}}>
                                <div className={styles.grass} style={{backgroundImage: "url(" + grass_image + ")",...(imageSet == 'winter' ? grass_style_winter : '')}}/>
                            </div>
                            <Spritesheet
                                image="assets/sets/shared/sprite_rhino_stand_up.png"
                                className={styles.nele_stand_up}
                                widthFrame={600}
                                heightFrame={506}
                                steps={16}
                                fps={4}
                                loop={false}
                                autoplay={true}
                            />
                            <div className={styles.dog} />
                        </div>
                    </div>
                </div>
                <div className={styles.spacer} />
                <div className={styles.carouselWrapper}>
                    <Carousel
                        isKid={false}
                        slideStartingNumber={0}
                        sliderItemList={sliderItemListOrdered}
                    />
                </div>
                <NewsPostit />
            </div>
            <section className={styles.topLinkSection}>
                <ScrollTo className={styles.topLink}>NACH OBEN</ScrollTo>
            </section>
            <div className={styles.footerWrapper}>
                <Footer />
            </div>
        </>
    );
};

export default withRouter(Home);
