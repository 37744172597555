import React from 'react';

import styles from './Checkbox.module.scss';
import tick from '../../assets/check.png';

type CheckboxProps = {
  label: string,
  checked: boolean,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

const Checkbox: React.FC<CheckboxProps> = ({ label, checked = false, onChange }) => {
  return (
    <div className={styles.checkContainer}>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <div className={styles.styledCheckbox}>
        <img src={tick} alt="tick" />
        <label><span>{label}</span></label>
      </div>
    </div>
  );
};

export default Checkbox;