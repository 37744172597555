import React, {SyntheticEvent} from 'react';
import cx from 'classnames';
import playSoundIfNotMuted from '../../helpers/playSound';
import PlaySoundButtonIcon from './PlaySoundButtonIcon.png';
import styles from './PlaySoundButton.module.scss';

type PlaySoundButtonProps = {
    audioFile: string,
    className?: string,
}

// TODO it should unmute sound when clicked
const PlaySoundButton: React.FC<PlaySoundButtonProps> = ({ audioFile, className }) => {
    const handleClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        playSoundIfNotMuted(audioFile);
    };

    return (
        <img className={cx(styles.playSoundButton, className)} src={PlaySoundButtonIcon} onClick={handleClick} />
    );
};

export default PlaySoundButton;