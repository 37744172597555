import React, {CSSProperties, useEffect, useState} from 'react';
import MuteButton from '../../../../components/MuteButton/MuteButton';
import OutroModal from '../../../../components/OutroModal/OutroModal';
import playSoundIfNotMuted, {stopSound} from '../../../../helpers/playSound';
import renderImages, {ImgData} from '../../../../helpers/renderImages';
import styles from './DetectiveOutro.module.scss';
import {Transition} from 'react-spring/renderprops-universal';
import {animated} from 'react-spring';
import Logo from '../../../../components/Logo/Logo';
import Spritesheet from 'react-responsive-spritesheet';
import detectiveSpritesheet from './assets/detective_sprite.png';
import Bunch from './assets/bunch.png';
import Museum from './assets/museum.png';
import Cloud2 from '../../../../assets/cloud_2.png';
import Cloud3 from '../../../../assets/cloud_3.png';
import Cloud5 from '../../../../assets/cloud_5.png';
import Flower11 from '../../../../assets/flower_11.png';
import Grass2 from '../../assets/gras_2.png';
import Grass3 from '../../assets/gras_3.png';
import Grass7 from '../../assets/gras_7.png';
import Flower12 from '../../../../assets/flower_12.png';
import Flower9 from '../../../../assets/flower_9.png';
import Flower13 from '../../../../assets/flower_13.png';
import Flower14 from '../../../../assets/flower_14.png';
import Gift from './assets/gift.png';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import OptionBox from '../../../../components/OptionBox/OptionBox';
import {getClient} from "../../../../services/contentfulClient";

interface DetectiveOutroProps extends RouteComponentProps {
    style?: CSSProperties;
}

let winning_text: any;
const outroVoiceFile = require('./assets/voice/79.mp3');
const modalVoiceFile = require('./assets/voice/80.mp3');

const decorationalImages: ImgData[] = [
    {src: Cloud2, className: styles.cloudTiny},
    {src: Cloud3, className: styles.cloudSmall},
    {src: Cloud5, className: styles.cloudLarge},
    {src: Bunch, className: styles.bunch },
    {src: Museum, className: styles.museum},
    {src: Flower11, className: styles.flowerSowThistle},
    {src: Flower12, className: styles.flowerDaisy},
    {src: Flower9, className: styles.flowerBig},
    {src: Flower13, className: styles.flowerFieldPoppy},
    {src: Flower14, className: styles.flowerViolet},
    {src: Grass2, className: styles.grassSmall},
    {src: Grass3, className: styles.grassMedium},
    {src: Grass7, className: styles.grassLarge},
];

const DetectiveOutro: React.FC<DetectiveOutroProps> = ({
    style,
    history,
}) => {
    const [shouldShowModal, setShouldShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    getClient().getEntry('5aAHxtzM7UKrtDMcVfSeOQ')
        .then(function (entry: any) {
            winning_text = entry.fields;
            setIsLoading(false);
        })

    useEffect(() => {
        if (shouldShowModal) {
            playSoundIfNotMuted(modalVoiceFile)
        } else {
            (playSoundIfNotMuted(outroVoiceFile))
        }
        return () => stopSound()
    }, [shouldShowModal]);


    return (
        <div className={styles.detectiveOutro}>
            {shouldShowModal && <OutroModal style={style} history={history} infoForVisitorsSlideNumber={2} winning_text={winning_text} />}
            <div className={styles.margeSign}/>
            <OptionBox/>
            <MuteButton />
            <header>
                <Logo/>
            </header>
            <div className={styles.spritesheet}>
                <div
                    className={styles.bubble}
                >
                    <Transition
                        unique
                        reset
                        items={0}
                        from={{opacity: 0}}
                        enter={{opacity: 1}}
                        leave={{opacity: 0}}
                    >
                        {index => style =>
                            <animated.p
                                className={styles.bubbleText}
                                style={style}
                            >
                                Juhu – wir haben zum Glück alle gefunden! Toll, dass Du mir geholfen hast.
                                Klicke auf das Geschenk, um deinen Preis zu bekommen.
                            </animated.p>
                        }
                    </Transition>
                </div>
                <Spritesheet
                    image={detectiveSpritesheet}
                    widthFrame={800}
                    heightFrame={694}
                    steps={12}
                    fps={6}
                    loop={true}
                    startAt={1}
                    endAt={6}
                    onLoopComplete={(spritesheet: any) => {
                        if (spritesheet.getInfo('completeLoopCicles') === 1) {
                            spritesheet.goToAndPause(1);
                        }
                    }}
                />
            </div>
            {decorationalImages.map(renderImages)}
            <div className={styles.lawn}/>
            <img
                className={styles.gift}
                src={Gift}
                alt=""
                onClick={() => setShouldShowModal(true)}
            />
        </div>
    );
};

export default withRouter(DetectiveOutro);
