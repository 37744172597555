import {BubbleVariant} from '../../../../components/Bubble/Bubble';

type IntroData = {
    layerText?: string,
    text: string,
    voiceFile?: string,
    voiceTimeOut?: number,
    bubbleVariant?: BubbleVariant,
}

export const overlayText = '3 Stunden später…';

const audioFiles = [
    require('./assets/voice/60.mp3'),
    require('./assets/voice/61.mp3'),
    require('./assets/voice/62.mp3'),
    require('./assets/voice/63.mp3'),
    require('./assets/voice/64.mp3'),
];

export default [
    {
        text: 'Puhhh... Ich mag meine Tierfreunde richtig gerne, aber die sind immer so anstrengend und laut.',
        voiceFile: audioFiles[0],
        bubbleVariant: 'bubble1',
    },
    {
        text: 'Gut, dass sie nun im Museum sind und ich in Ruhe mein Mittagsschläfchen halten kann.',
        voiceFile: audioFiles[1],
        bubbleVariant: 'bubble2',
    },
    {
        // we have second time the same bubble, because of how fade out/fade in works now
        // we must keep the audio for next bubble here for some reaason (no time for changes of intro logic ;))
        text: 'Gut, dass sie nun im Museum sind und ich in Ruhe mein Mittagsschläfchen halten kann.',
        voiceFile: audioFiles[2],
        voiceTimeOut: 3000,
        bubbleVariant: 'bubble2',
    },
    {
        text: 'Die Tiere müssten doch längst wieder von ihrem Ausflug zurück sein. Im Museum habe ich gerade komische Geräusche gehört…',
    },
    {
        text: 'Wiehern, Bellen, Zischen – und sogar eine Schleimspur habe ich entdeckt!',
        voiceFile: audioFiles[3],
    },
    {
        text: 'Ich habe früher als Detektiv gearbeitet, also werde ich die Ausreißer schon finden… Kannst Du mir dabei helfen?',
        voiceFile: audioFiles[4],
    },
    {
        text: '',
    },
] as IntroData[];