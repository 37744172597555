import React, {useRef, useEffect} from 'react';
import cx from 'classnames';
import {animated} from 'react-spring';
import styles from './MapModal.module.scss';
import Close from '../../../../assets/close.png';
import {MapType} from '../../MapBox';

type MapModalProps = {
    onClose: () => void,
    type: MapType
}

const MapModal: React.FC<MapModalProps> = ({
    onClose,
    type,
}) => {

    const getHeaderTypeText = (type: MapType) => {
        switch (type) {
            case MapType.museum:
                return 'MUSEUMSPLAN';
            case MapType.area:
                return 'UMGEBUNG';
        }
    };

    const node: any = useRef();

    const handleClick = (e: Event) => {
        if (node.current.contains(e.target)) {
            return;
        }
        onClose();
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    return (
        <animated.div className={styles.modalWrapper}>
            <div
                className={styles.modal}
                ref={node}
            >
                <h2>
                    {getHeaderTypeText(type)}
                </h2>
                <img
                    onClick={onClose}
                    className={styles.closeIcon}
                    src={Close}
                    alt='schließen'
                />
                <div className={cx(
                    styles.bigMap, {
                        [styles.museum]: type === MapType.museum,
                        [styles.area]: type === MapType.area
                    }
                )}/>
            </div>
        </animated.div>
    )
};

export default MapModal;