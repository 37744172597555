import React, {useEffect} from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import Bubble, {BubbleVariant} from '../../../../../components/Bubble/Bubble';
import gameSelectionData, {Games} from '../../../../../containers/GameSelection/gameSelectionData';
import playSoundIfNotMuted, {stopSpecificSound} from '../../../../../helpers/playSound';
import renderImages, {ImgData} from '../../../../../helpers/renderImages';
import introData from '../introData';
import styles from './IntroOutdoor.module.scss';
import {Transition} from 'react-spring/renderprops';
import Flower14 from '../../../../../assets/flower_14.png';
import Flower4 from '../../../../../assets/flower_4.png';
import Flower9 from '../../../../../assets/flower_9.png';
import Flower12 from '../../../../../assets/flower_12.png';
import Flower2 from '../../../../../assets/flower_2.png';
import Flower3 from '../../../../../assets/flower_3.png';
import Flower11 from '../../../../../assets/flower_11.png';
import Grass2 from '../../../assets/gras_2.png';
import Grass3 from '../../../assets/gras_3.png';
import Grass7 from '../../../assets/gras_7.png';
import Cloud2 from '../../../../../assets/cloud_2.png';
import Cloud3 from '../../../../../assets/cloud_3.png';
import Cloud4 from '../../../../../assets/cloud_4.png';
import Cloud5 from '../../../../../assets/cloud_5.png';
import Spritesheet from 'react-responsive-spritesheet';
import detectiveSpeakSpritesheet from '../assets/detective_Intro_spritesheet.png';
import handleNext from '../../../../../helpers/handleNext';

interface IntroOutdoorProps extends RouteComponentProps {
    animationIndex: number,
    currentIntroIndex: number,
    onFinish: () => void,
    incrementIndex: () => void,
}

const clouds: ImgData[] = [
    { className: styles.cloudTiny, src: Cloud2 },
    { className: styles.cloudSmall, src: Cloud3 },
    { className: styles.cloudMedium, src: Cloud4 },
    { className: styles.cloudLarge, src: Cloud5 },
];

const flowers: ImgData[] = [
    { className: styles.grassSmall, src: Grass2 },
    { className: styles.grassMedium, src: Grass3 },
    { className: styles.grassLarge, src: Grass7 },
    { className: styles.flowerViolet, src: Flower14 },
    { className: styles.flowerDaisy, src: Flower12 },
    { className: styles.flowerBig, src: Flower9 },
    { className: styles.flowerTulip, src: Flower4 },
    { className: styles.flowerMarigold, src: Flower2 },
    { className: styles.flowerLavender, src: Flower3 },
    { className: styles.flowerSowThistle, src: Flower11 },
];

const IntroOutdoor: React.FC<IntroOutdoorProps> = ({
    animationIndex,
    currentIntroIndex,
    onFinish,
    incrementIndex,
    location,
    history,
}) => {
    useEffect(() => { // TODO it's repeated and could be refactored into common function
        // play the first sound in intro if the user comes from the app and entering url/external link
        // (so when the sound is not blocked by the browser)
        if (location.state && location.state.fromApp &&
            currentIntroIndex === 0 && introData[0].voiceFile) {
            playSoundIfNotMuted(introData[0].voiceFile);

            // clear the state so it's not preserved after page reload
            history.replace(gameSelectionData[Games.detective].link);
            return () => { if (introData[0].voiceFile) return stopSpecificSound(introData[0].voiceFile) }
        }
    }, []);

    return (
        <>
            {clouds.map(renderImages)}
            <div className={styles.spritesheet}>
                <Transition
                    unique
                    reset
                    items={currentIntroIndex}
                    from={{opacity: 0}}
                    enter={{opacity: 1}}
                    leave={{opacity: 0}}
                >
                    {index => style =>
                        <Bubble
                            className={styles.bubble}
                            variant={introData[index].bubbleVariant as BubbleVariant}
                            onClick={() => handleNext(
                                currentIntroIndex,
                                incrementIndex,
                                introData,
                                onFinish,
                            )}
                            style={style}
                            shouldShowSoundButton={(!location.state || (location.state && !location.state.fromApp)) && currentIntroIndex === 0}
                            voiceFile={introData[index].voiceFile}
                        >
                            {introData[index].text}
                        </Bubble>
                    }
                </Transition>
                {
                    currentIntroIndex < animationIndex
                        ?
                        (<Spritesheet
                            image={detectiveSpeakSpritesheet}
                            widthFrame={800}
                            heightFrame={429}
                            steps={12}
                            fps={8}
                            loop={true}
                            startAt={1}
                            endAt={7}
                        />)
                        :
                        (<Spritesheet
                            image={detectiveSpeakSpritesheet}
                            widthFrame={800}
                            heightFrame={429}
                            steps={12}
                            fps={8}
                            loop={true}
                            startAt={1}
                            endAt={7}
                            onLoopComplete={(spritesheet: any) => {
                                spritesheet.setEndAt(12);
                                if (spritesheet.getInfo('frame') === 12) {
                                    spritesheet.pause();
                                }
                            }}
                        />)
                }
            </div>
            {flowers.map(renderImages)}
        </>
    );
};

export default withRouter(IntroOutdoor);