import {AnyAction, Dispatch} from 'redux';
import {setCurrentScreen} from '../../../actions/common';
import {sleep} from '../../../helpers/sleep';
import {selectCurrentQuestionId, selectQuestionsCount} from '../selectors';
import questionsService from '../services/questions';

export const actionTypes = {
    getQuestionsInit: 'quiz/questions/get/init',
    getQuestionsSuccess: 'quiz/questions/get/success',
    getQuestionsFailure: 'quiz/questions/get/failure',
    reset: 'quiz/reset',
    setAnswer: 'quiz/answers/set',
    setCurrentIntroIndex: 'quiz/intro/currentIntroIndex/set',
    setCurrentScreen: 'quiz/currentScreen/set',
    setCurrentQuestionId: 'quiz/currentQuestionId/set',
    setFunFactStatus: 'quiz/funFact/set',
    setInterfaceLockStatus: 'quiz/lock',
};


export const setAnswer = (answerId: number) => (dispatch: Dispatch, getState: () => any) => {
    dispatch({
        type: actionTypes.setAnswer,
        payload: {answerId, questionId: selectCurrentQuestionId(getState())},
    });
};

export const setCurrentQuestionId = (id: number) => ({
    type: actionTypes.setCurrentQuestionId,
    payload: {id},
});

export const goToNextQuestion = (dispatch: Dispatch, getState: () => any) => {
    const currentQuestionId = selectCurrentQuestionId(getState());
    if (currentQuestionId + 1 < selectQuestionsCount(getState())) {
        dispatch(setFunFactStatus(false) as unknown as AnyAction);
        dispatch(setCurrentQuestionId(currentQuestionId + 1));
        dispatch(setInterfaceLockStatus(false));
    } else {
        dispatch(setCurrentScreen(actionTypes)('outro'))
    }
};

export const setFunFactStatus = (on: boolean, timeout: number = 0) => async (dispatch: Dispatch) => {
    await sleep(timeout);
    dispatch({
        type: actionTypes.setFunFactStatus,
        payload: {on},
    });
};

export const setInterfaceLockStatus = (on: boolean) => ({
    type: actionTypes.setInterfaceLockStatus,
    payload: {on},
});

export const getQuestions = async (dispatch: Dispatch) => {
    dispatch({type: actionTypes.getQuestionsInit});

    const questions = await questionsService.get();

    if (questions) {
        dispatch({
            type: actionTypes.getQuestionsSuccess,
            payload: {questions},
        })
    } else {
        dispatch({
            type: actionTypes.getQuestionsFailure,
            payload: {
                message: 'Couldn\'t load images',
            },
        })
    }

};

export const resetQuizGame = {type: actionTypes.reset};