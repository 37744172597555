import styles from "./FadeOutLayer.module.scss";
import React, {Component} from "react";

type FadeOutLayerProps = {
    layerText?: string,
};

class FadeOutLayer extends Component<FadeOutLayerProps> {
    render() {
        const {
            layerText,
        } = this.props;

        return (
            <div className={styles.showLayer}>
                {
                    layerText &&
                    (
                        <p className={styles.layerText}>
                            {layerText}
                        </p>
                    )
                }
            </div>
        )
    }

}

export default FadeOutLayer;