import cx from 'classnames';
import React from 'react';
import Spritesheet from 'react-responsive-spritesheet';
import {animated} from 'react-spring';
import {Transition} from 'react-spring/renderprops-universal';
import FadeOutLayer from '../../../../../components/FadeOutLayer/FadeOutLayer';
import MuteButton from '../../../../../components/MuteButton/MuteButton';
import handleNext from '../../../../../helpers/handleNext';
import detectiveSpritesheet from '../assets/detective_intro2_spritesheet.png';
import FrameRight from '../assets/frames-for-detective.png';
import FrameLeft from '../assets/frames-for-detective2.png';
import introData, {overlayText} from '../introData';
import styles from './IntroIndoor.module.scss';


interface IntroIndoorProps {
    currentIntroIndex: number,
    onFinish: () => void,
    incrementIndex: () => void,
}

const IntroIndoor: React.FC<IntroIndoorProps> = ({
    currentIntroIndex,
    onFinish,
    incrementIndex,
}) => (
        <>
            <FadeOutLayer layerText={overlayText}/>
            <p className={styles.layerTextDark}>
                {overlayText}
            </p>
            <img
                className={styles.frameLeft}
                src={FrameLeft}
                alt=""
            />
            <img
                className={styles.frameRight}
                src={FrameRight}
                alt=""
            />
            <div className={cx(styles.spritesheet, {[styles.animatedSpritesheet]: currentIntroIndex > 5})}>
                {
                    currentIntroIndex <= 5
                        ?
                        (
                            <>
                                <Transition
                                    unique
                                    reset
                                    items={currentIntroIndex}
                                    from={{opacity: 0}}
                                    enter={{opacity: 1}}
                                    leave={{opacity: 0}}
                                >
                                    {index => style =>
                                        <animated.p
                                            className={styles.bubble}
                                            style={style}
                                            onClick={() => handleNext(
                                                currentIntroIndex,
                                                incrementIndex,
                                                introData,
                                                onFinish,
                                            )}
                                        >
                                            {introData[index] && introData[index].text}
                                            {currentIntroIndex === 0}
                                        </animated.p>
                                    }
                                </Transition>
                                <Spritesheet
                                    image={detectiveSpritesheet}
                                    className={styles.rhino}
                                    widthFrame={800}
                                    heightFrame={366}
                                    steps={12}
                                    fps={8}
                                    loop={true}
                                    startAt={1}
                                    endAt={1}
                                />
                            </>
                        )
                        :
                        (<Spritesheet
                            image={detectiveSpritesheet}
                            className={styles.rhino}
                            widthFrame={800}
                            heightFrame={366}
                            steps={12}
                            fps={8}
                            loop={true}
                            startAt={4}
                            endAt={8}
                            onLoopComplete={(spritesheet: any) => {
                                spritesheet.setStartAt(4);
                                spritesheet.setEndAt(8);
                            }}
                        />)
                }
            </div>
            <div className={styles.floor}/>
        </>
    );

export default IntroIndoor;