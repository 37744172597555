import React, {CSSProperties} from 'react';
import Spritesheet from 'react-responsive-spritesheet';
import styles from './QuizPeep.module.scss';
import RhinoPeepSpritesheet from './rhino-peep@2x.png';

type QuizPeepProps = {
    style?: CSSProperties,
    shouldHide?: boolean,
}

const QuizPeep: React.FC<QuizPeepProps> = ({ style, shouldHide=true }) => (
    <Spritesheet
        image={RhinoPeepSpritesheet}
        className={styles.quizPeep}
        widthFrame={440}
        heightFrame={905}
        steps={9}
        fps={10}
        loop={true}

        onLoopComplete={(spritesheet: any) => {
        if (spritesheet.getInfo('completeLoopCicles') === 0) {
            spritesheet.setStartAt(5);
            spritesheet.setEndAt(9);
        }
        if (shouldHide && spritesheet.getInfo('completeLoopCicles') === 2) {
            spritesheet.setStartAt(1);
            spritesheet.setEndAt(9);
            spritesheet.setDirection('rewind');
        }
        if (spritesheet.getInfo('completeLoopCicles') === 3) {
            spritesheet.pause();
        }
    }}
        style={{ ...style, }}
    />
);

export default QuizPeep;
