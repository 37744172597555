import contentful from './contentful';
import {IHome} from './schema/contentful';

export type HomeCollection = {
    entries: IHome[]
}

const fetch = () => {
    return contentful
        .fetchEntriesForContentType('home', {limit: 1000})
        .then(response => {
            return {
                entries: response.items.map(item => item as IHome)
            }
        })
};

export default {
    fetch
}
