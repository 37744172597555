import React, {useState} from 'react';
import NavigationTopBar from '../../components/NavigationTopBar/NavigationTopBar';
import Footer from '../../components/Footer/Footer';
import PageTitle from '../../components/PageTitle/PageTitle';
import cx from 'classnames';

import styles from './Copyrights.module.scss';
import {getClient} from "../../services/contentfulClient";
import {INLINES} from "@contentful/rich-text-types";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import ScrollTo from "../../components/ScrollTo/ScrollTo";

type CopyrightsProps = {};
let Box_1: any,
    Box_2: any,
    Box_3: any,
    Box_4: any,
    Box_5: any,
    Box_6: any,
    Box_7: any,
    Box_8: any,
    Box_9: any,
    Box_10: any,
    Box_11: any;

const Copyrights: React.FC<CopyrightsProps> = () => {
    const [isLoading, setIsLoading] = useState(true);

    const promise_box_1 = getContentfulBoxContent('5juoJ1dCE51mSQ9Eusid9t'),
        promise_box_2 = getContentfulBoxContent('4s9iTipSJkfEUjgtqCCw8W'),
        promise_box_3 = getContentfulBoxContent('3cE0xiTNVKERPYdxUeOaVH'),
        promise_box_4 = getContentfulBoxContent('5q81oVngu1O0qiqzNbP9fq'),
        promise_box_5 = getContentfulBoxContent('28fZjCinRsEJ0EvYRbMf8E'),
        promise_box_6 = getContentfulBoxContent('51vY8C0UUBRHwKuDCqtbQ1'),
        promise_box_7 = getContentfulBoxContent('2j8oLaIvyXyKKBq5ZfgBJF'),
        promise_box_8 = getContentfulBoxContent('2UwqinRA4nw0EqhsgYgDNB'),
        promise_box_9 = getContentfulBoxContent('1XsWhG9SRxEFOaQFWAGTvm'),
        promise_box_10 = getContentfulBoxContent('2lywEFgX54gxWe6IMgouyc'),
        promise_box_11 = getContentfulBoxContent('56GcthXhSAZCkcJj3j754s');

    Promise.all([promise_box_1, promise_box_2, promise_box_3, promise_box_4, promise_box_5, promise_box_6, promise_box_7, promise_box_8, promise_box_9, promise_box_10, promise_box_11]).then(data => {
        Box_1 = parseContentfulText(data[0]);
        Box_2 = parseContentfulText(data[1]);
        Box_3 = parseContentfulText(data[2]);
        Box_4 = parseContentfulText(data[3]);
        Box_5 = parseContentfulText(data[4]);
        Box_6 = parseContentfulText(data[5]);
        Box_7 = parseContentfulText(data[6]);
        Box_8 = parseContentfulText(data[7]);
        Box_9 = parseContentfulText(data[8]);
        Box_10 = parseContentfulText(data[9]);
        Box_11 = parseContentfulText(data[10]);
        setIsLoading(false);
    })

    return (
        <>
            <div className={styles.outerContainer}>
                <div className={styles.wrapper}>
                    <NavigationTopBar/>
                    <PageTitle>
                        Bildunterschriften und COPYRIGHTS
                    </PageTitle>

                    <section className={styles.introText}>
                        {Box_1}
                    </section>
                    <section className={styles.copyrights}>
                        <h2 className={styles.subTitle}>
                            Bilder aus den Spielen
                        </h2>
                        <div className={styles.copyrights__content}>
                            <div className={styles.kuratorSection}>
                                <h3 className={styles.kuratorHeader}>
                                    Kurator quiz
                                </h3>

                                <div className={styles.collections} key="1">
                                    {Box_2}
                                </div>

                                <div className={cx(styles.quizSection, styles.listing)} key="2">
                                    {Box_3}
                                </div>
                            </div>
                            <div className={styles.kunstSection}>
                                <h3 className={styles.kunstHeader}>
                                    Kunst-memory
                                </h3>
                                <div className={cx(styles.round1, styles.listing)}>
                                    {Box_4}
                                </div>
                                <div className={cx(styles.round2, styles.listing)}>
                                    {Box_5}
                                </div>
                                <div className={cx(styles.round3, styles.listing)}>
                                    {Box_6}
                                </div>
                                <div className={cx(styles.round4, styles.listing)}>
                                    {Box_7}
                                </div>
                                <div className={cx(styles.round5, styles.listing)}>
                                    {Box_8}
                                </div>
                                <div className={cx(styles.round6, styles.listing)}>
                                    {Box_9}
                                </div>
                            </div>
                            <div className={styles.singlePaged}>
                                <div className={styles.photos}>
                                    <h3 className={styles.photosHeader}>
                                        FOTOGRAFIEN & GRAFIK
                                    </h3>
                                    <div className={styles.listing}>
                                        {Box_10}
                                    </div>
                                </div>
                                <div className={styles.detective}>
                                    <h3 className={styles.detectiveHeader}>
                                        Detektivspiel
                                    </h3>
                                    <div className={cx(styles.listing, styles.no_heading)}>
                                        {Box_11}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <section className={styles.topLinkSection}>
                <ScrollTo className={styles.topLink}>NACH OBEN</ScrollTo>
            </section>
            <Footer/>
        </>
    );
};

function parseContentfulText(text: any) {
    const website_url = window.location.href;
    const options = {
        renderNode: {
            [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
                return ( <a href={node.data.target.fields.file.url} target="_blank"> {children} </a> )
            },
            [INLINES.HYPERLINK]: (node: any) => {
                return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
            }
        }
    };
    return documentToReactComponents(text, options);
}

function getContentfulBoxContent(contentful_id: string) {
    return getClient().getEntry(contentful_id)
        .then(function (entry: any) {
            return entry.fields.text;
        });
}

export default Copyrights;
