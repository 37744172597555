import React from 'react';
import styles from './QuizImage.module.scss';

type QuizImageProps = {
    src: string,
}

const QuizImage: React.FC<QuizImageProps> = ({ src }) => (
    <div className={styles.quizImage}>
        <img key={src} src={src} className={styles.img} alt=""/>
    </div>
);

export default QuizImage;