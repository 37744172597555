import React, {useState} from 'react';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import NavigationTopBar from '../../components/NavigationTopBar/NavigationTopBar';
import Footer from '../../components/Footer/Footer';
import PageTitle from '../../components/PageTitle/PageTitle';
import {getClient} from '../../services/contentfulClient';
import styles from './PietsDreamTrip.module.scss';
import {INLINES} from "@contentful/rich-text-types";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {calculateImageSize} from "../../helpers/calculateImageSize";
import PictureBox from "./components/PictureBox/PictureBox";

type OfferProps = {};
let content: any;

let imgWidth = calculateImageSize(400),
    imgHeight = calculateImageSize(300);

const Offer: React.FC<OfferProps> = () => {
    const [isLoading, setIsLoading] = useState(true);

    const promise_content = getClient().getEntry('2qzMjd7QBjj5Z67LBLgdJ2')
            .then(function (entry: any) {
                return entry.fields;
            });

    Promise.all([promise_content]).then(data => {
        content = data[0];
        setIsLoading(false);
    })
    return (
        <>
            <ContentWrapper defaultStyles>
                <NavigationTopBar />
                {isLoading ? '' :
                    <div className={styles.container}>
                        <PageTitle>{content.title}</PageTitle>
                        {parseContentfulText(content.text)}
                        <div className={styles.imageContainer}>
                            {content.image1 ?
                                    <PictureBox
                                        artworkSrc={content.image1.fields.file.url + '?w=' + imgWidth + '&h=' + imgHeight}
                                    />
                                : ''}
                            {content.image2 ?
                                <PictureBox
                                    artworkSrc={content.image2.fields.file.url}
                                />
                                : ''}
                        </div>

                        {parseContentfulText(content.text2)}
                    </div>
                }
            </ContentWrapper>
            <Footer />
        </>
    );
};

function parseContentfulText(text: any) {
    console.log(text);
    const website_url = window.location.href;
    const options = {
        renderNode: {
            [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
                return ( <a href={node.data.target.fields.file.url} target="_blank"> {children} </a> )
            },
            [INLINES.HYPERLINK]: (node: any) => {
                return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
            }
        }
    };
    return documentToReactComponents(text, options);
}

function getHeading(contentful_id: string) {
    return getClient().getEntry(contentful_id)
        .then(function (entry: any) {
            return entry.fields.heading;
        });
}

export default Offer;
