import React, {MutableRefObject, useEffect, useRef} from 'react';
import Logo from '../../components/Logo/Logo';
import MuteButton from '../../components/MuteButton/MuteButton';
import OptionBox from '../../components/OptionBox/OptionBox';
import styles from './ThiefGame.module.scss';
import {setApplicationCounterTimer} from "../../actions/common";

const ThiefGame = () => {
    const refIframe = useRef(null);
    const setGameFocus = () => (refIframe as unknown as MutableRefObject<HTMLIFrameElement>).current.focus();
    useEffect(() => {
        setApplicationCounterTimer();
        setTimeout(setGameFocus, 200);
        window.addEventListener('click', setGameFocus);
        return () => window.removeEventListener('click', setGameFocus);
    });
    return (
        <div className={styles.thiefGame}>
            <OptionBox/>
            <MuteButton />
            <Logo/>
            <iframe
                src={`${process.env.PUBLIC_URL}/platform-game-201911291651/index.htm`}
                className={styles.iframe}
                width={960}
                height={540}
                ref={refIframe}
            />
        </div>
    );
};

export default ThiefGame;