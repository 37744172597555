import {BubbleData} from '../../../../components/Bubble/Bubble';
//const Audio1 = require('./assets/3.mp3');

export default [
    {
        text: 'Manche Kunstwerke sind so groß, dass sie gar nicht ins Museum passen.',
        variant: 'bubble1',
        //voiceFile: Audio1,
    },
    {
        text: 'Hilf mir, ein möglichst hohes Museum zu bauen.',
        variant: 'bubble1',
        //voiceFile: Audio1,
    },
    {
        text: 'Damit jede Kunst – egal wie groß – Platz hat. Los geht´s!',
        variant: 'bubble1',
        //voiceFile: Audio1,
    },
] as BubbleData[];
