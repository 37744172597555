
export type LabelData = {
  label: string,
  value?: string,
}

export type CardData = {
  title: string,
  sections: {
    left: LabelData[],
    right?: LabelData[],
  },
}

export type FilterData = {
  [key: string]: CardData,
}

export const filterSections: FilterData = {
  age: {
    title: 'Alter',
    sections: {
      left: [{ label: '0-3' }, { label: '4-6' }, { label: '6-9' }],
      right: [{ label: '9-12' }, { label: '12+' }],
    }
  },
  week: {
    title: 'Wann?',
    sections: {
      left: [{ label: 'Unter der woche' }, { label: 'Am wochenende' }],
    }
  },
  eventType: {
    title: 'Veranstaltungsart',
    sections: {
      left: [{ label: 'Familienführung' }, { label: 'Familienführung & Workshop' }, { label: 'Kinderworkshop' }, { label: 'Kinderferienkurs' }, { label: 'Kinderführung' }],
      right: [{ label: 'Eltern haben frei' }, { label: 'Kunst Klub' }, { label: 'Kunst mit Baby' }, { label: 'Kinderkurs' }, { label: 'Andere' }],
    }
  },
  dayTime: {
    title: 'Uhrzeit',
    sections: {
      left: [{ label: '11-15 uhr' }, { label: '15-18 uhr' }, { label: '18-21 uhr' }]
    }
  },
  familyChildren: {
    title: 'Geeignet für',
    sections: {
      left: [{ label: 'Kinder' }, { label: 'Familie' }]
    }
  }
};