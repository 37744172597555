import React from 'react';
import { ICalendarEvent } from '../../../../services/schema/contentful';
import EventBox from '../EventBox/EventBox';

type EventListProps = {
  events?: ICalendarEvent[],
}

const EventList: React.FC<EventListProps> = ({ events }) => {

  return (
    <div>
      { 
        events && events.map(event => (
          <EventBox key={event.sys.id} event={event} />
        )) 
      }
    </div>
  )
}

export default EventList;