import {AnyAction, Dispatch} from "redux";
import shuffle from "lodash.shuffle";
import range from "lodash.range";
import {setCurrentScreen, setInterfaceLockStatus} from '../../../actions/common';
import {State} from '../../../interfaces/State';
import imagesService from "../services/images";
import {
    areAnyTilesLeft,
    areSelectedTilesTheSame,
    selectChosenTiles,
    selectCurrentPlayer,
    selectNumberOfChosenTiles,
    selectTile,
    selectTwoRandomTiles,
    selectRandomChosenTilesHistoryPair,
    selectCurrentRound,
    selectCurrentShuffle,
    selectImagesForCurrentRound,
    selectWinningPlayer, selectRoundScore, selectRoundWinningPlayer, selectGameScore,
} from "../selectors";
import {AddPointAction} from "./AddPointAction";
import {PlayerName} from "../interfaces/MemoryState";
import {sleep} from "../../../helpers/sleep";
import {flipCoin} from './helpers';
import {selectCurrentIntroIndex} from "../selectors";

const SAME_TILES_NUMBER = 2;

export const actionTypes = {
    addChosenTile: 'memory/chosenTiles/add',
    addPoint: 'memory/chosenTiles/addPoint',
    getImagesInit: 'memory/images/get/init',
    getImagesSuccess: 'memory/images/get/success',
    getImagesFailure: 'memory/images/get/failure',
    removeTiles: 'memory/tiles/remove',
    removeChosenTiles: 'memory/chosenTiles/remove',
    setCurrentPlayer: 'memory/currentPlayer/set',
    setCurrentScreen: 'memory/currentScreen/set',
    setCurrentIntroIndex: 'memory/intro/currentIntroIndex/set',
    setGameScore: 'memory/gameScore/set',
    setRound: 'memory/round/set',
    setRoundScore: 'memory/roundScore/set',
    setShuffle: 'memory/shuffle/set',
    startRound: 'memory/round/start',
    finish: 'memory/finish',
    setInterfaceLockStatus: 'memory/lock',
    reset: 'memory/reset',
};

export const setCurrentIntroIndex = (index: number) => ({
    type: actionTypes.setCurrentIntroIndex,
    payload: {index},
});

export const decrementCurrentIntroIndex = (dispatch: Dispatch, getState: () => State) => {
    const currentIntroIndex = selectCurrentIntroIndex(getState());

    dispatch(setCurrentIntroIndex(currentIntroIndex - 1));
};

export const incrementCurrentIntroIndex = (dispatch: Dispatch, getState: () => State) => {
    const currentIntroIndex = selectCurrentIntroIndex(getState());

    dispatch(setCurrentIntroIndex(currentIntroIndex + 1));
};

const setRound = (roundNumber: number) => ({
    type: actionTypes.setRound,
    payload: {roundNumber: roundNumber},
});

export const startRound = {type: actionTypes.startRound};

const scoreForRound = (dispatch: Dispatch, getState: () => State) => {
    const roundWinner = selectRoundWinningPlayer(getState());
    const gameScore = selectGameScore(getState());

    if (roundWinner) { // not a draw
        dispatch({
            type: actionTypes.setGameScore,
            payload: {
                gameScore: {
                    ...gameScore,
                    [roundWinner]: gameScore[roundWinner] + 1,
                },
            },
        })
    } else { // a draw
        dispatch({
            type: actionTypes.setGameScore,
            payload: {
                gameScore: {
                    cpu: gameScore.cpu + 1,
                    user: gameScore.user + 1,
                },
            },
        })
    }
};

export const MAX_ROUNDS_NUMBER = 5; // note: this is zero-based, so 5 means 6 rounds total

export const goToNextRound = (dispatch: Dispatch, getState: () => State) => {
    const currentRound = selectCurrentRound(getState());
    if (currentRound < MAX_ROUNDS_NUMBER) {
        dispatch(setRound(currentRound + 1));
        dispatch(setShuffle(0) as unknown as AnyAction);
    } else {
        dispatch(setCurrentIntroIndex(0));
        dispatch({ type: actionTypes.finish });
        dispatch(setCurrentScreen(actionTypes)('outro'));
    }
};

export const setShuffle = (shuffleNumber: number) => (dispatch: Dispatch, getState: () => State) => {
    const images = selectImagesForCurrentRound(getState());
    const uniqueTilesNumber = images.length;
    const tiles = [...range(uniqueTilesNumber), ...range(uniqueTilesNumber)];
    const shuffledTiles: number[] = shuffle(tiles);

    dispatch({
        type: actionTypes.setShuffle,
        payload: {
            shuffleNumber: shuffleNumber,
            shuffledTiles,
        },
    })
};

const scoreForShuffle = (dispatch: Dispatch, getState: () => State) => {
    const shuffleWinner = selectWinningPlayer(getState());
    const roundScore = selectRoundScore(getState());

    if (shuffleWinner) { // not a draw
        dispatch({
            type: actionTypes.setRoundScore,
            payload: {
                roundScore: {
                    ...roundScore,
                    [shuffleWinner]: roundScore[shuffleWinner] + 1,
                },
            },
        })
    } else { // a draw
        dispatch({
            type: actionTypes.setRoundScore,
            payload: {
                roundScore: {
                    cpu: roundScore.cpu + 1,
                    user: roundScore.user + 1,
                },
            },
        })
    }
};

const MAX_SHUFFLE_NUMBER_IN_ROUND = 0; // note: this is zero-based, so 0 means 1 shuffles total

export const goToNextShuffle = (dispatch: Dispatch, getState: () => State) => {
    const currentShuffle = selectCurrentShuffle(getState());
    dispatch(scoreForShuffle as unknown as AnyAction);

    if (currentShuffle < MAX_SHUFFLE_NUMBER_IN_ROUND) {
        dispatch(setShuffle(currentShuffle + 1) as unknown as AnyAction);
    } else {
        dispatch(scoreForRound as unknown as AnyAction);
    }
};

export const addChosenTile = (tileId: number, imageId: number) => ({
    type: actionTypes.addChosenTile,
    payload: {
        tileId,
        imageId,
    },
});

export const addPoint = (player: PlayerName, imageId: number): AddPointAction => ({
    type: actionTypes.addPoint,
    payload: {player, imageId},
});

export const removeTiles = (tiles: number[]) => ({
    type: actionTypes.removeTiles,
    payload: {tiles},
});

export const removeChosenTiles = (chosenTiles: number[]) => {
    return ({
        type: actionTypes.removeChosenTiles,
        payload: {chosenTiles},
    });
};

const changeCurrentPlayer = (currentPlayer: PlayerName) => {
    switch (currentPlayer) {
        case 'cpu':
            return {
                type: actionTypes.setCurrentPlayer,
                payload: {playerName: 'user'},
            };
        case 'user':
            return {
                type: actionTypes.setCurrentPlayer,
                payload: {playerName: 'cpu'},
            };
    }
};

const makeRandomCpuTurn = async (dispatch: Dispatch, getState: () => State) => {
    dispatch({type: 'CPU turn'});
    const randomTiles = selectTwoRandomTiles(getState());
    dispatch(chooseTile(randomTiles[0]) as any);
    await sleep(400);
    dispatch(chooseTile(randomTiles[1]) as any);
};

const makeAiCpuTurn = async (dispatch: Dispatch, getState: () => State) => {
    dispatch({type: 'CPU turn'});
    const randomTiles = selectRandomChosenTilesHistoryPair(getState()) || selectTwoRandomTiles(getState());
    dispatch(chooseTile(randomTiles[0] as number) as any);
    await sleep(400);
    dispatch(chooseTile(randomTiles[1] as number) as any);
};

const makeCpuTurn = async (dispatch: Dispatch) => {
    const makeCpuTurnFunction = flipCoin(makeRandomCpuTurn, makeAiCpuTurn, 0.7);
    dispatch(makeCpuTurnFunction as unknown as AnyAction);
};

const handleLockingAndPlayerChange = (currentPlayer: PlayerName) => async (dispatch: Dispatch) => {
    if (currentPlayer === 'user') {
        dispatch(setInterfaceLockStatus(actionTypes)(true));
        await sleep(500);
        dispatch(changeCurrentPlayer(currentPlayer));
    } else { // currentPlayer === 'cpu'
        await sleep(500);
        dispatch(changeCurrentPlayer(currentPlayer));
        dispatch(setInterfaceLockStatus(actionTypes)(false));
    }
};

export const chooseTile = (tileId: number) => async (dispatch: Dispatch, getState: () => State) => {
    dispatch(setInterfaceLockStatus(actionTypes)(true));    // make sure no clicks happen while counting points, etc.

    let numberOfChosenTiles = selectNumberOfChosenTiles(getState());
    const imageId = selectTile(tileId)(getState()) as number;
    const currentPlayer = selectCurrentPlayer(getState());

    if (numberOfChosenTiles < SAME_TILES_NUMBER) {
        dispatch(addChosenTile(tileId, imageId as number));
        numberOfChosenTiles = selectNumberOfChosenTiles(getState());
    }
    if (numberOfChosenTiles === SAME_TILES_NUMBER) {
        const chosenTilesIds = Object.keys(selectChosenTiles(getState())) as unknown as number[];
        const isScoring = areSelectedTilesTheSame(getState());

        await sleep(500);
        if (isScoring) {
            dispatch(addPoint(currentPlayer, imageId));
        }

        await sleep(500);
        dispatch(removeChosenTiles(chosenTilesIds));

        if (isScoring) {
            await sleep(200);
            dispatch(removeTiles(chosenTilesIds));
        } else { // !isScoring
            dispatch(handleLockingAndPlayerChange(currentPlayer) as unknown as AnyAction);
        }

        if ((isScoring && areAnyTilesLeft(getState()) && currentPlayer === 'cpu') || (!isScoring && currentPlayer === 'user')) {
            await sleep(800);
            dispatch(makeCpuTurn as any);
        }

        if (!areAnyTilesLeft(getState())) {
            await sleep(300);
            dispatch(goToNextShuffle as unknown as AnyAction);
        }
    }
    if (currentPlayer === 'user' && areAnyTilesLeft(getState())) {
        dispatch(setInterfaceLockStatus(actionTypes)(false));   //unlock interface if user is still making a move
    }
};

export const getImages = async (dispatch: Dispatch) => {
    dispatch({type: actionTypes.getImagesInit});

    const images = await imagesService.get();

    if (images) {
        dispatch({
            type: actionTypes.getImagesSuccess,
            payload: {images},
        })
    } else {
        dispatch({
            type: actionTypes.getImagesFailure,
            payload: {
                message: 'Couldn\'t load images',
            },
        })
    }
};