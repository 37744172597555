import React from 'react';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';
import { ICalendarEvent } from '../../../../services/schema/contentful';
import calendar from '../../../../services/calendar';
import Button from '../../../../components/Button/Button';
import Accordion from '../Accordion/Accordion';

import styles from './EventBox.module.scss';

type EventBoxProps = {
  event: ICalendarEvent,
}

const EventBox: React.FC<EventBoxProps> = ({ event }) => {

  const handleButtonClick = () => {
    if (event && event.fields.buyTicketUrl) {
      window.location.href = event.fields.buyTicketUrl;
    } else {
      window.location.href = `mailto:${(event && event.fields.registerEmail) || 'bildung@kunstpalast.de'}`
    }
  }

  const renderDate = () => {
    const date = calendar.formatDate(event.fields.date, event.fields.startTime, event.fields.endTime);
    return <>
      <p>
        <span className={styles.dayOfWeek}>{date.dayOfWeek}</span>
        <span className={styles.dayAndMonth}>{date.dayAndMonth}</span>
      </p>
      <p>
        <span className={styles.hour}>{date.hour} - {date.end}</span>
      </p>
    </>;
  }

  return (
    <div className={styles.eventWrapper}>
      <div className={styles.event}>
        <section className={styles.left}>
          <span>{renderDate()}</span>
        </section>
        <section className={styles.middle}>
          <p>
            <span className={cx(styles.eventType, styles[calendar.getEventClassName(event.fields.eventType)])}>
              {event.fields.eventType}
            </span>
          </p>
          <Accordion
            title={<h3>{event.fields.title}</h3>}
            shortDescription={
              <>
                {event.fields.childAge}&nbsp;
                {event.fields.priceLabel && <>| Gebühr: {event.fields.priceLabel} </>}
                {event.fields.meetingPoint && <>| Treffpunkt: {event.fields.meetingPoint} </>}
                {event.fields.pleaseBring && <>| Bitte mitbringen: {event.fields.pleaseBring}</>}
              </>
            }
          >
            <span className={styles.description}>
              <ReactMarkdown source={event.fields.longDescription} />
            </span>
          </Accordion>
        </section>
        <section className={styles.right}>
          <Button
            className={cx(styles.signonButton, { [styles.urlButton]: event.fields.buyTicketUrl })}
            onClick={handleButtonClick}
          >
            { event.fields.buyTicketUrl ? 'Anmelden' : 'Per e-mail anmelden' }
          </Button>
        </section>
      </div>
    </div>
  )
}

export default EventBox;