import {State} from '../../../interfaces/State';
import {ScreenName} from '../../../reducers/common';
import {DetectiveState} from '../interfaces/DetectiveState';

const selectDetectiveGame = (state: State): DetectiveState => state.detective;

export const selectCurrentScreen = (state: State): ScreenName => selectDetectiveGame(state).currentScreen;

/* Intro */
export const selectCurrentIntroIndex = (state: State) => selectDetectiveGame(state).currentIntroIndex;

const selectTasks = (state: State) => selectDetectiveGame(state).tasks;

export const selectTask = (taskId: number) => (state: State) =>
    selectTasks(state)[taskId];

export const selectTasksCount = (state: State) => selectTasks(state).length;

export const selectCurrentTaskId = (state: State) => selectDetectiveGame(state).currentTaskId;

export const selectCurrentTask = (state: State) => selectTask(selectCurrentTaskId(state))(state);

