import React from 'react';
import styles from './LinkPicture.module.scss';
import cx from 'classnames';

type LinkPictureProps = {
    text: string;
    href: string,
    className?: string,
};

const LinkPicture = ({ text, href, className }: LinkPictureProps) => {

    return (
        <a href={`${process.env.PUBLIC_URL}${href}`} className={cx(styles.pictureLeft, className)}>
            <span>{text}</span>
        </a>
    );
};

export default LinkPicture;