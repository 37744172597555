import React, {Component} from 'react';
import {Helmet} from "react-helmet";

type MemoryCoreProps = {
};

class RestorerCore extends Component<MemoryCoreProps> {
    render() {

        return (
            <>
                <Helmet>
                    <script src="/kinder/assets/restorer/game.js" type="text/javascript" />
                    <link rel="stylesheet" type="text/css" href="/kinder/assets/restorer/game.css" />
                    <script>
                        var outroUrl = "fang-den-wurm/outro"
                    </script>
                </Helmet>
                <div>
                    <div id="ajaxbar">
                        <div id="game">
                            <canvas id="canvas"></canvas>
                        </div>

                        <div id="orientate"><img src="/kinder/assets/restorer/media/graphics/orientate/portrait.png"/></div>
                        <div id="play" className="play"></div>
                    </div>
                </div>
            </>
        )
    }
}


export default (RestorerCore);
