import {State} from '../../../interfaces/State';
import {ScreenName} from '../../../reducers/common';
import {QuestionData, QuizState} from '../interfaces/QuizState';

const countCorrectAnswers = (acc: number, item: QuestionData) => item.userAnswer === item.correctAnswer ? acc + 1 : acc;

export const selectQuizGame = (state: State): QuizState => state.quiz;
export const selectCurrentScreen = (state: State): ScreenName => selectQuizGame(state).currentScreen;
export const selectQuestions = (state: State): QuestionData[] => selectQuizGame(state).questions;
export const selectQuestionsCount = (state: State): number => selectQuestions(state).length;
export const selectCorrectAnswersCount = (state: State): number =>
    selectQuestions(state).reduce(countCorrectAnswers, 0);
export const selectQuestion = (id: number) => (state: State): QuestionData => selectQuestions(state)[id];
export const selectCurrentQuestionId = (state: State): number => selectQuizGame(state).currentQuestionId;
export const selectCurrentQuestion = (state: State): QuestionData => {
    const currentQuestionId = selectCurrentQuestionId(state);
    return selectQuestion(currentQuestionId)(state);
};

export const isAnswerCorrect = (answerId: number) => (state: State) => {
    const questionData = selectCurrentQuestion(state);
    if (questionData) {
        return answerId === questionData.correctAnswer;
    }
    return false;
};

export const isCurrentAnswerCorrect = (state: State) => {
    const questionData = selectCurrentQuestion(state);
    if (questionData && questionData.userAnswer) {
        return isAnswerCorrect(questionData.userAnswer)(state);
    }
    return false;
};

export const isCurrentQuestionAnswered = (state: State) => {
    const questionData = selectCurrentQuestion(state);
    return (questionData && (!!questionData.userAnswer || questionData.userAnswer === 0))
};

export const isInterfaceLocked = (state: State) => selectQuizGame(state).isInterfaceLocked;

export const shouldShowAnswers = (state: State) => !selectQuizGame(state).isFunFactOn;

export const shouldShowFunFact = (state: State) => selectQuizGame(state).isFunFactOn;

export const shouldNextButtonBeActive = (state: State) => selectQuizGame(state).isFunFactOn;

/* Intro */
export const selectCurrentIntroIndex = (state: State) => selectQuizGame(state).currentIntroIndex;