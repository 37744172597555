import React, {useEffect, useState} from 'react';
import RhinoSpritesheet from './assets/nele.png';
import PuppySpritesheet from './assets/puppy.png';
import constructionSiteOutroData from './constructionSiteOutroData';
import {Transition} from 'react-spring/renderprops-universal';
import {config} from 'react-spring';
import {getClient} from "../../../../services/contentfulClient";
import ConstructionSiteDecorationBoxOutro from "./ConstructionSiteDecorationBoxOutro";

let winning_text: any;

interface MemoryOutroProps {
}

const ConstructionSiteOutro: React.FC<MemoryOutroProps> = ({
}) => {
    const [shouldShowModal, setShouldShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    getClient().getEntry('4ImkCZQhHnA67m0ikZ72XA')
        .then(function (entry: any) {
            winning_text = entry.fields;
            setIsLoading(false);
        })

    return (
        <>
            <ConstructionSiteDecorationBoxOutro
                PuppySpritesheet={PuppySpritesheet}
                puppyWidthFrame={1170}
                puppyHeightFrame={1200}
                constructionSiteScene={'Outro'}
                bubbleData={constructionSiteOutroData}
                onBirdClick={() => setShouldShowModal(true)}
                onFinish={() => {
                    window.location.href = '/spiele';
                }}
                RhinoSpritesheet={RhinoSpritesheet}
                rhinoWidthFrame={830}
                rhinoHeightFrame={1290}
                shouldShowNavigation={true}
            />

            <Transition
                unique
                reset
                items={shouldShowModal}
                from={{opacity: 0, transform: 'translate3d(-50%, -70%, 0) scale(0.00001)'}}
                enter={{opacity: 1, transform: 'translate3d(-50%, -50%, 0) scale(1)'}}
                leave={{opacity: 0}}
                config={config.wobbly}
            >
                {(shouldShow: boolean) => shouldShow}
            </Transition>
        </>
    );
};

export default (ConstructionSiteOutro);
