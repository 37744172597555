import React from 'react';

import styles from './Loader.module.scss';

const Loader: React.FC = () => (
  <section className={styles.loaderWrapper}>
    <div className={styles['lds-ellipsis']}>
      <div />
      <div />
      <div />
      <div />
    </div>
  </section>
)

export default Loader;