import React, {Component} from 'react';
import Helmet from 'react-helmet';
import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import DetectiveCore from './DetectiveCore/DetectiveCore';
import DetectiveIntro from './DetectiveIntro/DetectiveIntro';
import DetectiveOutro from './DetectiveOutro/DetectiveOutro';
import styles from './DetectiveGame.module.scss';
import {ScreenName} from '../../../reducers/common';
import {State} from '../../../interfaces/State';
import {selectCurrentScreen} from '../selectors';
import {Dispatch} from 'redux';
import {setApplicationCounterTimer} from '../../../actions/common';
import {setCurrentScreen} from '../../../actions/common';
import {actionTypes, getTasks} from '../actions';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface DetectiveGameProps extends RouteComponentProps {
    currentScreen: ScreenName,
    handleGoToCore: () => void,
    initGame: () => void,
}

class DetectiveGame extends Component<DetectiveGameProps> {

    componentDidMount(): void {
        this.props.initGame();
        /*window.setInterval()*/
        setApplicationCounterTimer();
    }

    render() {
        const {
            currentScreen,
            handleGoToCore,
        } = this.props;
        return (
            <div className={styles.detectiveGame}>
                <Helmet>
                    <body className={styles.detectiveGameBody}/>
                </Helmet>
                {
                    currentScreen === 'intro'
                    &&
                    <DetectiveIntro onFinish={handleGoToCore}/>
                }
                {
                    currentScreen === 'core'
                    &&
                    <DetectiveCore/>
                }
                {
                    currentScreen === 'outro'
                    &&
                    <DetectiveOutro />
                }
            </div>
        );
    }
}

const mapStateToProps = (state: State) => ({
    currentScreen: selectCurrentScreen(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    handleGoToCore: () => dispatch(setCurrentScreen(actionTypes)('core')),
    initGame: () => dispatch(getTasks as unknown as AnyAction)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetectiveGame));