import React, {Component} from 'react';

type DelayedProps = {
    waitBeforeShow: number,
}

class Delayed extends Component<DelayedProps, {hidden: boolean}> {
    timeoutId?: number;

    constructor(props: Readonly<DelayedProps>) {
        super(props);
        this.state = {
            hidden: true
        };
    }
    componentDidMount() {
        this.timeoutId = setTimeout(() => {
            this.setState({hidden: false});
        }, this.props.waitBeforeShow) as unknown as number;
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutId);
    }

    render() {
        return this.state.hidden ? '' : this.props.children
    }
}

export default Delayed;