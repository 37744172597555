import {Dispatch} from 'redux';
import {setCurrentScreen} from '../../../actions/common';
import {State} from '../../../interfaces/State';
import {selectCurrentTaskId, selectTasksCount} from '../selectors';
import tasksService from '../services/tasks';

export const actionTypes = {
    getTasksInit: 'detective/tasks/get/init',
    getTasksSuccess: 'detective/tasks/get/success',
    getTasksFailure: 'detective/tasks/get/failure',
    setCurrentIntroIndex: 'detective/intro/currentIntroIndex/set',
    setCurrentScreen: 'detective/currentScreen/set',
    setCurrentTaskId: 'detective/currentCurrentTaskId/set',
    setInterfaceLockStatus: 'detective/lock',
    reset: 'detective/reset',
};


export const getTasks = async (dispatch: Dispatch) => {
    dispatch({type: actionTypes.getTasksInit});

    const tasks = await tasksService.get();

    if (tasks) {
        dispatch({
            type: actionTypes.getTasksSuccess,
            payload: {tasks},
        })
    } else {
        dispatch({
            type: actionTypes.getTasksFailure,
            payload: {
                message: 'Couldn\'t load tasks',
            },
        })
    }
};

export const setCurrentTaskId = (taskId: number) => ({
    type: actionTypes.setCurrentTaskId,
    payload: {taskId},
});

export const goToNextTask = (dispatch: Dispatch, getState: () => State) => {
    const currentTaskId = selectCurrentTaskId(getState());
    const tasksCount = selectTasksCount(getState());

    if (currentTaskId < tasksCount - 1) { // ID is zero-indexed, hence the minus one
        dispatch(setCurrentTaskId(currentTaskId + 1));
    } else {
        dispatch(setCurrentScreen(actionTypes)('outro'));
    }
};