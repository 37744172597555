import React, {CSSProperties, ReactElement, useEffect} from 'react';
import cx from 'classnames';
import {Spring} from 'react-spring/renderprops-universal';
import playSoundIfNotMuted, {stopSound} from '../../../../../../helpers/playSound';
import styles from './QuizFunFact.module.scss';

type QuizFunFact = {
    copy: ReactElement|string,
    onClick?: () => void,
    shouldShowBubble?: boolean,
    style: CSSProperties,
    voiceFile: string,
}

const QuizFunFact: React.FC<QuizFunFact> = ({ onClick, style, copy, shouldShowBubble = true, voiceFile }) => {
    useEffect(() => {
        playSoundIfNotMuted(voiceFile);

        return () => stopSound();
    }, [voiceFile]);

    return (
        <div className={cx(styles.funFact, {[styles.withBubble]: shouldShowBubble})} style={style} onClick={onClick}>
            {shouldShowBubble &&
            <Spring
                from={{opacity: 0, transform: 'translate3d(-240%, -240%, 0)'}}
                to={{opacity: 1, transform: 'none'}}
                config={{duration: 1000}}
            >
                {props => <div className={styles.bubble} style={props}/>}
            </Spring>
            }
            <p>{copy}</p>
        </div>
    );
};

export default QuizFunFact;