import React from 'react';

export interface DecorationalObject {
    className: string
}

export interface ImgData extends DecorationalObject {
    src: string
}

const renderImages = ({src, className}: ImgData) => (
    <img alt="" src={src} className={className} key={src}/>
);

export default renderImages;