import React, {ReactElement} from 'react';

export type GameSelectionData = {
    contentfulId: string,
    age: string,
    text: string,
    title: string,
    informationText: string|ReactElement,
    link: string,
    gameId: number,
    startingFrame: number,
    frameSteps: number
    headerText: string
    blockedText: string,
    spriteSheetTimeout: number,
    refreshAnimationOnScroll?: boolean,
    isBlockedOnTouchDevice?: boolean,
    whiteText: boolean,
};

export enum Games { // should be updated whenever the order of the games changes
    memory,
    quiz,
    construction,
    einstrich,
    hide,
    restorer,
    detective,
    thief,
}

export default [
    {
        contentfulId: '5sVWlwbfyplYETsyAmhkVm',
        age: '4+',
        title: 'Kunst-Memory',
        text: 'Lerne die Kunstwerke der Dauerausstellung kennen - bei einer Partie Memory mit Rhinos geflügeltem Freund Piet.',
        link: 'memory',
        gameId: 0,
        startingFrame: 1,
        frameSteps: 8,
        headerText: 'WÄHLE DEIN ABENTEUER MIT RHINO UND GEWINNE EINEN PREIS',
        blockedText: 'Dieses Spiel kannst du leider nur am Computer spielen.',
        spriteSheetTimeout: 200,
    },
    {
        contentfulId: '5aX9NWR0Kld2lczIS7nz5h',
        age: '8+',
        title: 'Kunst-Quiz',
        text: 'Hilf dem Rhino-Kurator bei seiner Ausstellung und beantworte die Fragen zu den Kunstwerken.',
        link: 'quiz',
        gameId: 1,
        startingFrame: 41,
        frameSteps: 10,
        blockedText: 'Dieses Spiel kannst du leider nur am Computer spielen.',
        spriteSheetTimeout: 1000,
        refreshAnimationOnScroll: true,
    },
    {
        contentfulId: '6drIbgWA0j2L46k3Y8ginZ',
        age: '6+',
        title: 'Kunstbau',
        text: 'Kunstbau-Spiel',
        link: 'kunstbau',
        gameId: 4,
        startingFrame: 81,
        frameSteps: 5,
        spriteSheetTimeout: 2600,
    },
    {
        contentfulId: '2UTYCpNeLppf65ChRkVuEP',
        age: '7+',
        title: 'Ein Strich!',
        text: 'Ein Strich!-Spiel',
        link: 'ein-strich',
        gameId: 5,
        startingFrame: 101,
        frameSteps: 16,
        spriteSheetTimeout: 2600,
    },
    {
        contentfulId: '3QtrOAJfRvk1TXjDgtnQek',
        age: '6+',
        title: 'Großes Verstecken',
        text: 'Großes Verstecken-Spiel',
        link: 'grosses-verstecken',
        gameId: 6,
        startingFrame: 121,
        frameSteps: 10,
        spriteSheetTimeout: 2600,
    },
    {
        contentfulId: '3DoqBUUMv28FSnqJ6z3EMO',
        age: '7+',
        title: 'Fang den Wurm',
        text: 'Fang den Wurm-Spiel',
        link: 'fang-den-wurm',
        gameId: 7,
        startingFrame: 141,
        frameSteps: 10,
        spriteSheetTimeout: 3000,
        whiteText: true,
    },
    {
        contentfulId: '4CU1KAdtMA3Z5gtC5hdMP2',
        age: '6+',
        title: 'Detektiv-Spiel',
        text: 'Gehe mit dem Rhino-Detektiv auf Spurensuche im Kunstpalast und finde die verlorenen Tiere.',
        link: 'detective',
        gameId: 2,
        startingFrame: 61,
        frameSteps: 6,
        blockedText: 'Dieses Spiel kannst du leider nur am Computer spielen.',
        spriteSheetTimeout: 1800,
        isBlockedOnTouchDevice: true,
    },
    {
        contentfulId: '6HeUxlmGVhSiF7Dz5vdAA',
        age: '8+',
        title: 'Nachts im Museum',
        text: 'Versuche Dich als Kunstdieb im Museum – aber Achtung – es lauern allerlei Hindernisse und Gefahren!',
        link: 'nachts',
        gameId: 3,
        startingFrame: 21,
        frameSteps: 8,
        blockedText: 'Dieses Spiel kannst du leider nur am Computer spielen.',
        spriteSheetTimeout: 2600,
        isBlockedOnTouchDevice: true,
    }
] as unknown as GameSelectionData[];
