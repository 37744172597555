import React from 'react';
import RestorerData from "./RestorerIntroData";
import RhinoSpritesheet from "./assets/nele.png";
import FlaskSpritesheet from "./assets/flask.png";
import DoorSpritesheet from "./assets/door.png";
import RestorerDecorationBox from "../components/RestorerDecorationBox/RestorerDecorationBox";
import {setApplicationCounterTimer} from "../../../../actions/common";

type RestorerIntroProps = {
    onFinish: () => void,
}

const RestorerIntro: React.FC<RestorerIntroProps> = ({
    onFinish
}) => {
    setApplicationCounterTimer();
    return (
        <RestorerDecorationBox
            flaskWidthFrame={185}
            flaskHeightFrame={420}
            FlaskSpritesheet={FlaskSpritesheet}
            rhinoWidthFrame={980}
            rhinoHeightFrame={1150}
            RhinoSpritesheet={RhinoSpritesheet}
            doorWidthFrame={765}
            doorHeightFrame={1020}
            DoorSpritesheet={DoorSpritesheet}
            restorerScene={'Intro'}
            bubbleData={RestorerData}
            onFinish={onFinish}
        />
    );
};

export default RestorerIntro;
