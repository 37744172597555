import React, {SyntheticEvent, useRef, useState} from 'react';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import {LocationDescriptorObject} from 'history';
import gameSelectionData from '../../gameSelectionData';
import styles from './GameBox.module.scss';
import GameSpritesheet from '../assets/game_selection_spritesheet.png';
import Spritesheet from 'react-responsive-spritesheet';
import DesktopImage from '../assets/laptop.png';

type GameBoxProps = {
    gameId: number,
    startingFrame: number,
    frameSteps: number,
    link: string | LocationDescriptorObject,
    isBlocked?: boolean,
    spritesheetTimeOut: number,
    refreshAnimation?: boolean,
    ageField: string,
    text: string,
    title: string,
    whiteText: boolean,
}

const GameBox: React.FC<GameBoxProps> = ({
     gameId,
     startingFrame,
     frameSteps,
     link,
     isBlocked,
     spritesheetTimeOut,
     refreshAnimation = false,
     ageField,
     text,
     title,
     whiteText,
}) => {

    const [blockedLayer, setBlockedLayer] = useState(false);
    const spriteInstance: any = useRef();

    const handleHover = () => {
        const spritesheet = spriteInstance.current as any;
        if (spritesheet) {
            spritesheet.play();
        }
    };

    const handleScrollAnimation = () => {
        if(refreshAnimation) {
            const spritesheet = spriteInstance.current as any;
            spritesheet.play();
        }
    };

    handleScrollAnimation();

    const showBlockedLayer = (event: SyntheticEvent) => {
        setBlockedLayer(true);
        event.preventDefault()
    };

    return (
        <section
            className={styles.gameBox}
            onClick={showBlockedLayer}
        >
            <Link
                to={isBlocked ? './spiele' : link}
                className={cx(styles.gameImage, {[styles.isBlocked]: isBlocked})}
                onMouseEnter={handleHover}
            >
                <Spritesheet
                    image={GameSpritesheet}
                    className={styles.spritesheet}
                    widthFrame={400}
                    heightFrame={500}
                    steps={frameSteps}
                    fps={12}
                    loop={true}
                    startAt={startingFrame}
                    timeout={spritesheetTimeOut}
                    autoplay={!isBlocked}
                    endAt={startingFrame + frameSteps - 1}
                    getInstance={(spritesheet: any) => {
                        spriteInstance.current = spritesheet;
                    }}
                    onLoopComplete={(spritesheet: any) => {
                        spritesheet.goToAndPause(startingFrame);
                    }}
                />
                <p className={styles.gameTitle}>
                    <span className={styles.ageField}>{ageField}</span>
                    <span className={whiteText ? styles.white : ''}>{title}</span>
                </p>
            </Link>
            <div className={styles.gameText}>
                {text}
            </div>
            {isBlocked && blockedLayer && (
                <div className={styles.blockedLayer}>
                    <img
                        className={styles.desktopImage}
                        src={DesktopImage}
                        alt=""
                    />
                    <p>{gameSelectionData[gameId].blockedText}</p>
                </div>
            )}
        </section>
    )
};

export default GameBox;
