import React, { ReactElement, ReactNode } from "react";
import cx from "classnames";
import styles from "./ContentWrapper.module.scss";

type ContentWrapperProps = {
  children: ReactNode;
  className?: string;
  defaultStyles?: boolean;
};

const ContentWrapper: React.FC<ContentWrapperProps> = ({
  children,
  className,
  defaultStyles = true
}) => (
  <div className={cx(className, { [styles.outerContainer]: defaultStyles })}>
    <div className={cx({ [styles.wrapper]: defaultStyles })}>{children}</div>
  </div>
);

export default ContentWrapper;
