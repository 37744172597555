import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './Carousel.module.scss';
import ElementPic1 from '../../assets/bunch.png';
import Slide from './Slide/Slide';
import { ISliderItem } from '../../../../services/schema/contentful';

type CarouselProps = {
    isKid: boolean,
    slideStartingNumber: number,
    sliderItemList: ISliderItem[],
}

const Carousel: React.FC<CarouselProps> = ({
   slideStartingNumber,
    isKid,
    sliderItemList,
}) => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: slideStartingNumber || 0,
    };


    return (
        <Slider
            {...settings}
            className={styles.slider}
        >
            {sliderItemList.map((slideElement, idx) => (
                <Slide
                    key={idx}
                    title={slideElement.fields.title}
                    imgSrc={slideElement.fields.image.fields.file.url}
                    text={
                        (
                            isKid &&
                            slideElement.fields.descriptionKid
                        )
                        ||
                        slideElement.fields.description
                    }
                    underText={
                        (
                            isKid &&
                            slideElement.fields.bottomLineKid
                        )
                        ||
                        slideElement.fields.bottomLine
                    }
                    elementPic={slideElement.fields.picture ? ElementPic1 : ''}
                    ctaText={slideElement.fields.ctaText}
                    ctaLink={slideElement.fields.ctaUrl}
                    ctaBehavior={slideElement.fields.ctaBehavior}
                />
            ))}
        </Slider>
    );
};

export default Carousel;
