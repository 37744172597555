import React, { Component } from 'react';
import Logo from '../../components/Logo/Logo';
import styles from './NavigationTopBar.module.scss';
import cx from 'classnames';
import BackLink from '../BackLink/BackLink';

type NavigationViewType = 'besucherinfo' | 'spiele' | 'kinderangebote' | 'kalender' | 'ausstellungen' | 'tipps-fuer-zuhause';

type NavigationTopBarProps = {
    type?: NavigationViewType,
    withBackLink?: boolean,
    backLinkHref?: string,
    className?: string,
}

interface NavigationTopBarState {
    prevScrollpos: number,
    bigNavigation: boolean,
    isMobileNavigationOpen: boolean,
}

class NavigationTopBar extends Component<NavigationTopBarProps, NavigationTopBarState> {

    constructor(props: Readonly<NavigationTopBarProps>) {
        super(props);
        this.state = {
            prevScrollpos: window.pageYOffset,
            bigNavigation: true,
            isMobileNavigationOpen: false,
        };
        this.triggerMobileNavigation = this.triggerMobileNavigation.bind(this);
        this.closeMobileNavigation = this.closeMobileNavigation.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const { prevScrollpos } = this.state;
        const currentScrollPos = window.pageYOffset;
        const bigNavigation = prevScrollpos < 350;

        this.setState({
            prevScrollpos: currentScrollPos,
            bigNavigation
        });
    };

    triggerMobileNavigation() {
        this.setState({
            isMobileNavigationOpen: true,
        });
    }

    closeMobileNavigation() {
        this.setState({
            isMobileNavigationOpen: false,
        });
    }

    render() {

        const {type, withBackLink, backLinkHref, className} = this.props;
        const {bigNavigation, isMobileNavigationOpen} = this.state;
        return (
            <div className={cx(styles.navigationWrapper, {[styles.afterScroll]: !bigNavigation}, className)}>
                {
                    withBackLink && backLinkHref &&
                    <BackLink className={styles.backLink} href={backLinkHref}/>
                }
                <Logo className={styles.mobileLogo} />
                <button
                    className={styles.navigationButton}
                    onClick={this.triggerMobileNavigation}
                />
                <ul className={cx(styles.navigationList, {[styles.mobileNavigation]: isMobileNavigationOpen})}>
                    <button
                        onClick={this.closeMobileNavigation}
                        className={cx(styles.closeButton, {[styles.closeNavigationButton]: isMobileNavigationOpen})}
                    />
                    <li className={cx(styles.listElement, {[styles.currentPage]: type === 'kinderangebote'})}>
                        <a className={styles.linkElement} href={`${process.env.PUBLIC_URL}/kinderangebote`}>
                            ANGEBOTE FÜR KINDER
                        </a>
                    </li>
                    <li className={cx(styles.listElement, {[styles.currentPage]: type === 'kalender'})}>
                        <a className={styles.linkElement} href={`${process.env.PUBLIC_URL}/kalender`}>
                            KALENDER
                        </a>
                    </li>
                    <li className={cx(styles.listElement, {[styles.currentPage]: type === 'spiele'})}>
                        <a className={styles.linkElement} href={`${process.env.PUBLIC_URL}/spiele`}>
                            ONLINE-SPIELE
                        </a>
                    </li>
                    <li className={styles.listElement}><Logo /></li>
                    <li className={cx(styles.listElement, {[styles.currentPage]: type === 'besucherinfo'})}>
                        <a className={styles.linkElement} href={`${process.env.PUBLIC_URL}/besucherinfo`}>
                            PLANE DEINEN BESUCH
                        </a>
                    </li>
                    <li className={cx(styles.listElement, {[styles.currentPage]: type === 'ausstellungen'})}>
                        <a className={styles.linkElement} href={`${process.env.PUBLIC_URL}/ausstellungen`}>
                            Aktuelle Ausstellungen
                        </a>
                    </li>
                    <li className={cx(styles.listElement, {[styles.currentPage]: type === 'tipps-fuer-zuhause'})}>
                        <a className={styles.linkElement} href={`${process.env.PUBLIC_URL}/tipps-fuer-zuhause`}>
                            Tipps für zu Hause
                        </a>
                    </li>
                </ul>
            </div>
        )
    }
}

export default NavigationTopBar;
