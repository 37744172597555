import React from 'react';
import HideData from "./HideIntroData";
import RhinoSpritesheet from "./assets/nele.png";
import PuppySpritesheet from "./assets/puppy.png";
import GoatSpritesheet from "./assets/goat.png";
import StatueSpritesheet from "./assets/statue.png";
import BirdSpritesheet from "./assets/bird.png";
import HideDecorationBox from "../components/HideDecorationBox/HideDecorationBox";
import {setApplicationCounterTimer} from "../../../../actions/common";

type HideIntroProps = {
    onFinish: () => void,
}

const HideIntro: React.FC<HideIntroProps> = ({
    onFinish
}) => {
    setApplicationCounterTimer();
    return (
        <HideDecorationBox
            puppyWidthFrame={560}
            puppyHeightFrame={320}
            PuppySpritesheet={PuppySpritesheet}
            rhinoWidthFrame={770}
            rhinoHeightFrame={1365}
            RhinoSpritesheet={RhinoSpritesheet}
            goatWidthFrame={240}
            goatHeightFrame={320}
            GoatSpritesheet={GoatSpritesheet}
            statueWidthFrame={350}
            statueHeightFrame={960}
            StatueSpritesheet={StatueSpritesheet}
            birdWidthFrame={370}
            birdHeightFrame={215}
            BirdSpritesheet={BirdSpritesheet}
            hideScene={'Intro'}
            bubbleData={HideData}
            onFinish={onFinish}
        />
    );
};

export default HideIntro;
