import React from 'react';
import cx from 'classnames';
import styles from './EventSubPostit.module.scss';
import ReactMarkdown from "react-markdown";

type EventSubPostitProps = {
    text: string,
    sizeBig: boolean,
}

const EventSubPostit: React.FC<EventSubPostitProps> = ({
    text,
    sizeBig
}) => {

    return (
        <div
            className={cx(
                styles.eventSubPostit,
                {[styles.small]: !sizeBig}
            )}
        >
            <ReactMarkdown source={text} />
        </div>
    )
};

export default EventSubPostit;
