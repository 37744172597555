const isFirstItem = (index: number) => index === 0;

const handlePrevious = (
    currentIntroIndex: number,
    decrementIndex: () => void,
) => {
    if (!isFirstItem(currentIntroIndex)) {
        decrementIndex();
    }
};

export default handlePrevious;