import React from 'react';
import styles from './Slide.module.scss';
import ReactMarkdown from "react-markdown";
import {calculateImageSize} from "../../../../../helpers/calculateImageSize";

type SlideProps = {
    title: string,
    imgSrc: string,
    text: string,
    underText: string,
    elementPic?: string,
    ctaText?: string,
    ctaLink?: string,
    ctaBehavior?: boolean,
}

let imgWidth = calculateImageSize(306),
    imgHeight = calculateImageSize(194);

const Slide: React.FC<SlideProps> = ({
    title,
    imgSrc,
    text,
    underText,
    elementPic,
    ctaText,
    ctaLink,
    ctaBehavior,
}) => {
    return (
        <>
            <div className={styles.carouselElement}>
                <h2 className={styles.title}>
                    {title}
                </h2>
                <span className={styles.frame}>
                        <img
                            className={styles.picture}
                            src={imgSrc+'?w=' + imgWidth + '&h=' + imgHeight}
                            alt=''
                        />
                    {
                        elementPic &&
                        <img
                            className={styles.elementPic}
                            src={elementPic}
                            alt=""
                        />
                    }
                    </span>
                <span className={styles.text}>
                    <ReactMarkdown source={text} />
                    <span className={styles.subtext}>
                        {underText}
                    </span>
                </span>
                {ctaLink ?  <a
                    href={ctaLink}
                    target={ctaBehavior ? '_blank' : '_self'}
                    className={styles.corouselSign}
                >
                    {ctaText ? ctaText : 'MEHR ENTDECKEN'}
                </a> :  <a
                    href={`${process.env.PUBLIC_URL}/kinderangebote`}
                    className={styles.corouselSign}
                >
                    MEHR ENTDECKEN
                </a>}

            </div>
        </>
    )
};

export default Slide;
