import React from 'react';
import cx from 'classnames';
import styles from './PictureBox.module.scss';
import ReactMarkdown from "react-markdown";

type PictureBoxProps = {
    artworkSrc: string,
    artworkName: string,
    artworkDescription: string,
    className: string,
    tapeTypeNumber: number,
}

const PictureBox: React.FC<PictureBoxProps> = ({
    artworkSrc,
    artworkName,
    artworkDescription,
    className,
    tapeTypeNumber,
}) => {

    return (
        <div className={styles.pictureBox}>
            <div className={cx(styles.tape, styles[`tape${tapeTypeNumber}`])}/>
            <img
                className={cx(styles.artElement, className)}
                src={artworkSrc}
                alt={artworkName}
            />
            <div className={styles.artDescription}>
                <strong>{artworkName}</strong>
                <ReactMarkdown source={artworkDescription} />
            </div>
        </div>
    )
};

export default PictureBox;
