import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ThiefGame from '../../games/Thief/ThiefGame';
import Home from "../Home/Home";
import { compose, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../../reducers";
import { Provider } from "react-redux";
import MemoryGame from "../../games/Memory/containers/MemoryGame";
import DetectiveGame from "../../games/Detective/containers/DetectiveGame";
import QuizGame from "../../games/Quiz/containers/QuizGame";
import GameSelection from "../GameSelection/GameSelection";
import InfoForVisitors from "../InfoForVisitors/InfoForVisitors";
import Calendar from "../Calendar/Calendar";
import FAQ from "../FAQ/FAQ";
import Copyrights from "../Copyrights/Copyrights";
import Offer from "../Offer/Offer";
import OfferAlwaysThere from "../OfferAlwaysThere/OfferAlwaysThere";
import AgainAndAgain from '../AgainAndAgain/AgainAndAgain';
import OfferWhoWant from '../OfferWhoWant/OfferWhoWant';
import TemporaryExhibition from '../TemporaryExhibition/TemporaryExhibition';
import TippsForHome from "../TippsForHome/TippsForHome";
import Kunstklick from "../Kunstklick/Kunstklick";
import AI from "../AI/AI";
import DreamTrip from "../DreamWorld/DreamTrip";
import Profile from "../Profile/Profile";
import News from "../News/News";
import NeleWantsToKnow from "../NeleWantsToKnow/NeleWantsToKnow";
import PietsDreamTrip from "../PietsDreamTrip/PietsDreamTrip";
import Lexicon from "../Lexicon/Lexicon";
import ConstructionSiteGame from "../../games/ConstructionSite/containers/ConstructionSiteGame";
import ConstructionSiteOutro from "../../games/ConstructionSite/containers/ConstructionSiteOutro/ConstructionSiteOutro";
import StudioGame from "../../games/Studio/containers/StudioGame";
import StudioOutro from "../../games/Studio/containers/StudioOutro/StudioOutro";
import HideGame from "../../games/Hide/containers/HideGame";
import HideOutro from "../../games/Hide/containers/HideOutro/HideOutro";
import RestorerGame from "../../games/Restorer/containers/RestorerGame";
import RestorerOutro from "../../games/Restorer/containers/RestorerOutro/RestorerOutro";

const passToReduxDevToolsOrNoop = (x: any) => {
    if ((window as any).__REDUX_DEVTOOLS_EXTENSION__) {
        return (window as any).__REDUX_DEVTOOLS_EXTENSION__({ trace: true })(x);
    }
    return x;
};

export const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(thunk),
        passToReduxDevToolsOrNoop
    )
);

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router basename="/kinder">
        <Route component={MemoryGame} exact path="/memory" />
        <Route component={ConstructionSiteOutro} exact path="/kunstbau/outro" />
        <Route component={ConstructionSiteGame} exact path="/kunstbau" />
        <Route component={StudioOutro} exact path="/ein-strich/outro" />
        <Route component={StudioGame} exact path="/ein-strich" />
        <Route component={HideOutro} exact path="/grosses-verstecken/outro" />
        <Route component={HideGame} exact path="/grosses-verstecken" />
        <Route component={RestorerOutro} exact path="/fang-den-wurm/outro" />
        <Route component={RestorerGame} exact path="/fang-den-wurm" />
        <Route component={QuizGame} exact path="/quiz" />
        <Route component={DetectiveGame} exact path="/detective" />
        <Route component={ThiefGame} exact path="/nachts" />
        <Route component={GameSelection} exact path="/spiele" />
        <Route component={Calendar} exact path="/kalender" />
        <Route component={Offer} exact path="/kinderangebote" />
        <Route component={OfferAlwaysThere} exact path="/kinderangebote/belgeitend" />
        <Route component={AgainAndAgain} exact path="/kinderangebote/programmformate" />
        <Route component={TemporaryExhibition} exact path="/ausstellungen" />
        <Route component={TippsForHome} exact path="/tipps-fuer-zuhause" />
        <Route render={(props) => <TippsForHome {...props} isDIY={true} isTop5={false} />} exact path="/tipps-fuer-zuhause/diy" />
        <Route render={(props) => <TippsForHome {...props} isTop5={true} isDIY={false}/>} exact path="/tipps-fuer-zuhause/top5" />
        <Route
            component={OfferWhoWant}
            exact
            path="/kinderangebote/buchbar"
        />
        <Route component={InfoForVisitors} exact path="/besucherinfo" />
        <Route component={FAQ} exact path="/besucherinfo/faq" />
        <Route component={AI} exact path="/ki" />
        <Route component={PietsDreamTrip} exact path="/piets-traumreise" />
        <Route component={DreamTrip} exact path="/traumreisen" />
        <Route component={Profile} exact path="/steckbrief" />
        <Route component={News} exact path="/news" />
        <Route component={NeleWantsToKnow} exact path="/nele-wills-wissen" />
        <Route component={Lexicon} exact path="/lexikon" />
        <Route component={Copyrights} exact path="/bildunterschriften" />
        <Route component={Home} exact path="/" />
        <Route component={Kunstklick} exact path="/kunstklick" />
      </Router>
    </Provider>
  );
};

export default App;
