import React from 'react';
import cx from 'classnames';
import styles from './ModalButton.module.scss';

type ButtonType = 'primary' | 'secondary' | 'tertiary';

type ModalButton = {
    className?: string,
    type?: ButtonType,
    copy: string,
    onClick: () => void,
}

const ModalButton: React.FC<ModalButton> = ({ className, type = 'primary', copy, onClick}) => (
    <button onClick={onClick} className={cx(styles.modalButton, className, styles[type])}>
        {copy}
    </button>
);

export default ModalButton;