import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import styles from './NewsPostit.module.scss';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import home, {HomeCollection} from '../../../../services/home';
import {IHome} from "../../../../services/schema/contentful";
import VideoModalElement from "../VideoModalElement/VideoModalElement";
import {calculateImageSize} from "../../../../helpers/calculateImageSize";

type NewsPostitProps = {}

let imgVideoWidth = calculateImageSize(376),
    imgVideoHeight = calculateImageSize(250),
    imgWidth = calculateImageSize(650),
    imgHeight = calculateImageSize(460);

const NewsPostit: React.FC<NewsPostitProps> = () => {
    const [homeItemList, sethomeItemList] = useState([] as IHome[]);
    useEffect(() => {
        home.fetch().then(parsehomeItemListResponse);
    }, []);

    const parsehomeItemListResponse = (response: HomeCollection) => {
        sethomeItemList(response.entries);
    };

    const tipItemListOrdered = homeItemList.sort((a, b) => a.fields.order > b.fields.order ? -1 : 1)

    function renderHomeList(tipItemListOrdered: any) {
        let counter = 0;
        return tipItemListOrdered.map((element: any, id: number) => {
            counter++;
            const website_url = window.location.href;
            const options = {
                renderNode: {
                    [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
                        return ( <a href={node.data.target.fields.file.url} target="_blank"> {children} </a> )
                    },
                    [INLINES.HYPERLINK]: (node: any) => {
                        return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
                    }
                }
            };
            const info = documentToReactComponents(element.fields.text, options);
            let style = (counter % 2 == 0) ? styles.redBox : styles.blueBox;
            const image = (element.fields.image) ? element.fields.image.fields.file.url : null,
                imageDescription = (element.fields.imageDescription) ? documentToReactComponents(element.fields.imageDescription) : null;

            switch (element.fields.style) {
                case 'Text + Image':
                    style = styles.text_image;
                    console.log();

                    return (
                        <div key={id}
                             className="TippsForHome_eventBox__1ZaLV">
                            <div className="PictureBox_pictureBox__1VJ0t">
                                <div className="PictureBox_tape__Vn3uC"></div>
                                <img className="PictureBox_artElement__3_rNE" src={image+'?w=' + imgWidth + '&h=' + imgHeight} alt="Anne Orthen" /><div className="PictureBox_artDescription__33n1K"><span>{imageDescription}</span></div></div>
                            <div className="EventPostit_eventPostit__2gvuo TippsForHome_cartTopSectionMiddle__3uoBI EventPostit_smallPostit__13IxI">
                                <p className={cx('EventPostit_title__3rc9A ', styles.event_title)}>{element.fields.title}</p>
                                <div className={styles.event_text}>
                                    {info}
                                </div>
                            </div>
                        </div>
                    )
                    break;
                case 'Text + Video':
                    const youtubeVideoId = (element.fields.youtubeVideoId) ? element.fields.youtubeVideoId : null;
                    return (
                        <div className={styles.movieBoxWrapper} key={id}>
                            <div className={styles.movieBox}>
                                <VideoModalElement
                                    channel='youtube'
                                    videoId={youtubeVideoId}
                                    imgSrc={image+'?w=' + imgVideoWidth + '&h=' + imgVideoHeight}
                                />
                                <div className={styles.videoTeaser}>
                                    <h3>{element.fields.title}</h3>
                                    {info}
                                </div>
                            </div>
                           <div className={styles.linkWrapper}>
                               <a href="/kinder/kunstklick"
                                  target="_self"
                                  className={styles.newsletterSubscribeLink}
                               >
                                   Alle Videos ansehen
                               </a>
                           </div>
                        </div>
                    );
                    break;
                case 'Text left + Image right':
                    style = styles.text_image;

                    return (
                        <div
                            key={id}
                            id={element.fields.anchor}
                            className={cx(
                                styles.exhibitionBox,
                                {[styles.smallElement]: element.subPostitSize === 'small'}
                            )}
                        >
                            <div className={styles.birdBoxWrapper}>
                                <div className={styles.birdBox}>
                                    <div className={styles.wrapperImage}>
                                        <div className={styles.frame}>
                                            {element.fields.link ? <a href={element.fields.link} target={element.fields.linkOpenInNewWindow ? '_blank' : '_self' }>
                                                <img className={styles.imageContainer} src={image+'?w=' + imgVideoWidth + '&h=' + imgVideoHeight}
                                                     alt="" />
                                            </a> : <img className={styles.imageContainer} src={image+'?w=' + imgVideoWidth + '&h=' + imgVideoHeight}
                                                        alt="" />}
                                            <div className={styles.magpie}></div>
                                        </div>

                                    </div>
                                    <div className={styles.birdBoxTeaser}>
                                        <h3>{element.fields.title}</h3>
                                        {info}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    break;
                default: // Also for "Large Textbox"
                    return (
                        <div
                            key={id}
                            className={cx(
                                style,
                            )}
                        >
                            <h2>{element.fields.title}</h2>
                            {info}
                        </div>
                    )
                    break;
            }
        })

    }

    return (
        <>
            <div className={cx(
                styles.wrapper,
            )}
            >
                {renderHomeList(tipItemListOrdered)}
            </div>
        </>
    )
};

export default NewsPostit;
