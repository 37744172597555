import React, {ReactElement, useEffect} from 'react';
import cx from 'classnames';
import playSoundIfNotMuted, {stopSound} from '../../../../../../helpers/playSound';
import styles from './QuizQuestion.module.scss';

type QuizQuestionProps = {
    className?: string,
    question: ReactElement,
    voiceFile: string,
}

const QuizQuestion: React.FC<QuizQuestionProps> = ({ className, question, voiceFile }) => {
    useEffect(() => {
        playSoundIfNotMuted(voiceFile);

        return () => stopSound();
    }, [voiceFile]);

    return (
        <div className={cx(styles.quizQuestion, className)}>
            {question}
        </div>
    );
};

export default QuizQuestion;