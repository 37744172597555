import React from 'react';
import cx from 'classnames';
import styles from './Counter.module.scss';

type CounterProps = {
    dataLeft: number,
    dataRight?: number,
    divider?: string,
    className?: string,
}

const Counter: React.FC<CounterProps> = ({
    className,
    dataLeft,
    dataRight,
    divider
}) => (
    <div
        className={cx(styles.score, className)}
    >
        {dataLeft}
        {divider}
        {dataRight}
    </div>
);

export default Counter;