import React, {useState} from 'react';
import styles from './OptionBox.module.scss';
import Pause from './assets/pause.png';
import Close from '../../assets/close.png';
import Modal from '../Modal/Modal';
import {withRouter, RouteComponentProps} from 'react-router';

interface OptionBoxProps extends RouteComponentProps {}

const OptionBox: React.FC<OptionBoxProps> = ({history}) =>{

    const [isPauseModal, setIsPauseModal] = useState(false);

    const triggerPauseModal = () => {
        setIsPauseModal(true);
    };

    const closePauseModal = () => {
        setIsPauseModal(false);
    };

    return (
        <div className={styles.wrapper}>
            <img
                className={styles.pauseIcon}
                src={Pause}
                alt="Pause"
                onClick={triggerPauseModal}
            />
            {isPauseModal && (
                <Modal
                    buttonCopy='SPIEL MEHR SPIELE'
                    onButtonClick={ () =>{history.push('/spiele') }}
                    alternateButtonCopy='HOMEPAGE'
                    onAlternateButtonClick={ () =>{history.push('/') }}
                >
                    <>
                        <h1 className={styles.pauseTitle}>pause</h1>
                        <img
                            className={styles.closeIcon}
                            src={Close}
                            alt="Schließen"
                            onClick={closePauseModal}
                        />
                    </>
                </Modal>
            )}
        </div>
    );
};

export default withRouter(OptionBox);