import shuffle from 'lodash.shuffle';
import groupBy from 'lodash.groupby';
import sample from 'lodash.sample';
import {State} from '../../../interfaces/State';
import {ScreenName} from '../../../reducers/common';
import {MemoryImageData, MemoryState, PlayerName, Score} from "../interfaces/MemoryState";


const selectMemoryGame = (state: State): MemoryState => state.memory;

export const selectCurrentRound = (state: State): number => selectMemoryGame(state).currentRound;
export const selectCurrentShuffle = (state: State): number => selectMemoryGame(state).currentShuffle;

export const selectImages = (state: State): MemoryImageData[][] => selectMemoryGame(state).images;

export const selectImagesForCurrentRound = (state: State): MemoryImageData[] =>
    selectImages(state)[selectCurrentRound(state)];

export const selectTiles = (state: State): (number | undefined)[] => selectMemoryGame(state).tiles;

export const areAnyTilesLeft = (state: State): boolean => !!selectTiles(state).filter((item) => item !== undefined).length;

export const selectTwoRandomTiles = (state: State): number[] => {
    const availableTiles = Object.entries(selectTiles(state))
        .filter(([index, item]) => item !== undefined);

    const shuffledAvailableTiles = shuffle(availableTiles);

    return [shuffledAvailableTiles[0][0], shuffledAvailableTiles[1][0]] as unknown as number[]
};

export const selectChosenTilesHistory = (state: State) => selectMemoryGame(state).chosenTilesHistory;

export const isSomethingInChosenTilesHistory = (state: State) => !!Object.keys(selectChosenTilesHistory(state)).length;

export const selectGroupedChosenTilesHistory = (state: State) => groupBy(
    Object.entries(selectChosenTilesHistory(state)),
    ([key, val]: any) => val,
);

export const selectChosenTilesHistoryPairs = (state: State) => Object.entries(selectGroupedChosenTilesHistory(state))
    .filter((item) => item[1].length === 2)
    .map(([key, [[tile1], [tile2]]]) => [tile1, tile2]);

export const selectRandomChosenTilesHistoryPair = (state: State) => sample(selectChosenTilesHistoryPairs(state));

export const selectTile = (tileId: number) => (state: State) => selectTiles(state)[tileId];

export const selectChosenTiles = (state: State) => selectMemoryGame(state).chosenTiles;

export const isTileChosen = (tileId: number) => (state: State) => {
    return selectMemoryGame(state).chosenTiles[tileId] !== undefined;
};

export const selectNumberOfChosenTiles = (state: State) => Object.keys(selectChosenTiles(state)).length;

export const areSelectedTilesTheSame = (state: State) => {
    const selectedTiles = Object.values(selectChosenTiles(state));
    return selectedTiles[0] === selectedTiles[1];
};

export const selectCurrentPlayer = (state: State) => selectMemoryGame(state).currentPlayer;

export const selectCurrentScreen = (state: State): ScreenName => selectMemoryGame(state).currentScreen;

export const isCurrentPlayer = (playerName: PlayerName) => (state: State) => selectCurrentPlayer(state) === playerName;

export const isUserTurn = (state: State) => isCurrentPlayer('user')(state);

export const selectPlayerScore = (playerName: PlayerName) =>
    (state: State) => Object.values(selectMemoryGame(state).points)
        .filter((scorer) => scorer === playerName).length;

export const isInterfaceLocked = (state: State) => selectMemoryGame(state).isInterfaceLocked;

export const selectWinningPlayer = (state: State): PlayerName | undefined => {
    if (selectPlayerScore('user')(state) > selectPlayerScore('cpu')(state)) {
        return 'user';
    } else if (selectPlayerScore('user')(state) < selectPlayerScore('cpu')(state)) {
        return 'cpu';
    }
    return undefined;
};

export const selectRoundWinningPlayer = (state: State): PlayerName | undefined => {
    const gameScore = selectRoundScore(state);

    if (gameScore.user > gameScore.cpu) {
        return 'user';
    } else if (gameScore.user < gameScore.cpu) {
        return 'cpu';
    }
    return undefined;
};


export const selectGameWinningPlayer = (state: State): PlayerName | undefined => {
    const gameScore = selectGameScore(state);

    if (gameScore.user > gameScore.cpu) {
        return 'user';
    } else if (gameScore.user < gameScore.cpu) {
        return 'cpu';
    }
    return undefined;
};

export const isThereDraw = (state: State): boolean =>
    selectPlayerScore('user') === selectPlayerScore('cpu');

export const hasRoundJustStarted = (state: State): boolean => selectMemoryGame(state).hasRoundJustStarted;

export const hasRoundJustFinished = (state: State): boolean => selectMemoryGame(state).hasRoundJustFinished;

export const isGameFinished = (state: State): boolean => selectMemoryGame(state).isFinished;

export const shouldShowRoundStartModal = (state: State): boolean => hasRoundJustStarted(state);

export const selectCurrentRoundNumber = (state: State): number => selectMemoryGame(state).currentRound;

export const selectRoundScore = (state: State): Score => selectMemoryGame(state).roundScore;
export const selectGameScore = (state: State): Score => selectMemoryGame(state).gameScore;
export const selectPlayerGameScore = (player: PlayerName) => (state: State): number =>
    selectMemoryGame(state).gameScore[player];

export const selectCurrentIntroIndex = (state: State) => selectMemoryGame(state).currentIntroIndex;