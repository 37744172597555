import React, {CSSProperties, useEffect, useRef} from 'react';
import Spritesheet from 'react-responsive-spritesheet';
import {Transition, animated} from 'react-spring/renderprops';
import playSoundIfNotMuted from '../../../../../../helpers/playSound';
import styles from './DetectivePeep.module.scss';
import RhinoPeepSpritesheet from './rhino-peep@2x.png';
import Delayed from '../Delayed/Delayed';
import Magnifier from '../assets/magnifying_glass.png';

const VoiceFile = require('./78.mp3');

type QuizPeepProps = {
    bubbleCopy: string,
    showHelper: () => void,
    style?: CSSProperties,
    shouldShowRhino: boolean,
}

const timeout = 45000;

// TODO universal Rhino Peep component
const DetectivePeep: React.FC<QuizPeepProps> = ({
    bubbleCopy,
    style,
    showHelper,
    shouldShowRhino,
}) => {
    const spriteInstance = useRef();

    useEffect(() => {
        if (shouldShowRhino) {
            const timeoutId = setTimeout(() => {
                playSoundIfNotMuted(VoiceFile)
            }, timeout);
            return () => clearTimeout(timeoutId);
        }
    }, [shouldShowRhino]);

    return (
        <>
            {shouldShowRhino && (
                <Delayed
                    waitBeforeShow={timeout}
                >
                    <Spritesheet
                        image={RhinoPeepSpritesheet}
                        className={styles.detectivePeep}
                        widthFrame={470}
                        heightFrame={905}
                        steps={9}
                        fps={10}
                        loop={true}
                        getInstance={(spritesheet: any) => {
                            spriteInstance.current = spritesheet;
                        }}
                        onLoopComplete={(spritesheet: any) => {
                            if (spritesheet.getInfo('completeLoopCicles') === 0) {
                                spritesheet.setStartAt(5);
                                spritesheet.setEndAt(9);
                            }
                            if (spritesheet.getInfo('completeLoopCicles') === 6) {
                                spritesheet.pause();
                            }
                        }}
                        style={{...style}}
                    />
                    <Transition
                        unique
                        reset
                        items={true}
                        from={{opacity: 0}}
                        enter={{opacity: 1}}
                        leave={{opacity: 0}}
                    >
                        {() => style => {
                            return (
                                <animated.div
                                    className={styles.bubble}
                                    style={style}
                                    onClick={showHelper}
                                >
                                    <p>{bubbleCopy}</p>
                                    <img
                                        src={Magnifier}
                                        alt=""
                                        className={styles.magnifier}
                                    />
                                </animated.div>
                            )
                        }}
                    </Transition>
                    {/* TODO tweak bubble animations, add click to skip (needs a new ticket) */}
                </Delayed>
            )}
        </>
    );
};

export default DetectivePeep;
