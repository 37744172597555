import { createClient, EntryCollection } from 'contentful';

const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID as string;
const ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN as string;
const ENVIRONMENT = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT as string;

const client = createClient({
    // This is the space ID. A space is like a project folder in Contentful terms
    space: SPACE_ID,
    // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
    accessToken: ACCESS_TOKEN,
    environment: (ENVIRONMENT) ? ENVIRONMENT : 'master',
});

// Load all entries for a given Content Type from Contentful
const fetchEntriesForContentType = (contentType: string, searchParams: any = { limit: 6 }): Promise<EntryCollection<any>> => {
    try {
        return client.getEntries({
            content_type: contentType,
            ...searchParams,
        });
    } catch (error) {
        console.log(`Error occurred while fetching Entries for ${contentType}`);
        console.error(error);
        return Promise.resolve({} as EntryCollection<any>);
    }
};

export default {
    fetchEntriesForContentType,
};
