import contentful from './contentful';
import { IFaq } from './schema/contentful';

export type FaqCollection = {
    entries: IFaq[]
}

const fetch = () => {
    return contentful
        .fetchEntriesForContentType('faq')
        .then(response => {
            return {
                entries: response.items.map(item => item as IFaq)
            }
        })
};

export default {
    fetch
}