import React, {useState} from 'react';
import cx from 'classnames';
import styles from './MapBox.module.scss';
import MapModal from './components/MapModal/MapModal';
import MapComponent from './components/MapComponent/MapComponent';

export enum MapType {
    museum,
    area
}

type MapBoxProps = {
    className?: string,
    titleMuseum: string,
    titleArea: string,
    imageMuseum: string,
    imageArea: string
}

const MapBox: React.FC<MapBoxProps> = ({className, titleArea, titleMuseum, imageArea, imageMuseum}) => {

    const [shouldShowModal, setShouldShowModal] = useState(false);
    const [showCurrentMap, setCurrentMap] = useState(MapType.area);

    const handleMap = (type: MapType) => {
        setShouldShowModal(true);
        setCurrentMap(type);
    };

    return (
        <div className={cx(styles.mapBox, className)}>
            <MapComponent
                type={MapType.museum}
                onClick={() => handleMap(MapType.museum)}
                title={titleMuseum}
                image={imageMuseum}
            />
            <MapComponent
                type={MapType.area}
                onClick={() => handleMap(MapType.area)}
                title={titleArea}
                image={imageArea}
            />
            {
                shouldShowModal && MapType &&
                <MapModal
                    onClose={()=>{setShouldShowModal(false)}}
                    type={showCurrentMap}
                />
            }
        </div>
    )
};

export default MapBox;
