import contentful from './contentful';
import { IExhibition } from './schema/contentful';

export type ExhibitionCollection = {
    entries: IExhibition[]
}

const fetch = () => {
    return contentful
        .fetchEntriesForContentType('exhibition')
        .then(response => {
            return {
                entries: response.items.map(item => item as IExhibition)
            }
        })
};

export default {
    fetch
}