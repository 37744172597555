import React from 'react';
import cx from 'classnames';
import styles from './VideoBox.module.scss';

type VideoBoxProps = {
    onClick: () => void,
    imgSrc: any,
    className?: string,
    classNameTv?: string,
}

const VideoBox: React.FC<VideoBoxProps> = ({
    onClick,
    imgSrc,
    className,
    classNameTv,
}) => {
    return (
        <div
            className={cx(className, styles.frame)}
            onClick={onClick}
        >
            <img
                className={styles.videoContainer}
                src={imgSrc}
                alt=''
            />
            <span className={styles.playSign} />
            <div className={cx(classNameTv, styles.tv)} />
        </div>
    );
};

export default VideoBox;