import {BubbleVariant} from '../../../../components/Bubble/Bubble';

export type MemoryOutroData = {
    textUser: string,
    textCpu: string,
    textBoth: string,
    bubbleVariant: BubbleVariant,
};

export default
    {
        textUser: 'Glückwunsch, Du hast gewonnen!',
        textCpu: 'Oh! Der Vogel hat gewonnen!',
        textBoth: 'Glückwunsch! Ihr beide habt gewonnen!',
        bubbleVariant: 'bubble1',
    } as MemoryOutroData;