import React, {useEffect, useState} from 'react';

import NavigationTopBar from '../../components/NavigationTopBar/NavigationTopBar';
import Footer from '../../components/Footer/Footer';
import ScrollTo from '../../components/ScrollTo/ScrollTo';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import LinkPicture from '../GameSelection/components/LinkPicture/LinkPicture';
import PageTitle from '../../components/PageTitle/PageTitle';
import imgSrc from './assets/audioguide.jpg';

import styles from './OfferAlwaysThere.module.scss';
import VideoModalElement from "../Home/components/VideoModalElement/VideoModalElement";
import {getClient} from "../../services/contentfulClient";
import {IOffers} from "../../services/schema/contentful";
import offersMuseum, {OffersCollection} from "../../services/offersMuseum";
import {INLINES} from "@contentful/rich-text-types";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import cx from "classnames";
import {calculateImageSize} from "../../helpers/calculateImageSize";

type OfferAlwaysThereProps = {};
let Heading: string,
    imgVideoWidth = calculateImageSize(550),
    imgVideoHeight = calculateImageSize(350),
    imgWidth = calculateImageSize(755),
    imgHeight = calculateImageSize(503);

const OfferAlwaysThere: React.FC<OfferAlwaysThereProps> = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [offersItemList, setOffersItemList] = useState([] as IOffers[]);

    getClient().getEntry('3GsSnDabbHeNARDrknV2z0')
        .then(function (entry: any) {
            Heading = entry.fields.heading;
            setIsLoading(false);
        })

    useEffect(() => {
        offersMuseum.fetch().then(parseOffersItemListResponse);
    }, []);

    const parseOffersItemListResponse = (response: OffersCollection) => {
        setOffersItemList(response.entries);
    };

    const offersItemListOrdered = offersItemList.sort((a, b) => a.fields.order > b.fields.order ? -1 : 1)

    function renderList(offersItemListOrdered: any) {
        let counter = 0;
        return offersItemListOrdered.map((element: any, id: number) => {
            counter++;
            const website_url = window.location.href;
            const options = {
                renderNode: {
                    [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
                        return ( <a href={node.data.target.fields.file.url} target="_blank"> {children} </a> )
                    },
                    [INLINES.HYPERLINK]: (node: any) => {
                        return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
                    }
                }
            };
            let style = (counter % 2 == 0) ? 'left' : 'right',
                image_class = '';
            const info = documentToReactComponents(element.fields.text, options),
                image = (element.fields.image) ? element.fields.image.fields.file.url : null,
                youtubeVideoId: string = (element.fields.youtubeVideoId) ? element.fields.youtubeVideoId : null;

            if (youtubeVideoId) {
                style = 'video';
            }

            if (!image) {
                image_class = styles.no_image;
            }

            let tapesArray: any = [
                    styles.tapeRed,
                    styles.tapeBlue
                ],
                randomTapeArrayNumber: number = Math.floor(Math.random() * tapesArray.length);

            let postitPositionArray: any = [
                    styles.postitNewRight,
                    styles.postitNewLeft
                ],
                randomPostitPositionArrayNumber: number = Math.floor(Math.random() * postitPositionArray.length);

            switch (style) {
                case 'left':
                    return (
                        <section className={cx(styles.postitContainer, image_class, styles.postitContainerLeft)} key={id} id={element.fields.anchor}>
                            {image ? <img className={styles.image} src={image + '?w=' + imgWidth + '&h=' + imgHeight} alt={element.fields.title} /> : ''}
                            <div className={cx(styles.postitNew, postitPositionArray[randomPostitPositionArrayNumber])}>
                                <span className={cx(styles.tape, tapesArray[randomTapeArrayNumber])}/>
                                <h2 className={styles.subTitle}>{element.fields.title}</h2>
                                {info}
                            </div>
                        </section>
                    )
                    break;
                case 'right':
                    return (
                        <section className={cx(styles.postitContainer, image_class, styles.postitContainerRight)} key={id} id={element.fields.anchor}>
                            <div className={cx(styles.postitNew, postitPositionArray[randomPostitPositionArrayNumber])}>
                                <span className={cx(styles.tape, tapesArray[randomTapeArrayNumber], styles.tapeRight)}/>
                                <h2 className={styles.subTitle}>{element.fields.title}</h2>
                                {info}
                            </div>
                            {image ? <img className={styles.image} src={image + '?w=' + imgWidth + '&h=' + imgHeight} alt={element.fields.title} /> : ''}
                        </section>
                    )
                    break;
                case 'video':
                    return (
                        <section className={cx(styles.postitContainer, styles.postitContainerVideo)} id={element.fields.anchor}>
                            <div className={cx(styles.postitNew, postitPositionArray[randomPostitPositionArrayNumber])}>
                                <span className={cx(styles.tape, tapesArray[randomTapeArrayNumber], styles.tapeRight)}/>
                                <h2 className={styles.subTitle}>{element.fields.title}</h2>
                                {info}
                            </div>
                            {youtubeVideoId ? <VideoModalElement
                                imgSrc={image + '?w=' + imgVideoWidth + '&h=' + imgVideoHeight}
                                className={styles.video}
                                classNameTv={styles.tvClass}
                                classNameWrapper={styles.wrapperVideo}
                                channel='youtube'
                                videoId={youtubeVideoId}
                            /> : ''}
                        </section>
                    )
                    break;
            }
        })
    }

    return (
        <>
            <ContentWrapper defaultStyles className={styles.offerAlwaysThere}>
                <NavigationTopBar withBackLink backLinkHref="/kinderangebote" />
                <LinkPicture text="ANGEBOTE FÜR KINDER" href="/kinderangebote" />
                <PageTitle>{Heading}</PageTitle>
                {renderList(offersItemListOrdered)}
                <section className={styles.topLinkSection}>
                    <ScrollTo className={styles.topLink}>NACH OBEN</ScrollTo>
                </section>
            </ContentWrapper>
            <Footer />
        </>
    );
};

export default OfferAlwaysThere;
