import { createClient } from 'contentful'

const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID as string;
const ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN as string;
const ENVIRONMENT = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT as string;
let client:any;

client = createClient({
    space: SPACE_ID,
    accessToken: ACCESS_TOKEN,
    environment: (ENVIRONMENT) ? ENVIRONMENT : 'master',
})

export function getClient () {
    return client
}
