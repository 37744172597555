import React, { ReactElement, useState } from 'react';
import cx from 'classnames';

import styles from './Accordion.module.scss';

type AccordionProps = {
  title: ReactElement,
  shortDescription?: ReactElement,
  children: ReactElement,
}

const Accordion: React.FC<AccordionProps> = ({ title, shortDescription, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  }

  return (
    <>
      <div className={styles.title} onClick={toggleExpanded}>
        {title}
        <div className={cx(styles.expandButton, { [styles.expanded]: isExpanded })} />
      </div>
      {
        shortDescription && <div className={styles.shortDescription}>
          {shortDescription}
        </div>
      }
      <div className={cx(styles.longDescription, { [styles.expanded]: isExpanded })}>
        {children}
      </div>
    </>
  );
}

export default Accordion;