import React from 'react';
import styles from './Postit.module.scss';
import ReactMarkdown from "react-markdown";

type PostitProps = {
    postitTitle: string,
    plainText: string,
    plainTextBottom?: string,
    subtext?: string,
}

const Postit: React.FC<PostitProps> = ({
    postitTitle,
    plainText,
    plainTextBottom,
    subtext,
}) => {
    return (
        <div className={styles.postit}>
            <h2 className={styles.postitTitle}>
                {postitTitle}
            </h2>
            <div className={styles.plainText}>
                <ReactMarkdown source={plainText} />
            </div>
            <p className={styles.plainText}>
                {plainTextBottom}
            </p>
            <p className={styles.subtext}>
                {subtext}
            </p>
        </div>
    )
};

export default Postit;
