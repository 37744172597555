import {BubbleData} from '../../../../components/Bubble/Bubble';

export default [
    {
        text: 'Wusstest Du schon …?',
        variant: 'bubble3',
        //voiceFile: Audio1,
    },
    {
        text: 'Das Bild von Peter-Paul Rubens ist so groß, dass es durch keine Türe passte.',
        variant: 'bubble3',
        //voiceFile: Audio1,
    },
    {
        text: 'Deshalb wurde extra der Fußboden aufgeschlitzt – und nachher wieder verschlossen.',
        variant: 'bubble3',
        //voiceFile: Audio1,
    },
] as BubbleData[];
