import React, {CSSProperties, ReactNode} from 'react';
import cx from 'classnames';
import PlaySoundButton from '../PlaySoundButton/PlaySoundButton';
import styles from './Bubble.module.scss';
import {animated} from 'react-spring/renderprops';

export type BubbleVariant = 'bubble1' | 'bubble2' | 'bubble2' | 'bubble3';

export type BubbleData = {
    text: ReactNode,
    variant: BubbleVariant,
    voiceFile?: string,
};

type BubbleProps = {
    children: ReactNode,
    className?: string,
    mirroredOnDesktop?: boolean,
    onClick: () => void,
    // only works for bubble1 variant for now
    shouldShowSoundButton?: boolean,
    style?: CSSProperties,
    variant: BubbleVariant,
    voiceFile?: string,
}

const Bubble: React.FC<BubbleProps> = ({
    children,
    className,
    onClick,
    mirroredOnDesktop = false,
    shouldShowSoundButton = false,
    style,
    variant,
    voiceFile,
}) => (
    <animated.div
        style={style}
        className={cx(
            styles.bubble,
            className,
            styles[variant],
            {[styles.mirroredOnDesktop]: mirroredOnDesktop}
        )}
        onClick={onClick}
    >
        <div className={styles.bubbleText}>{children}</div>
        {shouldShowSoundButton && voiceFile &&
        <PlaySoundButton audioFile={voiceFile} className={styles.playSoundButton}/>}
    </animated.div>
);

export default Bubble;