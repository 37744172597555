import React, {CSSProperties} from 'react';
import styles from './Logo.module.scss';
import cx from 'classnames';

type LogoProps = {
    className?: string,
    style?: CSSProperties,
    wrapperClassName?: string,
}

const Logo = ({className, style, wrapperClassName}: LogoProps) => (
    <div className={cx(styles.logoBox, wrapperClassName)}>
        <a
            href={`${process.env.PUBLIC_URL}/`}
            className={cx(styles.logo, className)}
            style={style}
        />
    </div>
);

export default Logo;