import {AnyAction} from 'redux';
import {setCurrentScreen} from '../../../reducers/common';
import {actionTypes} from '../actions';
import {TaskData} from '../gameData';
import {DetectiveState} from '../interfaces/DetectiveState';

const initialState: DetectiveState = {
    currentIntroIndex: 0,
    currentScreen: 'intro',
    currentTaskId: 0,
    tasks: [],
};

const setCurrentTaskId = (state: DetectiveState, {taskId}: { taskId: number }): DetectiveState => ({
    ...state,
    currentTaskId: taskId,
});

const setTasks = (state: DetectiveState, {tasks}: { tasks: TaskData[] }) => ({
    ...state,
    tasks: tasks,
});

const detectiveReducer = (state: DetectiveState = initialState, {type, payload}: AnyAction): DetectiveState => {
    switch (type) {
        case actionTypes.setCurrentIntroIndex:
            return ({...state, currentIntroIndex: payload.index});
        case actionTypes.setCurrentScreen:
            return setCurrentScreen(state, payload);
        case actionTypes.getTasksSuccess:
            return setTasks(state, payload);
        case actionTypes.setCurrentTaskId:
            return setCurrentTaskId(state, payload);
        default:
            return state;
    }
};


export default detectiveReducer;