import React from 'react';
import StudioOutroData from "./StudioIntroData";
import RhinoSpritesheet from "./assets/nele.png";
import BirdSpritesheet from "./assets/bird.png";
import StudioDecorationBoxOutro from "../components/StudioDecorationBox/StudioDecorationBox";
import {setApplicationCounterTimer} from "../../../../actions/common";

type ConstructionSiteIntroProps = {
    onFinish: () => void,
}

const StudioIntro: React.FC<ConstructionSiteIntroProps> = ({
    onFinish
}) => {
    setApplicationCounterTimer();
    return (
        <StudioDecorationBoxOutro
            birdWidthFrame={380}
            birdHeightFrame={290}
            BirdSpritesheet={BirdSpritesheet}
            rhinoWidthFrame={680}
            rhinoHeightFrame={1000}
            RhinoSpritesheet={RhinoSpritesheet}
            constructionSiteScene={'Intro'}
            bubbleData={StudioOutroData}
            onFinish={onFinish}
        />
    );
};

export default StudioIntro;
