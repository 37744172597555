import {ReactElement} from 'react';
import {BubbleVariant} from '../../../../components/Bubble/Bubble';
import styles from './QuizIntro.module.scss';

import GlassFrame from './assets/glas.png';
import PaintingFrame from './assets/gemaelde.png';
import ModernFrame from './assets/moderne.png';
import GraphicFrame from './assets/graphik.png';
import ScultptureFrame from './assets/skulptur.png';
import AppliedFrame from './assets/angewandte.png';
import PhotographyFrame from './assets/fotografie.png';

import GlassFrameDesktop from './assets/desktop/glas.png';
import PaintingFrameDesktop from './assets/desktop/gemaelde.png';
import ModernFrameDesktop from './assets/desktop/moderne.png';
import GraphicFrameDesktop from './assets/desktop/graphik.png';
import ScultptureFrameDesktop from './assets/desktop/skulptur.png';
import AppliedFrameDesktop from './assets/desktop/angewandte.png';
import PhotographyFrameDesktop from './assets/desktop/fotografie.png';

type IntroData = {
    imgUrl?: string,
    imgUrlDesktop?: string,
    bubbleClass?: string,
    text: string | ReactElement,
    voiceFile: string,
    bubbleVariant?: BubbleVariant,
};

const audioFiles = [
    require('./assets/voice/19.mp3'),
    require('./assets/voice/20.mp3'),
    require('./assets/voice/21.mp3'),
    require('./assets/voice/22.mp3'),
    require('./assets/voice/23.mp3'),
    require('./assets/voice/24.mp3'),
    require('./assets/voice/25.mp3'),
    require('./assets/voice/26.mp3'),
    require('./assets/voice/27.mp3'),
    require('./assets/voice/28.mp3'),
    require('./assets/voice/29.mp3'),
    require('./assets/voice/30.mp3'),
    require('./assets/voice/31.mp3'),
];

export default [
    {
        text: 'Hallo! Ich bin ein Kurator und arbeite im Kunstpalast.',
        bubbleClass: styles.bubble1,
        voiceFile: audioFiles[0],
        bubbleVariant: 'bubble2',
    },
    {
        text: 'Ich denke mir Ausstellungen zu bestimmten Themen aus.',
        bubbleClass: styles.bubble2,
        voiceFile: audioFiles[1],
        bubbleVariant: 'bubble3',
    },
    {
        text: 'Dafür wähle ich passende Kunstwerke aus und schreibe dazu Texte.',
        bubbleClass: styles.bubble3,
        voiceFile: audioFiles[2],
        bubbleVariant: 'bubble2',
    },
    {
        text: 'Im Kunstpalast gibt es unglaublich viele verschiedene Kunstwerke:',
        bubbleClass: styles.bubble4,
        voiceFile: audioFiles[3],
        bubbleVariant: 'bubble3',
    },
    {
        text: 'Glas',
        imgUrl: GlassFrame,
        imgUrlDesktop: GlassFrameDesktop,
        voiceFile: audioFiles[4],
    },
    {
        text: 'Gemälde',
        imgUrl: PaintingFrame,
        imgUrlDesktop: PaintingFrameDesktop,
        voiceFile: audioFiles[5],
    },
    {
        text: 'Moderne',
        imgUrl: ModernFrame,
        imgUrlDesktop: ModernFrameDesktop,
        voiceFile: audioFiles[6],
    },
    {
        text: 'Graphik',
        imgUrl: GraphicFrame,
        imgUrlDesktop: GraphicFrameDesktop,
        voiceFile: audioFiles[7],
    },
    {
        text: 'Skulptur',
        imgUrl: ScultptureFrame,
        imgUrlDesktop: ScultptureFrameDesktop,
        voiceFile: audioFiles[8],
    },
    {
        text: 'Angewandte Kunst',
        imgUrl: AppliedFrame,
        imgUrlDesktop: AppliedFrameDesktop,
        voiceFile: audioFiles[9],
    },
    {
        text: 'Fotografie',
        imgUrl: PhotographyFrame,
        imgUrlDesktop: PhotographyFrameDesktop,
        voiceFile: audioFiles[10],
    },
    {
        text: 'Ich plane gerade eine Ausstellung mit ganz besonderen Kunstwerken.',
        bubbleClass: styles.bubble5,
        voiceFile: audioFiles[11],
        bubbleVariant: 'bubble3',
    },
    {
        text: 'Kannst Du mir helfen die Fragen zu beantworten? Los Geht’s!',
        bubbleClass: styles.bubble6,
        voiceFile: audioFiles[12],
        bubbleVariant: 'bubble2',
    },
] as IntroData[];