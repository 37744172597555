import React, {useEffect} from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import cx from 'classnames';
import Bubble, {BubbleData} from '../../../../../components/Bubble/Bubble';
import MuteButton from '../../../../../components/MuteButton/MuteButton';
import gameSelectionData, {Games} from '../../../../../containers/GameSelection/gameSelectionData';
import playSoundIfNotMuted, {stopSound} from '../../../../../helpers/playSound';
import renderImages, {ImgData} from '../../../../../helpers/renderImages';
import styles from './MemoryDecorationBox.module.scss';
import Spritesheet from 'react-responsive-spritesheet';
import {Transition} from 'react-spring/renderprops';
import {State} from '../../../../../interfaces/State';
import {selectCurrentIntroIndex} from '../../../selectors';
import Navigation from '../../../../../components/Navigation/Navigation';
import handleNext from '../../../../../helpers/handleNext';
import {AnyAction, Dispatch} from 'redux';
import {decrementCurrentIntroIndex, incrementCurrentIntroIndex} from '../../../actions';
import OptionBox from '../../../../../components/OptionBox/OptionBox';
import Logo from '../../../../../components/Logo/Logo';
import Cake from '../assets/desktop/cake.png';
import Apples from '../assets/desktop/apples.png';
import Cards from '../assets/desktop/cards.png';
import Plates from '../assets/desktop/plates.png';
import Saucer from '../assets/desktop/saucer.png';
import Flower from '../assets/desktop/flower.png';
import Grass from '../assets/desktop/grass.png';
import Pot from '../assets/desktop/pot.png';

export type AnimateRhinoOrBird = 'both' | 'rhino' | 'bird';

interface MemoryDecorationBoxProps extends RouteComponentProps {
    BirdSpritesheet: string,
    birdWidthFrame?: number,
    birdHeightFrame?: number,
    currentIntroIndex: number,
    memoryScene: 'Intro' | 'Outro',
    bubbleData: BubbleData[],
    onBirdClick?: () => void,
    onFinish: () => void,
    RhinoSpritesheet: string,
    rhinoWidthFrame?: number,
    rhinoHeightFrame?: number,
    animateWhom?: AnimateRhinoOrBird,
    incrementIndex: () => void,
    decrementIndex: () => void,
    shouldShowNavigation?: boolean,
}

const decorations: ImgData[] = [
    {className: styles.cake, src: Cake},
    {className: styles.apples, src: Apples},
    {className: styles.cards, src: Cards},
    {className: styles.plates, src: Plates},
    {className: styles.pot, src: Pot},
    {className: styles.saucer, src: Saucer},
    {className: styles.flower, src: Flower},
    {className: styles.grass, src: Grass},
];

const MemoryDecorationBox: React.FC<MemoryDecorationBoxProps> = ({
    BirdSpritesheet,
    birdWidthFrame,
    birdHeightFrame,
    currentIntroIndex,
    history,
    location,
    memoryScene,
    bubbleData,
    onBirdClick,
    onFinish,
    RhinoSpritesheet,
    rhinoWidthFrame,
    rhinoHeightFrame,
    animateWhom = 'both',
    incrementIndex,
    decrementIndex,
    shouldShowNavigation = true,
}) => {

    useEffect(() => {
        // play the first sound in intro if the user comes from the app and entering url/external link
        // (so when the sound is not blocked by the browser)
        if (memoryScene === 'Intro' && location.state && location.state.fromApp &&
                currentIntroIndex === 0 && bubbleData[0].voiceFile) {
            playSoundIfNotMuted(bubbleData[0].voiceFile);

            // clear the state so it's not preserved after page reload
            history.replace(gameSelectionData[Games.memory].link);
        }
        return () => stopSound()
    }, []);

    return (
        <section className={styles.wrapper}>
            <header className={styles.hideOnMobile}>
                <Logo/>
            </header>
            <OptionBox/>
            <MuteButton />
            <div className={styles.margeSign}/>
            <div className={styles.decorationHorizontal}/>
            <div className={styles.decorationVertical}/>

            <div className={cx([styles.blanketArea, `${styles[memoryScene]}`])}>
                <Transition
                    unique
                    reset
                    items={currentIntroIndex}
                    from={{opacity: 0}}
                    enter={{opacity: 1}}
                    leave={{opacity: 0}}
                    config={{duration: 75}}
                >
                    {index => style => {
                        if (bubbleData[index]) {
                            const className = bubbleData[index].variant;

                            return (
                                <Bubble
                                    className={cx(styles.bubble, styles[className])}
                                    variant={bubbleData[index].variant}
                                    onClick={() => handleNext(
                                        currentIntroIndex,
                                        incrementIndex,
                                        bubbleData,
                                        onFinish,
                                    )}
                                    style={style}
                                    shouldShowSoundButton={(!location.state || (location.state && !location.state.fromApp)) && currentIntroIndex === 0}
                                    voiceFile={bubbleData[index].voiceFile}
                                >
                                    {bubbleData[index].text}
                                </Bubble>
                            );
                        }
                        return null;
                    }}
                </Transition>
                <Spritesheet
                    image={RhinoSpritesheet}
                    className={styles.rhino}
                    widthFrame={rhinoWidthFrame}
                    heightFrame={rhinoHeightFrame}
                    steps={6}
                    fps={6}
                    loop={true}
                    autoplay={animateWhom === 'both' || animateWhom === 'rhino'}
                    startAt={1}
                    endAt={6}
                    onLoopComplete={(spritesheet: any) => {
                        if (spritesheet.getInfo('completeLoopCicles') === 3) {
                            spritesheet.setEndAt(1);
                        }
                    }}
                />
                <Spritesheet
                    image={BirdSpritesheet}
                    className={styles.bird}
                    widthFrame={birdWidthFrame}
                    heightFrame={birdHeightFrame}
                    onClick={onBirdClick && onBirdClick}
                    steps={5}
                    fps={6}
                    loop={true}
                    autoplay={animateWhom === 'both' || animateWhom === 'bird'}
                    startAt={1}
                    endAt={5}
                    onLoopComplete={(spritesheet: any) => {
                        if (spritesheet.getInfo('completeLoopCicles') === 3) {
                            spritesheet.setEndAt(1);
                        }
                    }}
                />
                {decorations.map(renderImages)}
                <div className={styles.blanket}/>
            </div>
            {shouldShowNavigation &&
            <Navigation
                currentIndex={currentIntroIndex}
                decrementIndex={decrementIndex}
                mockData={bubbleData}
                onFinish={onFinish}
                incrementIndex={incrementIndex}
            />
            }
        </section>
    );
};

const mapStateToProps = (state: State) => ({
    currentIntroIndex: selectCurrentIntroIndex(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    decrementIndex: () => dispatch(decrementCurrentIntroIndex as unknown as AnyAction),
    incrementIndex: () => dispatch(incrementCurrentIntroIndex as unknown as AnyAction),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemoryDecorationBox));