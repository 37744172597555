import React, {useEffect} from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import cx from 'classnames';

import Bubble, {BubbleData} from '../../../../components/Bubble/Bubble';
import MuteButton from '../../../../components/MuteButton/MuteButton';
import gameSelectionData, {Games} from '../../../../containers/GameSelection/gameSelectionData';
import playSoundIfNotMuted, {stopSound} from '../../../../helpers/playSound';
import renderImages, {ImgData} from '../../../../helpers/renderImages';
import styles from './ConstructionSiteDecorationBoxOutro.module.scss';
import Spritesheet from 'react-responsive-spritesheet';
import {Transition} from 'react-spring/renderprops';
import {State} from '../../../../interfaces/State';
import {selectCurrentIntroIndex} from '../../selectors';
import Navigation from '../../../../components/Navigation/Navigation';
import handleNext from '../../../../helpers/handleNext';
import {AnyAction, Dispatch} from 'redux';
import {decrementCurrentIntroIndex, incrementCurrentIntroIndex} from '../../actions';
import OptionBox from '../../../../components/OptionBox/OptionBox';
import Logo from '../../../../components/Logo/Logo';

export type AnimateRhinoOrBird = 'both' | 'rhino' | 'bird';

interface ConstructionSiteDecorationBoxOutroProps extends RouteComponentProps {
    PuppySpritesheet: string,
    puppyWidthFrame?: number,
    puppyHeightFrame?: number,
    currentIntroIndex: number,
    constructionSiteScene: 'Intro' | 'Outro',
    bubbleData: BubbleData[],
    onBirdClick?: () => void,
    onFinish: () => void,
    RhinoSpritesheet: string,
    rhinoWidthFrame?: number,
    rhinoHeightFrame?: number,
    animateWhom?: AnimateRhinoOrBird,
    incrementIndex: () => void,
    decrementIndex: () => void,
    shouldShowNavigation?: boolean,
}

const decorations: ImgData[] = [
];

const ConstructionSiteDecorationBoxOutro: React.FC<ConstructionSiteDecorationBoxOutroProps> = ({
    PuppySpritesheet,
    puppyWidthFrame,
    puppyHeightFrame,
    currentIntroIndex,
    history,
    location,
    constructionSiteScene,
    bubbleData,
    onBirdClick,
    onFinish,
    RhinoSpritesheet,
    rhinoWidthFrame,
    rhinoHeightFrame,
    animateWhom = 'both',
    incrementIndex,
    decrementIndex,
    shouldShowNavigation = true,
}) => {

    useEffect(() => {
        // play the first sound in intro if the user comes from the app and entering url/external link
        // (so when the sound is not blocked by the browser)
        if (constructionSiteScene === 'Intro' && location.state && location.state.fromApp &&
                currentIntroIndex === 0 && bubbleData[0].voiceFile) {
            playSoundIfNotMuted(bubbleData[0].voiceFile);

            // clear the state so it's not preserved after page reload
            history.replace(gameSelectionData[Games.memory].link);
        }
        return () => stopSound()
    }, []);

    return (
        <section className={styles.wrapper}>
            <header className={styles.hideOnMobile}>
                <Logo/>
            </header>
            <OptionBox/>
            <MuteButton />
            <div className={styles.margeSign}/>

            <div className={cx([styles.backgroundArea, `${styles[constructionSiteScene]}`])}>
                <Transition
                    unique
                    reset
                    items={currentIntroIndex}
                    from={{opacity: 0}}
                    enter={{opacity: 1}}
                    leave={{opacity: 0}}
                    config={{duration: 75}}
                >
                    {index => style => {
                        if (bubbleData[index]) {
                            const className = bubbleData[index].variant;

                            return (
                                <Bubble
                                    className={cx(styles.bubble, styles[className])}
                                    variant={bubbleData[index].variant}
                                    onClick={() => handleNext(
                                        currentIntroIndex,
                                        incrementIndex,
                                        bubbleData,
                                        onFinish,
                                    )}
                                    style={style}
                                    shouldShowSoundButton={(!location.state || (location.state && !location.state.fromApp)) && currentIntroIndex === 0}
                                    voiceFile={bubbleData[index].voiceFile}
                                >
                                    {bubbleData[index].text}
                                </Bubble>
                            );
                        }
                        return null;
                    }}
                </Transition>
                <Spritesheet
                    image={RhinoSpritesheet}
                    className={styles.rhino}
                    widthFrame={rhinoWidthFrame}
                    heightFrame={rhinoHeightFrame}
                    steps={8}
                    fps={2}
                    loop={true}
                    timeout={1000}
                    autoplay={animateWhom === 'both' || animateWhom === 'rhino'}
                    startAt={1}
                    endAt={8}
                />
                <Spritesheet
                    image={PuppySpritesheet}
                    className={styles.puppy}
                    widthFrame={puppyWidthFrame}
                    heightFrame={puppyHeightFrame}
                    onClick={onBirdClick && onBirdClick}
                    steps={3}
                    fps={2}
                    loop={true}
                    autoplay={animateWhom === 'both' || animateWhom === 'bird'}
                    startAt={1}
                    endAt={3}
                />
                {decorations.map(renderImages)}
                <div className={styles.background}/>
            </div>
            {shouldShowNavigation &&
            <Navigation
                currentIndex={currentIntroIndex}
                decrementIndex={decrementIndex}
                mockData={bubbleData}
                onFinish={onFinish}
                incrementIndex={incrementIndex}
            />
            }
        </section>
    );
};

const mapStateToProps = (state: State) => ({
    currentIntroIndex: selectCurrentIntroIndex(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    decrementIndex: () => dispatch(decrementCurrentIntroIndex as unknown as AnyAction),
    incrementIndex: () => dispatch(incrementCurrentIntroIndex as unknown as AnyAction),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConstructionSiteDecorationBoxOutro));
