import React from 'react';
import cx from 'classnames';
import styles from './EventPostit.module.scss';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {Link} from 'react-router-dom';

import EventSubPostit from '../EventSubPostit/EventSubPostit';

type EventPostitProps = {
    className: string,
    date: string,
    kurator?: string,
    smallCartText: string,
    subPostitBig: boolean,
    mainData: any,
    exhibitionName: string,
}

const EventPostit: React.FC<EventPostitProps> = ({
    className,
    date,
    kurator,
    smallCartText,
    subPostitBig,
    mainData,
    exhibitionName,
}) => {

    const data = mainData.content;
    const website_url = window.location.href;
    const options = {
    renderNode: {
        [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
          return ( <a href={node.data.target.fields.file.url} target="_blank"> {children} </a> )
        },
        [INLINES.HYPERLINK]: (node: any) => {
            return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
        }
      }
    };
    const info = documentToReactComponents(mainData, options);
    function renderParagraphData(data: any) {
        return data.map((element: any, id: number) => {
            return (
                <p
                    key={id}
                    className={cx(
                        styles.paragraph,
                        {[styles.primaryText]: id === 0}
                        )}
                >
                </p>
            )
        })
    }

    return (
        <div className={cx(
            styles.eventPostit,
            className,
            {[styles.smallPostit]: !subPostitBig})}
        >
            <p className={styles.title}>
                {exhibitionName}
            </p>
            <p className={styles.date}>
                {date}
            </p>
            {info}
            {
                kurator
                ?
                <p className={styles.paragraph}>
                    <span className={styles.bold}>
                        Kurator:
                    </span>
                    {kurator}
                </p>
                :
                null
            }
            <EventSubPostit
                text={smallCartText}
                sizeBig={subPostitBig}
            />
        </div>
    )
};

export default EventPostit;
