import React, {Component, CSSProperties} from 'react';
import {connect} from 'react-redux';
import {animateScroll} from 'react-scroll';
import {Spring, Trail} from 'react-spring/renderprops-universal';
import {AnyAction, Dispatch} from 'redux';
import Button from '../../../../components/Button/Button';
import {State} from '../../../../interfaces/State';
import {
    getQuestions,
    goToNextQuestion,
    setAnswer,
    setFunFactStatus,
    setInterfaceLockStatus,
} from '../../actions';
import {QuestionData} from '../../interfaces/QuizState';
import {
    isAnswerCorrect,
    isCurrentAnswerCorrect,
    isCurrentQuestionAnswered,
    isInterfaceLocked,
    selectCurrentQuestion,
    selectCurrentQuestionId, selectQuestionsCount,
    shouldNextButtonBeActive,
    shouldShowAnswers, shouldShowFunFact,
} from '../../selectors';
import QuizFunFact from './components/QuizFunFact/QuizFunFact';
import QuizPeep from './components/QuizPeep/QuizPeep';
import QuizAnswer from './components/QuizAnswer/QuizAnswer';
import QuizQuestion from './components/QuizQuestion/QuizQuestion';
import QuizQuestionNumber from './components/QuizQuestionNumber/QuizQuestionNumber';
import styles from './QuizCore.module.scss';
import QuizImage from './components/QuizImage/QuizImage';


type QuizCoreProps = {
    currentQuestion: QuestionData,
    currentQuestionId: number,
    getQuestions: () => void,
    handleAnswer: (id: number) => () => void
    handleNextQuestion: () => void,
    isAnswerCorrect: (answerId: number) => boolean,
    isCurrentAnswerCorrect: boolean,
    isCurrentQuestionAnswered: boolean,
    isInterfaceLocked: boolean,
    shouldShowAnswers: boolean,
    shouldShowFunFact: boolean,
    shouldNextButtonBeActive: boolean,
    style?: CSSProperties,
    questionsCount: number,
};

class QuizCore extends Component<QuizCoreProps> {

    componentDidMount(): void {
        this.props.getQuestions();
    }

    render() {
        const {
            currentQuestion,
            currentQuestionId,
            handleAnswer,
            handleNextQuestion,
            isAnswerCorrect,
            isInterfaceLocked,
            isCurrentAnswerCorrect,
            isCurrentQuestionAnswered,
            shouldShowAnswers,
            shouldShowFunFact,
            shouldNextButtonBeActive,
            style,
            questionsCount,
        } = this.props;

        return (
            <>
                {currentQuestion && <>
                    <QuizQuestionNumber current={currentQuestionId + 1} count={questionsCount}/>
                    <div className={styles.quizCore} style={style}>
                        <QuizImage src={currentQuestion.imgUrl} />
                        {
                            shouldShowAnswers &&
                            <div className={styles.rightSide}>
                                <QuizPeep/>
                                <QuizQuestion
                                    className={styles.question}
                                    question={currentQuestion.question}
                                    voiceFile={currentQuestion.questionVoiceFile}
                                />
                                <div
                                    className={styles.quizAnswers}
                                >
                                    <Trail
                                        delay={2700}
                                        config={{mass: 2, friction: 20, tension: 170}}
                                        items={currentQuestion.answers}
                                        from={{opacity: 0, transform: 'translateX(-100%)'}}
                                        to={{opacity: 1, transform: 'translateY(0)'}}
                                    >
                                        {(answer: string, index: number) => (props: CSSProperties) => (
                                            <QuizAnswer
                                                key={answer}
                                                style={props}
                                                answer={answer}
                                                onClick={isInterfaceLocked ? undefined : handleAnswer(index)}
                                                isChecked={index === currentQuestion.userAnswer}
                                                isAnsweredCorrectly={isCurrentAnswerCorrect && index === currentQuestion.userAnswer}
                                                isCorrect={isCurrentQuestionAnswered && isAnswerCorrect(index)}
                                            />
                                        )}
                                    </Trail>
                                </div>
                            </div>
                        }
                        {
                            shouldShowFunFact &&
                            <div className={styles.rightSide}>
                                <QuizPeep shouldHide={false}/>
                                <div className={styles.quizAnswers}>
                                    <Spring
                                        config={{mass: 1, friction: 20, tension: 170}}
                                        from={{opacity: 0, transform: 'perspective(100px) translateZ(-100px)'}}
                                        to={{opacity: 1, transform: 'perspective(100px) translateZ(0)'}}
                                    >
                                        {(props: CSSProperties) => (
                                            <QuizFunFact
                                                style={props}
                                                copy={currentQuestion.funFact}
                                                onClick={shouldNextButtonBeActive ? handleNextQuestion : undefined}
                                                voiceFile={currentQuestion.funFactVoiceFile}
                                            />
                                        )}
                                    </Spring>
                                    <Spring
                                        config={{mass: 1, friction: 12, tension: 200}}
                                        from={{
                                            opacity: 0,
                                            transform: 'perspective(100px) translate3d(50px, 0, -100px)',
                                        }}
                                        to={{opacity: 1, transform: 'perspective(100px) translate3d(0, 0, 0)'}}

                                    >
                                        {(props: CSSProperties) => (
                                            <div className={styles.nextQuestionWrapper} style={props}>
                                                <Button
                                                    active={shouldNextButtonBeActive}
                                                    className={styles.nextQuestion}
                                                    onClick={shouldNextButtonBeActive ? handleNextQuestion : undefined}
                                                >Nächste Frage</Button>
                                            </div>
                                        )}
                                    </Spring>
                                </div>
                            </div>
                        }
                    </div>
                </>}
            </>
        )
    }
}

const mapStateToProps = (state: State) => ({
    currentQuestion: selectCurrentQuestion(state),
    currentQuestionId: selectCurrentQuestionId(state),
    isInterfaceLocked: isInterfaceLocked(state),
    isAnswerCorrect: (answerId: number) => isAnswerCorrect(answerId)(state),
    isCurrentAnswerCorrect: isCurrentAnswerCorrect(state),
    isCurrentQuestionAnswered: isCurrentQuestionAnswered(state),
    shouldShowAnswers: shouldShowAnswers(state),
    shouldShowFunFact: shouldShowFunFact(state),
    shouldNextButtonBeActive: shouldNextButtonBeActive(state),
    questionsCount: selectQuestionsCount(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getQuestions: (): void => dispatch(getQuestions as any),
    handleAnswer: (id: number) => (): void => {
        dispatch(setAnswer(id) as unknown as AnyAction);
        dispatch(setInterfaceLockStatus(true));
        dispatch(setFunFactStatus(true, 2000) as unknown as AnyAction)
    },
    handleNextQuestion: () => {
        animateScroll.scrollToTop({duration: 220});
        return dispatch(goToNextQuestion as unknown as AnyAction);
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizCore);