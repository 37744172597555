import React, {ReactElement, useEffect} from 'react';
import {config} from 'react-spring';
import {Transition} from 'react-spring/renderprops-universal';
import Modal, {ModalType} from '../../../../../../components/Modal/Modal';
import playSoundIfNotMuted, {stopSound} from '../../../../../../helpers/playSound';
import {MAX_ROUNDS_NUMBER} from '../../../../actions';
import {PlayerName} from '../../../../interfaces/MemoryState';

const roundVoiceFiles = [
    require('./assets/6.mp3'),
    require('./assets/7.mp3'),
    require('./assets/8.mp3'),
    require('./assets/9.mp3'),
    require('./assets/10.mp3'),
    require('./assets/11.mp3'),
];

const getWinnerDisplayText = (winner?: PlayerName) => {
  switch (winner) {
      case 'user':
          return <><strong>Du</strong> hast</>;
      case 'cpu':
          return <><strong>Der Vogel</strong> hat</>;
      default:
          return <><strong>Ihr beide</strong> habt</>
  }
};

const getNewRoundModalTitle = (roundNumber: number): string | ReactElement => {
  switch (roundNumber + 1) {
      case 1:
          return 'Moderne';
      case 2:
          return 'Skulptur';
      case 3:
          return 'Gemälde';
      case 4:
          return 'Glas';
      case 5:
          return 'Angewandte Kunst';
      case 6:
          return <span style={{display:'block'}}>Kunst-Kinder</span>;
      default:
          return '';
  }
};

const getNewRoundModalCopy = (roundNumber: number): string | ReactElement => {
  switch (roundNumber + 1) {
      case 1:
          return <>
          <p>Spiele ein Memory-Spiel <strong>als Rhino</strong> mit Deinem Vogelfreund.</p>
          <p>Moderne Kunst ist die Kunst aus den letzten 150 Jahren bis heute. Ich finde die bunten Bilder machen richtig gute Laune!</p>
          </>;
      case 2:
          return <>
          <p>Im Museum haben wir Figuren aller Art: Groß und klein, aus Holz und Stein, mal neu mal alt, so ist das halt.</p>
          </>;
      case 3:
          return <>
          <p>Wir haben auch eine große Sammlung von Bildern. In den Kunstwerken gibt es viele tolle Geschichten zu entdecken!</p>
          </>;
      case 4:
          return <>
          <p>Vorsicht zerbrechlich! In der Glassammlung findest Du nicht nur Gläser, sondern auch Fische, kleine Figuren und vieles mehr.</p>
          </>;
      case 5:
          return <>
          <p>Das kann alles Mögliche sein: Schränke, Lampen, Radios... Warum die Sachen im Museum sind? Sie sind besonders schön oder selten.</p>
          </>;
      case 6:
          return <>
          <p>Als es noch keine Kameras gab, mussten die Kinder früher stundenlang still sitzen, damit der Künstler sie malen konnte.</p>
          </>;
      
      default:
          return 'Spiele ein Memory-Spiel als Rhino mit Deinem Vogelfreund';
  }
};

const renderNewRoundModalContent = (roundNumber: number) => (
  <>
      <h1>Runde {roundNumber + 1} von {MAX_ROUNDS_NUMBER + 1} - {getNewRoundModalTitle(roundNumber)}</h1>
      <section>{getNewRoundModalCopy(roundNumber)}</section>
  </>
);

const isLastRound = (roundNumber: number) => roundNumber === MAX_ROUNDS_NUMBER;

const renderScoreModalContent = (userScore: number, cpuScore: number, roundNumber: number, winner?: PlayerName) => (
  <>
      <h1>Ergebnis: {userScore}:{cpuScore}</h1>
      <section>
          <p>
              Juhu! {getWinnerDisplayText(winner)} gewonnen!
              {!isLastRound(roundNumber) && <> Spiele die nächste Runde!</>}
          </p>
        </section>
  </>
);

const getSoundForScoreModal = (roundNumber: number, winner?: PlayerName) => {
    if (isLastRound(roundNumber)) {
        switch (winner) {
            case 'user':
                return require('./assets/12a.mp3');
            case 'cpu':
                return require('./assets/13a.mp3');
            default:
                return require('./assets/14a.mp3');
        }
    } else {
        switch (winner) {
            case 'user':
                return require('./assets/12.mp3');
            case 'cpu':
                return require('./assets/13.mp3');
            default:
                return require('./assets/14.mp3');
        }
    }
};

const RoundModal = ({
  shouldShowModal,
  hasRoundJustFinished,
  startRound,
  finishRound,
  roundNumber,
  userScore,
  cpuScore,
  winner,
} : {
  shouldShowModal: boolean,
  hasRoundJustFinished: boolean,
  startRound: () => void,
  finishRound: () => void,
  roundNumber: number,
  userScore: number,
  cpuScore: number,
  winner?: PlayerName,
}) => {

    useEffect( () => {
            if (hasRoundJustFinished) {
                playSoundIfNotMuted(getSoundForScoreModal(roundNumber, winner))
            } else { // new round sound:
                playSoundIfNotMuted(roundVoiceFiles[roundNumber]);
            }
        }, [roundNumber, hasRoundJustFinished]
    );

    const handleButtonClick = () => {
        stopSound();

        if (hasRoundJustFinished) {
            finishRound()
        } else {
            startRound()
        }
    };

    return (
        <Transition
            unique
            reset
            items={shouldShowModal}
            from={{opacity: 0, transform: 'translate3d(-50%, -70%, 0) scale(0.00001)'}}
            enter={{opacity: 1, transform: 'translate3d(-50%, -50%, 0) scale(1)'}}
            leave={{opacity: 0}}
            config={config.gentle}
        >
            {(shouldShowModal) =>
                shouldShowModal && (style => (
                    <Modal
                        buttonCopy={hasRoundJustFinished ? roundNumber === MAX_ROUNDS_NUMBER ? 'Weiter' : 'NÄCHSTE RUNDE' : 'START'}
                        onButtonClick={handleButtonClick}
                        style={style}
                        type={ModalType.long}
                    >
                        {
                            hasRoundJustFinished
                                ? renderScoreModalContent(userScore, cpuScore, roundNumber, winner)
                                : renderNewRoundModalContent(roundNumber)
                        }
                    </Modal>
                ))}
        </Transition>
    );
};

export default RoundModal;