export type SectionTop = {
    text: string,
    buttonLeft: string,
    buttonRight: string,
}

export default [
    {
        text: 'Unser abwechslungsreiches Ausstellungsprogramm setzt immer wieder neue Impulse, greift Themen der Zeit auf und ermöglicht neue Perspektiven. Darüber hinaus erlaubt ein breiter Kunstbegriff überraschende Perspektivwechsel.',
        buttonLeft: 'aktuell',
        buttonRight: 'ausblick',
    }
] as SectionTop[];