import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module'
import { hotjar } from 'react-hotjar';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './calendar.css';
import moment from 'moment';
import 'moment/locale/de';
import $ from "jquery";

moment.locale('de');

const tagManagerArgs = {
    gtmId: 'GTM-5RSB7VP'
};
const hotJarArgs = {
    hjid: 1179525,
    hjsv: 6
};

/*TagManager.dataLayer({
    dataLayer: {
        event: 'PageView',
        page: '/',
    },
    dataLayerName: 'PageDataLayer',
});*/
/*
TagManager.dataLayer({
    dataLayer: {
        event: 'GAEvent',
        category: 'MyCategory',
        value: 'Test'
    },
    dataLayerName: 'PageDataLayer',
});*/

console.log(TagManager);
TagManager.initialize(tagManagerArgs);



hotjar.initialize(hotJarArgs.hjid, hotJarArgs.hjsv);

ReactDOM.render(<App />, document.getElementById('root'));

$(function() {
    var waitForEl = function(selector:string, callback:any) {
        // @ts-ignore
        if (!$(selector).length) {
            setTimeout(function() {
                window.requestAnimationFrame(function(){ waitForEl(selector, callback) });
            }, 100);
        }else {
            callback();
        }
    };

    if (window.top.location.hash) {
        waitForEl(window.top.location.hash, scrollToBox)
    }

    function scrollToBox() {
        $("html, body").animate({

            // @ts-ignore
            scrollTop: $(window.top.location.hash).offset().top
        }, 1000);
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
