import React from 'react';
import cx from 'classnames';
import styles from './PictureBox.module.scss';

type PictureBoxProps = {
    artworkSrc: string,
}

const PictureBox: React.FC<PictureBoxProps> = ({
    artworkSrc,
}) => {

    return (
        <div className={styles.pictureBox}>
            <img
                className={cx(styles.artElement)}
                src={artworkSrc}
            />
        </div>
    )
};

export default PictureBox;
