import React, {useEffect, useRef, useState} from 'react';
import cx from 'classnames';
import styles from './TemporaryExhibition.module.scss';

import Footer from '../../components/Footer/Footer';
import PictureBox from './components/PictureBox/PictureBox';
import EventPostit from './components/EventPostit/EventPostit';
import ScrollTo from '../../components/ScrollTo/ScrollTo';
import NavigationTopBar from '../../components/NavigationTopBar/NavigationTopBar';

import sectionTopData from './sectionTopData';

import {IExhibition} from '../../services/schema/contentful';
import exhibition, {ExhibitionCollection} from '../../services/exhibitions';
import {getClient} from "../../services/contentfulClient";
import {INLINES} from "@contentful/rich-text-types";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {calculateImageSize} from "../../helpers/calculateImageSize";

type TemporaryExhibitionProps = {};
let Header_box: any,
    imgWidth = calculateImageSize(650),
    imgHeight = calculateImageSize(497),
    imgWidthMobile = calculateImageSize(306),
    imgHeightMobile = calculateImageSize(234);

const TemporaryExhibition: React.FC<TemporaryExhibitionProps> = ({}) => {
    const [exhibitionItemList, setExhibitionItemList] = useState([] as IExhibition[]);

    const [isLoading, setIsLoading] = useState(true);

    const promise_header_box = getClient().getEntry('1QBzQcXSuIoiTngmDv6PlS')
            .then(function (entry: any) {
                return entry.fields;
            })
    ;

    Promise.all([promise_header_box]).then(data => {
        Header_box = data[0];
        setIsLoading(false);
    })

    useEffect(() => {
        exhibition.fetch().then(parseExhibitionItemListResponse);
    }, []);

    const parseExhibitionItemListResponse = (response: ExhibitionCollection) => {
        setExhibitionItemList(response.entries);
    };

    const isMobile = () => window.innerWidth < 760;
    const isBigDesktop = () => window.innerWidth > 1679;

    const exhibitionItemListOrdered = exhibitionItemList.sort((a, b) => a.fields.order > b.fields.order ? -1 : 1)

    let currentList = exhibitionItemListOrdered.filter(function(currentElement) {
        return currentElement.fields.current;
    });

    let notCurrentList = exhibitionItemListOrdered.filter(function(currentElement) {
        return !currentElement.fields.current;
    });

    function renderExhibitionEventList(exhibitionItemList: any) {
        return exhibitionItemList.map((element: any, id: number) => {

            const elementData = element.fields;
            const imageDesktop = element.fields.image.fields.file.url + '?w=' + imgWidth + '&h=' + imgHeight;
            const imageMobile = element.fields.imageMobile.fields.file.url + '?w=' + imgWidthMobile + '&h=' + imgHeightMobile;

            return (
                <div
                    key={id}
                    id={element.fields.anchor}
                    className={cx(
                        styles.exhibitionBox,
                        {[styles.smallElement]: element.subPostitSize === 'small'}
                    )}
                >
                    <div className={styles.eventBox}>
                        <PictureBox
                            className={styles.artTopSectionMiddle}
                            artworkSrc={isMobile() ? imageMobile : imageDesktop}
                            artworkName={elementData.authorName}
                            artworkDescription={elementData.imageDescription}
                            tapeTypeNumber={elementData.tapeTypeNumber}
                        />
                        <EventPostit
                            className={styles.cartTopSectionMiddle}
                            exhibitionName={elementData.exhibitionName}
                            date={elementData.dateRange}
                            mainData={elementData.mainData}
                            kurator={elementData.kuratorName}
                            kuratorGender={elementData.kuratorGender}
                            smallCartText={elementData.subtitleText}
                            subPostitBig={elementData.subTextBig}
                        />
                    </div>
                </div>
            )
        })

    }

    return (
        <>
            <div
                className={styles.wrapper}
            >
                <NavigationTopBar type='ausstellungen'/>
                <section className={styles.sectionTop}>
                    <h1 className={styles.sectionTitle}>
                        AKTUELLE AUSTELLUNGEN
                    </h1>
                    <div className={styles.topPostit}>
                        {Header_box ? parseContentfulText(Header_box.text) : ''}
                        <div className={styles.scrollBox}>
                            <ScrollTo
                                scrollPosition={isMobile() ? 540 : 490}
                            >
                                {sectionTopData[0].buttonLeft}
                            </ScrollTo>
                            <ScrollTo scrollElementId="ausblick" >
                                {sectionTopData[0].buttonRight}
                            </ScrollTo>
                        </div>
                    </div>
                </section>
                <h1 className={styles.sectionTitle}>
                    aktuell
                </h1>
                {renderExhibitionEventList(currentList)}
                <h1 className={styles.sectionTitle} id="ausblick">
                    ausblick
                </h1>
                {renderExhibitionEventList(notCurrentList)}
            </div>
            <section className={styles.topLinkSection}>
                <ScrollTo className={styles.topLink}>NACH OBEN</ScrollTo>
            </section>
            <Footer/>
        </>
    )
};

function parseContentfulText(text: any) {
    const website_url = window.location.href;
    const options = {
        renderNode: {
            [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
                return ( <a href={node.data.target.fields.file.url} target="_blank"> {children} </a> )
            },
            [INLINES.HYPERLINK]: (node: any) => {
                return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
            }
        }
    };
    return documentToReactComponents(text, options);
}

export default TemporaryExhibition;
