import React, {useEffect, useState} from 'react';
import {BubbleData} from '../../../../components/Bubble/Bubble';
import OutroModal from '../../../../components/OutroModal/OutroModal';
import playSoundIfNotMuted, {stopSound} from '../../../../helpers/playSound';
import {State} from '../../../../interfaces/State';
import {connect} from 'react-redux';
import {selectWinningPlayer, selectPlayerGameScore} from '../../selectors';
import {PlayerName} from '../../interfaces/MemoryState';
import RhinoSpritesheet from './assets/memory_outro_rhino_spritesheet.png';
import BirdSpritesheet from './assets/memory_outro_bird_spritesheet.png';
import BalloonSpritesheet from './assets/balloons_spritesheet.png';
import memoryOutroData from './memoryOutroData';
import MemoryDecorationBox from '../components/MemoryDecorationBox/MemoryDecorationBox';
import styles from './MemoryOutro.module.scss';
import Spritesheet from 'react-responsive-spritesheet';
import {Transition} from 'react-spring/renderprops-universal';
import {config} from 'react-spring';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {getClient} from "../../../../services/contentfulClient";

const modalVoiceFile = require('./assets/18.mp3');

const getOutroSound = (winner?: PlayerName) => {
    switch (winner) {
        case 'user':
            return require('./assets/15.mp3');
        case 'cpu':
            return require('./assets/17.mp3');
        default:
            return require('./assets/16.mp3');
    }
};

let winning_text: any;

interface MemoryOutroProps extends RouteComponentProps {
    winningPlayer: PlayerName | undefined,
    userGameScore: number,
    cpuGameScore: number,
}

const MemoryOutro: React.FC<MemoryOutroProps> = ({
    winningPlayer,
    userGameScore,
    cpuGameScore,
    history,
}) => {
    const [shouldShowModal, setShouldShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    getClient().getEntry('4ImkCZQhHnA67m0ikZ72XA')
        .then(function (entry: any) {
            winning_text = entry.fields;
            setIsLoading(false);
        })

    useEffect(() => {
        playSoundIfNotMuted(getOutroSound(winningPlayer))
        return () => stopSound()
    }, []);

    const whoWon = winningPlayer === 'user'
        ? 'rhino'
        : winningPlayer === 'cpu'
            ? 'bird'
            : 'both';
    const bubbleData: BubbleData[] = [
        {
            text: <>
                <>
                    {winningPlayer === 'user' && memoryOutroData.textUser}
                    {winningPlayer === 'cpu' && memoryOutroData.textCpu}
                    {!winningPlayer && memoryOutroData.textBoth}
                </>
                <div className={styles.score}>
                    Endergebnis: {userGameScore}:{cpuGameScore}
                </div>
                <>
                    Klicke auf das Geschenk, um deinen Preis zu bekommen.
                </>
            </>,
            variant: memoryOutroData.bubbleVariant,
        },
    ];

    return (
        <>
            <MemoryDecorationBox
                BirdSpritesheet={BirdSpritesheet}
                birdWidthFrame={300}
                birdHeightFrame={485}
                memoryScene={'Outro'}
                bubbleData={bubbleData}
                onBirdClick={() => setShouldShowModal(true)}
                onFinish={() => {}}
                RhinoSpritesheet={RhinoSpritesheet}
                rhinoWidthFrame={356}
                rhinoHeightFrame={460}
                animateWhom={whoWon}
                shouldShowNavigation={false}
            />
            <Spritesheet
                image={BalloonSpritesheet}
                className={styles.balloonYellow}
                widthFrame={240}
                heightFrame={909}
                steps={3}
                fps={12}
                loop={true}
                startAt={1}
                endAt={3}
            />
            <Spritesheet
                image={BalloonSpritesheet}
                className={styles.balloonPink}
                widthFrame={240}
                heightFrame={909}
                steps={3}
                fps={12}
                loop={true}
                startAt={4}
                endAt={6}
            />
            <Spritesheet
                image={BalloonSpritesheet}
                className={styles.balloonOrange}
                widthFrame={240}
                heightFrame={909}
                steps={3}
                fps={12}
                loop={true}
                startAt={7}
                endAt={9}
            />
            <Spritesheet
                image={BalloonSpritesheet}
                className={styles.balloonRed}
                widthFrame={240}
                heightFrame={909}
                steps={3}
                fps={12}
                loop={true}
                startAt={10}
                endAt={12}
            />
            <Spritesheet
                image={BalloonSpritesheet}
                className={styles.balloonBlue}
                widthFrame={240}
                heightFrame={909}
                steps={3}
                fps={12}
                loop={true}
                startAt={13}
                endAt={15}
            />

            <Transition
                unique
                reset
                items={shouldShowModal}
                from={{opacity: 0, transform: 'translate3d(-50%, -70%, 0) scale(0.00001)'}}
                enter={{opacity: 1, transform: 'translate3d(-50%, -50%, 0) scale(1)'}}
                leave={{opacity: 0}}
                config={config.wobbly}
            >
                {(shouldShow: boolean) => shouldShow && (style => (
                    <OutroModal style={style} history={history} infoForVisitorsSlideNumber={1} voiceFile={modalVoiceFile} winning_text={winning_text} />
                ))}
            </Transition>
        </>
    );
};

const mapStateToProps = (state: State) => ({
    winningPlayer: selectWinningPlayer(state),
    userGameScore: selectPlayerGameScore('user')(state),
    cpuGameScore: selectPlayerGameScore('cpu')(state),
});

export default withRouter(connect(mapStateToProps)(MemoryOutro));
