import React, {useEffect} from 'react';
import playSoundIfNotMuted from '../../../../../../helpers/playSound';
import styles from './TaskReminder.module.scss';

type TaskReminderProps = {
    copy: string,
    subRemindTextStart: string,
    subRemindTextEnd: string,
    animal: string,
    imgUrl: string,
    audio: string,
}

const TaskReminder: React.FC<TaskReminderProps> = ({
    copy,
    subRemindTextStart,
    subRemindTextEnd,
    animal,
    imgUrl,
    audio,
}) => {
    useEffect(() => {
        if (audio) {
            playSoundIfNotMuted(audio)
        }
    }, [audio]);

    return (
        <div className={styles.taskReminder}>
            <p className={styles.copy}>
                {copy}
            </p>
            <div className={styles.bottom}>
                <p className={styles.subText}>
                    <span>{subRemindTextStart}</span>
                    <span className={styles.bold}>{animal}</span>
                    <span>{subRemindTextEnd}</span>
                </p>
                <div className={styles.imgWrapper}>
                    <img
                        src={imgUrl}
                        alt=""
                    />
                </div>
            </div>
        </div>
    )
};

export default TaskReminder;