import React, {useEffect, useState} from 'react';
import Footer from '../../components/Footer/Footer';
import styles from './InfoForVisitors.module.scss';
import Dogs from './assets/Group_dogs.png';
import Cart from './assets/Cart.png';
import NavigationTopBar from '../../components/NavigationTopBar/NavigationTopBar';
import MapBox from '../../components/MapBox/MapBox';
import PageTitle from '../../components/PageTitle/PageTitle';
import {INLINES} from "@contentful/rich-text-types";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {getClient} from "../../services/contentfulClient";
import ScrollTo from "../../components/ScrollTo/ScrollTo";

type InfoForVisitorsProps = {
    location?: any,
}

let Box_1: any,
    Box_2: any,
    Box_3: any,
    Box_4: any,
    Box_5: any,
    Box_6: any;

const InfoForVisitors: React.FC<InfoForVisitorsProps> = ({
    location,
}) => {
    const [isLoading, setIsLoading] = useState(true);

    const promise_box_1 = getContentfulBoxContent('1793xiqwNwGxjHYcNPB7EU'), //öffnungszeiten
        promise_box_2 = getContentfulBoxContent('4L2L6D7C5cBeCYcaYw7Unu'), // Tickets
        promise_box_3 = getContentfulBoxContent('4LyIFHdBcR0gV3HkF3a9LW'), // Hinweise für Roll
        promise_box_4 = getContentfulBoxContent('2GTPxlUrkQb1kc8Omj0yo6'), // Dein Weg zu uns
        promise_box_5 = getContentfulBoxContent('tTEOBiviLKCG5NzhyKQss'), // Museumpolan
        promise_box_6 = getContentfulBoxContent('5hzpNRBQnyoPFVxa8OPoMG'); // Umgebung

    Promise.all([promise_box_1, promise_box_2, promise_box_3, promise_box_4, promise_box_5, promise_box_6]).then(data => {
        Box_1 = data[0];
        Box_2 = data[1];
        Box_3 = data[2];
        Box_4 = data[3];
        Box_5 = data[4];
        Box_6 = data[5];
        setIsLoading(false);
    })

    return (
        <>
            <div className={styles.wrapper}>
                {isLoading ? '' :   <div>
                    <NavigationTopBar type='besucherinfo'/>
                    <PageTitle>
                        Plane deinen Besuch
                    </PageTitle>
                    <div className={styles.postitBoxTop}>
                        <div className={styles.baseDataTopLeft}>
                            <h2>
                                {Box_1.title}
                            </h2>
                            {parseContentfulText(Box_1.text)}
                        </div>
                        <div className={styles.baseDataTopRight}>
                            <h2>{Box_2.title}</h2>
                            {parseContentfulText(Box_2.text)}
                            <p className={styles.registrationText}>
                                <a href="https://freunde.kunstpalast.de/de/mitglied-werden/alle-mitgliedschaften/familienmitgliedschaft" target="_blank" rel="noopener noreferrer">Werden Sie mit Ihrer Familie Mitglied bei den Freunden des Kunstpalastes</a>
                            </p>
                            <div className={styles.linkBox}>
                                <a
                                    className={styles.ticketSign}
                                    href={'https://shop.kunstpalast.de//'}
                                >
                                    TICKETS KAUFEN
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.imageBoxTop}>
                        <img
                            className={styles.linkImage}
                            src={Dogs}
                            alt=''
                        />
                        <div className={styles.thinkBubble}>
                <span>
                    Brauchst du noch mehr Tipps für deinen Besuch?
                </span>
                            <a
                                href={`${process.env.PUBLIC_URL}/besucherinfo/faq`}
                                className={styles.faqSign}
                            >
                                FAQ
                            </a>
                        </div>
                    </div>
                    <div className={styles.postitBoxBottom}>
                        <div className={styles.baseDataBottomLeft}>
                            <h2>
                                {Box_3.title}
                            </h2>
                            {parseContentfulText(Box_3.text)}
                        </div>
                        <div className={styles.baseDataBottomRight}>
                            <h2>
                                {Box_4.title}
                            </h2>
                            {parseContentfulText(Box_4.text)}
                        </div>
                    </div>
                    <img
                        className={styles.bottomImage}
                        src={Cart}
                        alt=''
                    />
                    <MapBox titleArea={Box_6.title} imageArea={Box_6.image.fields.file.url} titleMuseum={Box_5.title} imageMuseum={Box_5.image.fields.file.url} />
                </div>}
            </div>
            <section className={styles.topLinkSection}>
                <ScrollTo className={styles.topLink}>NACH OBEN</ScrollTo>
            </section>
            <Footer/>
        </>
    )
};


function parseContentfulText(text: any) {
    const website_url = window.location.href;
    const options = {
        renderNode: {
            [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
                return ( <a href={node.data.target.fields.file.url} target="_blank"> {children} </a> )
            },
            [INLINES.HYPERLINK]: (node: any) => {
                return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
            }
        }
    };
    return documentToReactComponents(text, options);
}

function getContentfulBoxContent(contentful_id: string) {
    return getClient().getEntry(contentful_id)
        .then(function (entry: any) {
            return entry.fields;
        });
}

export default InfoForVisitors;
