import React, {useEffect, useState} from 'react';
import Helmet from 'react-helmet';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import NavigationTopBar from '../../components/NavigationTopBar/NavigationTopBar';
import Footer from '../../components/Footer/Footer';
import renderImages, {ImgData} from '../../helpers/renderImages';
import VideoModalElement from './components/VideoModalElement/VideoModalElement';

import styles from './Kunstklick.module.scss';
import Cloud2 from '../../assets/cloud_2.png';
import Cloud3 from '../../assets/cloud_3.png';
import Cloud5 from '../../assets/cloud_5.png';
import Flower4 from '../../assets/flower_4.png';
import Flower9 from '../../assets/flower_9.png';
import Flower13 from '../../assets/flower_13.png';
import Flower14 from '../../assets/flower_14.png';
import Flower16 from './assets/desktop/flower_16.png';
import imgSrcPlant from './assets/video_2020-06-08-2.jpg';
import imgSrcHorse from './assets/video_2020-04-23.jpg';
import imgSrcMagpie from './assets/video_2020-05-14.jpg';
import imgSrcDog from './assets/video_2020-05-26.jpg';
import imgSrcWoman from './assets/video_2020-06-02.jpg';
import imgSrcStatue from './assets/video_2020-05-18.jpg';
import imgSrcRhino from './assets/video_2020-06-29.jpg';
import kunstklick, {KunstKlickCollection} from '../../services/kunstKlick';
import {IKunstklick} from "../../services/schema/contentful";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {INLINES} from "@contentful/rich-text-types";

const clouds: ImgData[] = [
    {src: Cloud2, className: styles.cloud2},
    {src: Cloud3, className: styles.cloud3},
    {src: Cloud5, className: styles.cloud5},
];

const flowers: ImgData[] = [
    {src: Flower4, className: styles.flower4},
    {src: Flower9, className: styles.flower9},
    {src: Flower13, className: styles.flower13},
    {src: Flower14, className: styles.flower14},
    {src: Flower16, className: styles.flower16},
];

const Kunstklick: React.FC<RouteComponentProps> = ({ history }) => {
    const [kunstKlickList, setKunstKlickList] = useState([] as IKunstklick[]);
    useEffect(() => {
        kunstklick.fetch().then(parseSlideItemListResponse);
    }, []);

    const parseSlideItemListResponse = (response: KunstKlickCollection) => {
        setKunstKlickList(response.entries);
    };
    const kunstKlickListOrdered = kunstKlickList.sort((a, b) => a.fields.order > b.fields.order ? -1 : 1);

    function renderList(offersItemListOrdered: any) {
        return offersItemListOrdered.map((element: any, id: number) => {
            return (
                <div className={styles.movieBox} key={id}>
                    <VideoModalElement
                        channel='youtube'
                        videoId={element.fields.youtubeVideoId}
                        imgSrc={element.fields.image.fields.file.url}
                    />
                    <div className={styles.videoTeaser}>
                        <h3>{element.fields.title}</h3>
                        {element.fields.text ? parseText(element.fields.text) : ''}
                    </div>
                </div>
            );
        })
    }

    return (
        <>
            <div className={styles.noHeader}>
                <Helmet>
                    <body className={styles.homeBody} />
                </Helmet>
                <NavigationTopBar className={styles.homeNavigation} />
                <div className={styles.logoCover} />
                {clouds.map(renderImages)}
                <h1 className="PageTitle_title__110W0">Mediathek</h1>
                {renderList(kunstKlickListOrdered)}
            </div>
            <div className={styles.footerWrapper}>
                <Footer />
            </div>
        </>
    );
};

function parseText(string: any) {
    return documentToReactComponents(string, {
        renderNode: {
            [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
                return ( <a href={node.data.target.fields.file.url} target="_blank"> {children} </a> )
            },
            [INLINES.HYPERLINK]: (node: any) => {
                return <a href={node.data.uri} target={`${node.data.uri.startsWith(window.location.href) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(window.location.href) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
            }

        },
        renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text])
    })
}

export default withRouter(Kunstklick);
