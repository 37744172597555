import React, {Component, Fragment, useState} from 'react';
import styles from './GameSelection.module.scss';
import Cloud3 from './../../assets/cloud_3.png';
import Cloud4 from './../../assets/cloud_4.png';
import Cloud5 from './../../assets/cloud_5.png';
import GameBox from './components/GameBox/GameBox';
import LinkPicture from './components/LinkPicture/LinkPicture';
import gameSelectionData, {GameSelectionData} from './gameSelectionData';
import Logo from '../../components/Logo/Logo';
import NavigationTopBar from "../../components/NavigationTopBar/NavigationTopBar";
import {getClient} from "../../services/contentfulClient";
import {INLINES} from "@contentful/rich-text-types";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";

type GameSelectionProps = {}

interface GameSelectionState {
    refreshAnimation: boolean,
    heading: string,
    box_1: any,
    box_2: any,
    box_3: any,
    box_4: any,
    box_5: any,
    box_6: any,
    box_7: any,
    box_8: any,
}
let promise_heading: string,
    promise_box_1: any,
    promise_box_2: any,
    promise_box_3: any,
    promise_box_4: any,
    promise_box_5: any,
    promise_box_6: any,
    promise_box_7: any,
    promise_box_8: any;

class GameSelection extends Component<GameSelectionProps, GameSelectionState> {
    prevScrollpos: number;

    constructor(props: Readonly<GameSelectionProps>) {
        super(props);
        this.state = {
            refreshAnimation: false,
            heading: '',
            box_1: '',
            box_2: '',
            box_3: '',
            box_4: '',
            box_5: '',
            box_6: '',
            box_7: '',
            box_8: '',
        };

        this.prevScrollpos = window.pageYOffset;
    }

    componentDidMount() {
        this.getHeading();
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        this.getHeading();
        window.removeEventListener("scroll", this.handleScroll);
    }

    getHeading = () => {
        let headingString: string = '';

        promise_heading = getClient().getEntry('1pl9t5vv2nHMji1h282KeP')
            .then(function (entry: any) {
                return entry.fields.heading;
            });

        promise_box_1 = getClient().getEntry('5sVWlwbfyplYETsyAmhkVm')
            .then(function (entry: any) {
                return entry.fields;
            });

        promise_box_2 = getClient().getEntry('5aX9NWR0Kld2lczIS7nz5h')
            .then(function (entry: any) {
                return entry.fields;
            });

        promise_box_3 = getClient().getEntry('4CU1KAdtMA3Z5gtC5hdMP2')
            .then(function (entry: any) {
                return entry.fields;
            });

        promise_box_4 = getClient().getEntry('6HeUxlmGVhSiF7Dz5vdAA')
            .then(function (entry: any) {
                return entry.fields;
            });
        promise_box_5 = getClient().getEntry('6drIbgWA0j2L46k3Y8ginZ')
            .then(function (entry: any) {
                return entry.fields;
            });
        promise_box_6 = getClient().getEntry('2UTYCpNeLppf65ChRkVuEP')
            .then(function (entry: any) {
                return entry.fields;
            });
        promise_box_7 = getClient().getEntry('3QtrOAJfRvk1TXjDgtnQek')
            .then(function (entry: any) {
                return entry.fields;
            });
        promise_box_8 = getClient().getEntry('3DoqBUUMv28FSnqJ6z3EMO')
            .then(function (entry: any) {
                return entry.fields;
            });

        Promise.all([promise_heading, promise_box_1, promise_box_2, promise_box_3, promise_box_4, promise_box_5, promise_box_6, promise_box_7, promise_box_8]).then(data => {
            this.setState({
                heading: data[0],
                box_1: data[1],
                box_2: data[2],
                box_3: data[3],
                box_4: data[4],
                box_5: data[5],
                box_6: data[6],
                box_7: data[7],
                box_8: data[8],
            });
        })
    };

    handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        const refreshAnimation = this.prevScrollpos > 350 && this.prevScrollpos < 380;
        this.prevScrollpos = currentScrollPos;

        this.setState({
            refreshAnimation
        });
    };

    render() {
        const isTouchDeviceOrMobile = window.matchMedia("(max-width: 768px), (pointer: coarse)").matches;
        const {refreshAnimation, heading, box_1, box_2, box_3, box_4, box_5, box_6, box_7, box_8} = this.state;
        let box_content:any = {
            '5sVWlwbfyplYETsyAmhkVm': {
                'text': parseText(box_1.text),
                'title': box_1.title
            },
            '5aX9NWR0Kld2lczIS7nz5h':  {
                'text': parseText(box_2.text),
                'title': box_2.title
            },
            '4CU1KAdtMA3Z5gtC5hdMP2':  {
                'text': parseText(box_3.text),
                'title': box_3.title
            },
            '6HeUxlmGVhSiF7Dz5vdAA':  {
                'text': parseText(box_4.text),
                'title': box_4.title
            },
            '6drIbgWA0j2L46k3Y8ginZ':  {
                'text': parseText(box_5.text),
                'title': box_5.title
            },
            '2UTYCpNeLppf65ChRkVuEP':  {
                'text': parseText(box_6.text),
                'title': box_6.title
            },
            '3QtrOAJfRvk1TXjDgtnQek':  {
                'text': parseText(box_7.text),
                'title': box_7.title
            },
            '3DoqBUUMv28FSnqJ6z3EMO':  {
                'text': parseText(box_8.text),
                'title': box_8.title
            },
        }

        const renderGameBox = (gameBoxData: GameSelectionData, index: number) => (
            <Fragment key={index}>
                { index === 6 && (
                    <div className={styles.informationWrapper}>
                        <p className={styles.informationText}>Computer&shy;spiele</p>
                    </div>
                )}
                <GameBox
                    gameId={gameSelectionData[index].gameId}
                    isBlocked={gameSelectionData[index].isBlockedOnTouchDevice && isTouchDeviceOrMobile}
                    startingFrame={gameSelectionData[index].startingFrame}
                    frameSteps={gameSelectionData[index].frameSteps}
                    whiteText={gameSelectionData[index].whiteText}
                    link={{
                        pathname: gameSelectionData[index].link,
                        state: {fromApp: true},
                    }}
                    spritesheetTimeOut={gameSelectionData[2].spriteSheetTimeout}
                    ageField={gameSelectionData[index].age}
                    refreshAnimation={gameSelectionData[index].refreshAnimationOnScroll && refreshAnimation}
                    text={box_content[gameSelectionData[index].contentfulId].text}
                    title={box_content[gameSelectionData[index].contentfulId].title}
                />
            </Fragment>
        );

        function parseText(string: any) {
            return documentToReactComponents(string, {
                renderNode: {
                    [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
                        return ( <a href={node.data.target.fields.file.url} target="_blank"> {children} </a> )
                    },
                    [INLINES.HYPERLINK]: (node: any) => {
                        return <a href={node.data.uri} target={`${node.data.uri.startsWith(window.location.href) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(window.location.href) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
                    }
                }
            })
        }


        return (
            <div className={styles.wrapper}>
                <NavigationTopBar
                    type='spiele'
                    className={styles.naviWhiteBackground}
                />

                <img
                    className={styles.cloudSmall}
                    src={Cloud3}
                    alt=""
                />
                <img
                    className={styles.cloudMedium}
                    src={Cloud4}
                    alt=""
                />
                <img
                    className={styles.cloudLarge}
                    src={Cloud5}
                    alt=""
                />
                <LinkPicture text="Homepage" href="/" className={styles.backLink}/>
                <div className={styles.contentWrapper}>
                    <div className={styles.headingBox}>
                        <h1 className={styles.headingText}>
                            {heading}
                        </h1>
                        <p className={styles.headingSubtext}>
                        </p>
                    </div>
                    {gameSelectionData.map(renderGameBox)}
                </div>
            </div>
        )
    }
};

export default GameSelection;
