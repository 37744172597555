import React from 'react';
import {QuestionData} from "./interfaces/QuizState";

const questionsAudioFiles = [
    require('./assets/voice/32.mp3'),
    require('./assets/voice/33.mp3'),
    require('./assets/voice/34.mp3'),
    require('./assets/voice/35.mp3'),
    require('./assets/voice/36.mp3'),
    require('./assets/voice/37.mp3'),
    require('./assets/voice/38.mp3'),
    require('./assets/voice/39.mp3'),
    require('./assets/voice/40.mp3'),
    require('./assets/voice/41.mp3'),
    require('./assets/voice/42.mp3'),
    require('./assets/voice/43.mp3'),
    require('./assets/voice/44.mp3'),
];

const funFactsAudioFiles = [
    require('./assets/voice/45.mp3'),
    require('./assets/voice/46.mp3'),
    require('./assets/voice/47.mp3'),
    require('./assets/voice/48.mp3'),
    require('./assets/voice/49.mp3'),
    require('./assets/voice/50.mp3'),
    require('./assets/voice/51.mp3'),
    require('./assets/voice/52.mp3'),
    require('./assets/voice/53.mp3'),
    require('./assets/voice/54.mp3'),
    require('./assets/voice/55.mp3'),
    require('./assets/voice/56.mp3'),
    require('./assets/voice/57.mp3'),
];

export default [
    {
        answers: [
            'Kartoffel',
            'Stallmist',
            'Lego',
        ],
        byline: 'Dieter Roth, Karnickelkötelkarnickel, 1969-70 ',
        correctAnswer: 1,
        funFact: <>Wusstest Du, dass Dieter Roth Kunst aus allerlei natürlichen Materialien gemacht hat? Nicht nur aus Mist, sondern auch aus Wurst oder Schokolade. Das nennt man Eat-Art, also Ess-Kunst. Essen würde ich die Objekte aber nicht, manche sind schon über 50 Jahre alt und schmecken bestimmt nicht mehr…</>,
        imgUrl: `${process.env.PUBLIC_URL}/assets/quiz/01.jpg`,
        imgOrientation: 'horizontal',
        question: <>Woraus ist die Skulptur gemacht?</>,
        userAnswer: undefined,
        questionVoiceFile: questionsAudioFiles[0],
        funFactVoiceFile: funFactsAudioFiles[0],
    },
    {
        answers: [
            'wie ein Haus',
            'wie eine Playmobil Figur',
            'wie ein Marienkäfer',
        ],
        byline: 'Masakazu, Netsuke, frühes 19. Jh.',
        correctAnswer: 1,
        funFact: <>Diese kleinen Figuren wurden früher in Japan als Gegengewicht zur Befestigung von Taschen am Gürtel benutzt. Die Netsuke, so nennt man diese Figuren, wurden aus kostbaren Materialien gemacht. Sie zeigen Fantasiewesen, Geister, Götter oder Menschen – hier einen Holländer mit Trompete.</>,
        imgUrl: `${process.env.PUBLIC_URL}/assets/quiz/02.jpg`,
        imgOrientation: 'vertical',
        question: <>Wie groẞ ist die Figur?</>,
        userAnswer: undefined,
        questionVoiceFile: questionsAudioFiles[1],
        funFactVoiceFile: funFactsAudioFiles[1],
    },
    {
        answers: [
            'Über 3.000 Jahre alt',
            '9 Jahre alt',
            'Ungefähr 800 Jahre alt',
        ],
        byline: 'Ohrschmuck, Ägypten, Mitte 14./ Mitte 13. Jh. v. Chr.',
        correctAnswer: 0,
        funFact: <>Dieser ägyptische Ohrschmuck ist das älteste Kunstwerk aus der Glassammlung. Mehr als 3.000 Jahre sind diese kleinen Objekte schon alt. So lange würden die Gläser aus meiner Küche bestimmt nicht halten - dafür bin ich viel zu tollpatschig!</>,
        imgUrl: `${process.env.PUBLIC_URL}/assets/quiz/03.jpg`,
        imgOrientation: 'horizontal',
        question: <>Wie alt ist das Kunstwerk?</>,
        userAnswer: undefined,
        questionVoiceFile: questionsAudioFiles[2],
        funFactVoiceFile: funFactsAudioFiles[2],
    },
    {
        answers: [
            'Feuer',
            'Sonnenblumen',
            'Kohle',
        ],
        byline: 'Otto Piene, Gelb, treuer Freund, 1989/90',
        correctAnswer: 0,
        funFact: <>Otto Piene gehörte zu einer Künstlergruppe namens ZERO (Null). Sie haben viel mit Licht und auch mit Feuer experimentiert. Im Kunstpalast gibt es einen eigenen ZERO-Raum. Da kannst Du ein tolles Licht- und Schattenspiel entdecken und noch mehr Kunst von Otto Piene.</>,
        imgUrl: `${process.env.PUBLIC_URL}/assets/quiz/04.jpg`,
        imgOrientation: 'horizontal',
        question: <>Womit hat der Künstler das Bild gemacht?</>,
        userAnswer: undefined,
        questionVoiceFile: questionsAudioFiles[3],
        funFactVoiceFile: funFactsAudioFiles[3],
    },
    {
        answers: [
            'Stein',
            'Wachs ',
            'Zucker',
        ],
        byline: 'César (César Baldaccini), Pouce (Daumen), 1971',
        correctAnswer: 2,
        funFact: <>Der Künstler César hat ganz viele Daumen-Skulpturen gemacht. Allein diese gibt es 25 Mal. In der Kunst nennt man das Multiples. Seine gröẞte Daumen-Skulptur ist so hoch wie ein Haus. Diese hier ist ungefähr 40 cm groẞ und aus Zucker - also sozusagen ein Daumen-Lutscher (hihi).</>,
        imgUrl: `${process.env.PUBLIC_URL}/assets/quiz/05.jpg`,
        imgOrientation: 'vertical',
        question: <>Woraus ist die Skulptur gemacht?</>,
        userAnswer: undefined,
        questionVoiceFile: questionsAudioFiles[4],
        funFactVoiceFile: funFactsAudioFiles[4],
    },
    {
        answers: [
            'wie deine Hand',
            'wie Du',
            '3 mal so groẞ wie Du',
        ],
        byline: 'Peter Paul Rubens, Maria Himmelfahrt, 1616-18',
        correctAnswer: 2,
        funFact: <>Wusstest Du, dass das Bild von Peter Paul Rubens mit seinen über vier Metern zu groẞ für jede Tür des Museums ist? Es wurde extra ein Schlitz im Boden gebaut um das Bild  in den Ausstellungsraum zu ziehen. Das hätte ich gerne gesehen!</>,
        imgUrl: `${process.env.PUBLIC_URL}/assets/quiz/06.jpg`,
        imgOrientation: 'vertical',
        question: <>Wie groẞ ist das Bild?</>,
        userAnswer: undefined,
        questionVoiceFile: questionsAudioFiles[5],
        funFactVoiceFile: funFactsAudioFiles[5],
    },
    {
        answers: [
            'Japan',
            'USA',
            'Deutschland',
        ],
        byline: 'Utagawa Kunisada , Die Silbe fu: Ichikawa Yonegoro in der Rolle des Munefusa, 1864',
        correctAnswer: 0,
        funFact: <>Der Mann auf dem Bild ist ein bekannter japanischer Schauspieler aus dem 19. Jahrhundert. Als Erinnerung an seine Auftritte konnten die Fans diese Kunstwerke kaufen.</>,
        imgUrl: `${process.env.PUBLIC_URL}/assets/quiz/07.jpg`,
        imgOrientation: 'vertical',
        question: <>Woher kommt das Kunstwerk?</>,
        userAnswer: undefined,
        questionVoiceFile: questionsAudioFiles[6],
        funFactVoiceFile: funFactsAudioFiles[6],
    },
    {
        answers: [
            'Pinsel',
            'Schwämme',
            'Fische',
        ],
        byline: 'Yves Klein, Schwammrelief, 1960',
        correctAnswer: 1,
        funFact: <>Blau war die Lieblingsfarbe von Yves Klein! Dieses Bild heiẞt „Schwammrelief“. Relief bedeutet: Da wölbt sich etwas aus der Leinwand. Und mit den Schwämmen hat der Künstler nicht nur gemalt, sondern sie auch direkt mit auf das Bild geklebt.</>,
        imgUrl: `${process.env.PUBLIC_URL}/assets/quiz/08.jpg`,
        imgOrientation: 'vertical',
        question: <>Womit hat der Künstler das Bild gemacht?</>,
        userAnswer: undefined,
        questionVoiceFile: questionsAudioFiles[7],
        funFactVoiceFile: funFactsAudioFiles[7],
    },
    {
        answers: [
            'Käse',
            'Farbe',
            'Mülltüte',
        ],
        byline: 'Julia Gruner, Regenhaut, 2015',
        correctAnswer: 1,
        funFact: <>Mit Farbe kann man nicht nur malen… Die Künstlerin Julia Gruner hat diesen Regenmantel aus vielen Schichten dicker gelber Farbe gemacht. Einen Trinkbecher und Fuẞmatten hat sie auch aus Farbe hergestellt. Verrückt, oder?</>,
        imgUrl: `${process.env.PUBLIC_URL}/assets/quiz/09.jpg`,
        imgOrientation: 'vertical',
        question: <>Woraus ist die Skulptur gemacht?</>,
        userAnswer: undefined,
        questionVoiceFile: questionsAudioFiles[8],
        funFactVoiceFile: funFactsAudioFiles[8],
    },
    {
        answers: [
            'wie deine Oma',
            'Ganz neu (2018)',
            'wie ein Dinosaurier',
        ],
        byline: 'Hans Op de Beeck, Sleeping Girl, 2018',
        correctAnswer: 1,
        funFact: <>Der Künstler Hans Op de Beeck einen ganzen Raum geschaffen hat, wo alles so grau ist wie das Mädchen und die Couch? Wenn man so einen Raum betritt, kommt man sich selber ganz bunt vor. Naja, auẞer mir vielleicht. Ich könnte mich mit meiner grauen Haut super tarnen.</>,
        imgUrl: `${process.env.PUBLIC_URL}/assets/quiz/10.jpg`,
        imgOrientation: 'horizontal',
        question: <>Wie alt ist das Kunstwerk?</>,
        userAnswer: undefined,
        questionVoiceFile: questionsAudioFiles[9],
        funFactVoiceFile: funFactsAudioFiles[9],
    },
    {
        answers: [
            'Schaufel',
            'Zahnpasta',
            'Messer',
        ],
        byline: 'Lucio Fontana, Concetto spaziale, 1959',
        correctAnswer: 2,
        funFact: <>Lucio Fontana wurde durch seine sogenannten Schnittbilder weltbekannt! Er hat die Bilder mit einem Messer entweder zerschnitten oder durchstochen. Dabei entstanden Schlitze oder Löcher in der Leinwand.</>,
        imgUrl: `${process.env.PUBLIC_URL}/assets/quiz/11.jpg`,
        imgOrientation: 'horizontal',
        question: <>Womit hat der Künstler das Bild gemacht?</>,
        userAnswer: undefined,
        questionVoiceFile: questionsAudioFiles[10],
        funFactVoiceFile: funFactsAudioFiles[10],
    },
    {
        answers: [
            'Istanbul',
            'Paris',
            'Alaska',
        ],
        byline: 'Adolph von Menzel, Pariser Wochentag, 1869',
        correctAnswer: 1,
        funFact: <>Wusstest Du, dass Paris Künstler aus aller Welt schon immer fasziniert hat? Dort ist nämlich immer was los. Man kann übrigens auch an der Schrift erkennen, dass es eine Straẞe in Paris und nicht in Düsseldorf oder Amsterdam ist. Rechts über dem Laden steht „maison“, das ist französisch und bedeutet „Haus“.</>,
        imgUrl: `${process.env.PUBLIC_URL}/assets/quiz/12.jpg`,
        imgOrientation: 'horizontal',
        question: <>Woher kommt das Kunstwerk?</>,
        userAnswer: undefined,
        questionVoiceFile: questionsAudioFiles[11],
        funFactVoiceFile: funFactsAudioFiles[11],
    },
    {
        answers: [
            'Brotteig',
            'Badeschaum',
            'Spaghetti',
        ],
        byline: 'Daniel Spoerri, Ohne Titel, 1970/71',
        correctAnswer: 0,
        funFact: <>Daniel Spoerri hatte mal ein Restaurant in Düsseldorf. und er ist auch ein Eat-Art Künstler. Im Restaurant hat er dann selber gekocht, denn für ihn war das Kochen auch eine Kunst. Dort hat er auch Kunstwerke aus Essen gezeigt, wie seine Objekte aus Brotteig.</>,
        imgUrl: `${process.env.PUBLIC_URL}/assets/quiz/13.jpg`,
        imgOrientation: 'horizontal',
        question: <>Woraus ist die Skulptur gemacht?</>,
        userAnswer: undefined,
        questionVoiceFile: questionsAudioFiles[12],
        funFactVoiceFile: funFactsAudioFiles[12],
    },

] as QuestionData[];
