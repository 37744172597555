import {BubbleData} from '../../../../components/Bubble/Bubble';
const Audio1 = require('./assets/3.mp3');
const Audio2 = require('./assets/4.mp3');
const Audio3 = require('./assets/5.mp3');

export default [
    {
        text: 'Wenn nicht so viel los ist im Kunstpalast, rollen mein Kumpel und ich die Decke aus und machen ein Picknick.',
        variant: 'bubble1',
        voiceFile: Audio1,
    },
    {
        text: 'Dann spielen wir auch gerne Memory mit Bildern aus dem Museum…',
        variant: 'bubble2',
        voiceFile: Audio2,
    },
    {
        text: 'Dabei lässt sich immer was Neues entdecken! Machst Du mit?',
        variant: 'bubble3',
        voiceFile: Audio3,
    },
] as BubbleData[];