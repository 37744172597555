import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import styles from './NeleWantsToKnow.module.scss';

import Footer from '../../components/Footer/Footer';
import ScrollTo from '../../components/ScrollTo/ScrollTo';
import NavigationTopBar from '../../components/NavigationTopBar/NavigationTopBar';

import {ITip} from '../../services/schema/contentful';
import {TipCollection} from '../../services/tips';
import {getClient} from "../../services/contentfulClient";
import {INLINES} from "@contentful/rich-text-types";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {calculateImageSize} from "../../helpers/calculateImageSize";
import neleWantsToKnow from "../../services/neleWantsToKnow";

type NeleWantsToKnowProps = {isDIY: boolean, isTop5: boolean};
let Header_box: any,
    Heading_site: string,
    imgWidth = calculateImageSize(376),
    imgHeight = calculateImageSize(250);

const NeleWantsToKnow: React.FC<NeleWantsToKnowProps> = (props) => {
    const [tipItemList, settipItemList] = useState([] as ITip[]);

    useEffect(() => {
        neleWantsToKnow.fetch().then(parsetipItemListResponse);
    }, []);

    const parsetipItemListResponse = (response: TipCollection) => {
        settipItemList(response.entries);
    };


    const [isLoading, setIsLoading] = useState(true);

    const promise_header_box = getClient().getEntry('409fqv4S6QlxHLJCqu5Xgb')
        .then(function (entry: any) {
            return entry.fields;
        }),
        promise_heading_site = getHeading('7LIoESfDFPhjmUVK4cp7Uu');

    Promise.all([promise_header_box, promise_heading_site]).then(data => {
        Header_box = data[0];
        Heading_site = data[1];

        setIsLoading(false);
    })

    function renderTipList(ItemListOrdered: any) {
        return ItemListOrdered.map((element: any, id: number) => {

            const elementData = element.fields;
            const imageDesktop = (element.fields.image) ? element.fields.image.fields.file.url + '?w=' + imgWidth + '&h=' + imgHeight + '&fit=pad' : '';

            return (
                <div
                    key={id}
                    id={element.fields.anchor}
                    className={cx(
                        styles.exhibitionBox,
                        {[styles.smallElement]: element.subPostitSize === 'small'}
                    )}
                >
                    <div className={styles.movieBoxWrapper}>
                        <div className={styles.movieBox}>


                            <div className={styles.wrapperVideo}>
                                <div className={styles.frame}>
                                    <img className={styles.videoContainer} src={imageDesktop}
                                         alt="" />
                                    </div>

                            </div>
                            <div className={styles.videoTeaser}>
                                <h3>{elementData.tipName}</h3>
                                {parseContentfulText(elementData.main)}
                            </div>
                        </div>
                    </div>
                </div>
            )
        })

    }

    // TODO: ugly temporary solution with scroll, need to be more flexible if content will change
    return (
        <>
            <div
                className={styles.wrapper}
            >
                <NavigationTopBar />
                <section className={styles.sectionTop}>
                    <h1 className={styles.sectionTitle}>
                        {Heading_site}
                    </h1>

                </section>
                <section className={styles.introText}>
                    <h2 className={styles.subTitle}>{Header_box ? Header_box.title : ''}</h2>
                    {Header_box ? parseContentfulText(Header_box.text) : ''}
                </section>
                {renderTipList(tipItemList)}
            </div>
            <section className={styles.topLinkSection}>
                <ScrollTo className={styles.topLink}>NACH OBEN</ScrollTo>
            </section>
            <Footer/>
        </>
    )
};

function parseContentfulText(text: any) {
    const website_url = window.location.href;
    const options = {
        renderNode: {
            [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
                return ( <a href={node.data.target.fields.file.url} target="_blank"> {children} </a> )
            },
            [INLINES.HYPERLINK]: (node: any) => {
                return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
            }
        }
    };
    return documentToReactComponents(text, options);
}

function getHeading(contentful_id: string) {
    return getClient().getEntry(contentful_id)
        .then(function (entry: any) {
            return entry.fields.heading;
        });
}

export default NeleWantsToKnow;
