import React, {CSSProperties, useEffect} from 'react';
import Spritesheet from 'react-responsive-spritesheet';
import {RouteComponentProps, withRouter} from 'react-router';
import {AnyAction, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Transition, animated} from 'react-spring/renderprops';
import {
    decrementCurrentIntroIndex,
    incrementCurrentIntroIndex,
    setApplicationCounterTimer
} from '../../../../actions/common';
import Bubble, {BubbleVariant} from '../../../../components/Bubble/Bubble';
import gameSelectionData, {Games} from '../../../../containers/GameSelection/gameSelectionData';
import playSoundIfNotMuted, {stopSound} from '../../../../helpers/playSound';
import {State} from '../../../../interfaces/State';
import {actionTypes} from '../../actions';
import {selectCurrentIntroIndex} from '../../selectors';
import introData from './introData';
import Navigation from '../../../../components/Navigation/Navigation';
import handleNext from '../../../../helpers/handleNext';

import styles from './QuizIntro.module.scss';
import RhinoTalkingSpritesheet from './assets/rhino-talking.png';
import CuratorDesk from './assets/curator_desk.png';

interface QuizIntroProps extends RouteComponentProps {
    currentIntroIndex: number,
    decrementIndex: () => void,
    incrementIndex: () => void,
    onFinish: () => void;
    style?: CSSProperties,
}

const QuizIntro: React.FC<QuizIntroProps> = ({
    currentIntroIndex,
    decrementIndex,
    incrementIndex,
    onFinish,
    style,
    history,
    location,
}) => {
    useEffect(() => { // TODO it's repeated and could be refactored into common function
        // play the first sound in intro if the user comes from the app and entering url/external link
        // (so when the sound is not blocked by the browser)
        if (location.state && location.state.fromApp &&
            currentIntroIndex === 0 && introData[0].voiceFile) {
            playSoundIfNotMuted(introData[0].voiceFile);

            // clear the state so it's not preserved after page reload
            history.replace(gameSelectionData[Games.quiz].link);
        }

        setApplicationCounterTimer();
        return () => stopSound()
    }, []);

    return (
        <div style={style} className={styles.quizIntro}>
            <img src={CuratorDesk} alt="" className={styles.desk}/>
            <Spritesheet
                image={RhinoTalkingSpritesheet}
                className={styles.spritesheet}
                widthFrame={414}
                heightFrame={414}
                steps={9}
                fps={12}
                loop={true}
            />
            <Transition
                unique
                reset
                items={currentIntroIndex}
                from={{opacity: 0}}
                enter={{opacity: 1}}
                leave={{opacity: 0}}
            >
                {index => style => {
                    return (
                        <>
                            {
                                !introData[index].imgUrl &&
                                <Bubble
                                    className={styles.bubble}
                                    variant={introData[index].bubbleVariant as BubbleVariant}
                                    mirroredOnDesktop
                                    onClick={() => handleNext(
                                        currentIntroIndex,
                                        incrementIndex,
                                        introData,
                                        onFinish,
                                    )}
                                    style={style}
                                    shouldShowSoundButton={(!location.state || (location.state && !location.state.fromApp)) && currentIntroIndex === 0}
                                    voiceFile={introData[index].voiceFile}
                                >
                                    {introData[index].text}
                                </Bubble>
                            }
                            {
                                introData[index].imgUrl &&
                                <animated.div
                                    className={styles.frame}
                                    style={style}
                                    onClick={() => handleNext(
                                        currentIntroIndex,
                                        incrementIndex,
                                        introData,
                                        onFinish,
                                    )}
                                >
                                    <div className={styles.imageFrame}>
                                        <img className={styles.imageSmall} src={introData[index].imgUrl} alt=""/>
                                        <img className={styles.imageBig} src={introData[index].imgUrlDesktop} alt=""/>
                                    </div>
                                    {introData[index].text}
                                </animated.div>
                            }
                        </>
                    )
                }}
            </Transition>
            <Navigation
                currentIndex={currentIntroIndex}
                decrementIndex={decrementIndex}
                mockData={introData}
                onFinish={onFinish}
                incrementIndex={incrementIndex}
            />
        </div>
    );
};

const mapStateToProps = (state: State) => ({
    currentIntroIndex: selectCurrentIntroIndex(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    decrementIndex: () => dispatch(decrementCurrentIntroIndex(actionTypes, selectCurrentIntroIndex) as unknown as AnyAction),
    incrementIndex: () => dispatch(incrementCurrentIntroIndex(actionTypes, selectCurrentIntroIndex) as unknown as AnyAction),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuizIntro));