import React from 'react';
import cx from 'classnames';
import styles from './HelperSign.module.scss';

type DetectiveHelperProps = {
    helperVisibility: boolean,
    hideHelp: () => void,
    taskId: number,
}

const HelperSign: React.FC<DetectiveHelperProps> = ({
    helperVisibility,
    hideHelp,
    taskId
}) => {
    return(
        <div
            className={
                cx(
                    styles.helperSign,
                    {[styles.showHelp]: helperVisibility},
                    [styles[`artwork${taskId}`]]
                    )
            }
            onClick={hideHelp}
        />
    )
};

export default HelperSign;
