import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import styles from './TippsForHome.module.scss';

import Footer from '../../components/Footer/Footer';
import PictureBox from './components/PictureBox/PictureBox';
import EventPostit from './components/EventPostit/EventPostit';
import ScrollTo from '../../components/ScrollTo/ScrollTo';
import NavigationTopBar from '../../components/NavigationTopBar/NavigationTopBar';

import {ITip} from '../../services/schema/contentful';
import tip, {TipCollection} from '../../services/tips';
import {getClient} from "../../services/contentfulClient";
import {INLINES} from "@contentful/rich-text-types";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {calculateImageSize} from "../../helpers/calculateImageSize";

type TemporaryTipProps = {isDIY: boolean, isTop5: boolean};
let Header_box: any,
    imgWidth = calculateImageSize(650),
    imgHeight = calculateImageSize(497),
    imgWidthMobile = calculateImageSize(306),
    imgHeightMobile = calculateImageSize(234);

const TippsForHome: React.FC<TemporaryTipProps> = (props) => {
    const [tipItemList, settipItemList] = useState([] as ITip[]);

    useEffect(() => {
        tip.fetch().then(parsetipItemListResponse);
    }, []);

    const parsetipItemListResponse = (response: TipCollection) => {
        settipItemList(response.entries);
    };


    const [isLoading, setIsLoading] = useState(true);

    const promise_header_box = getClient().getEntry('5YzNhTSYJ1v52PNsNZLv9W')
        .then(function (entry: any) {
            return entry.fields;
        }),
        promise_header_box_top_5 = getClient().getEntry('1GQd5Nx5FqO2ZfG9yVpDW1')
            .then(function (entry: any) {
                return entry.fields;
            }),
        promise_header_box_diy = getClient().getEntry('3VX2c20M6Nfx7zdcZPw6y3')
            .then(function (entry: any) {
                return entry.fields;
            })
    ;

    Promise.all([promise_header_box, promise_header_box_top_5, promise_header_box_diy]).then(data => {
        if (props.isDIY) {
            Header_box = data[2];
        } else if (props.isTop5) {
            Header_box = data[1];
        } else {
            Header_box = data[0];
        }
        setIsLoading(false);
    })

    let currentList = tipItemList;

    const tipItemListOrdered = tipItemList.sort((a, b) => a.fields.order > b.fields.order ? -1 : 1)

    if (props.isDIY) {
        currentList = tipItemListOrdered.filter(function(currentElement) {
            return (currentElement.fields.hasOwnProperty('diyCategory') && currentElement.fields.diyCategory) ? currentElement : false;
        });
    } else if (props.isTop5) {
        currentList = tipItemListOrdered.filter(function(currentElement) {
            return (currentElement.fields.hasOwnProperty('top5Category') && currentElement.fields.top5Category) ? currentElement : false;
        });
    } else {
        currentList = tipItemListOrdered.filter(function(currentElement) {
            return (!currentElement.fields.top5Category && !currentElement.fields.diyCategory) ? currentElement : false;
        });
    }

    const isMobile = () => window.innerWidth < 760;
    const isBigDesktop = () => window.innerWidth > 1679;

    function renderTipList(tipItemListOrdered: any) {
        return tipItemListOrdered.map((element: any, id: number) => {

            const elementData = element.fields;
            const imageDesktop = (element.fields.image) ? element.fields.image.fields.file.url + '?w=' + imgWidth + '&h=' + imgHeight : '';
            const imageMobile = (element.fields.imageMobile) ? element.fields.imageMobile.fields.file.url + '?w=' + imgWidthMobile + '&h=' + imgHeightMobile : '';

            return (
                <div
                    key={id}
                    id={element.fields.anchor}
                    className={cx(
                        styles.exhibitionBox,
                        {[styles.smallElement]: element.subPostitSize === 'small'}
                    )}
                >
                    <div className={styles.eventBox}>
                        <PictureBox
                            className={styles.artTopSectionMiddle}
                            artworkSrc={isMobile() ? imageMobile : imageDesktop}
                            artworkName={elementData.authorName}
                            artworkDescription={elementData.imageDescription}
                            tapeTypeNumber={elementData.tapeTypeNumber}
                        />
                        <EventPostit
                            className={styles.cartTopSectionMiddle}
                            exhibitionName={elementData.tipName}
                            date={elementData.dateRange}
                            mainData={elementData.mainData}
                            kurator={elementData.kuratorName}
                            smallCartText={elementData.subtitleText}
                            subPostitBig={elementData.subTextBig}
                        />
                    </div>
                </div>
            )
        })

    }

    // TODO: ugly temporary solution with scroll, need to be more flexible if content will change
    return (
        <>
            <div
                className={styles.wrapper}
            >
                <NavigationTopBar type='tipps-fuer-zuhause'/>
                <section className={styles.sectionTop}>
                    <h1 className={styles.sectionTitle}>
                        Tipps für Zuhause {(props.isDIY) ? '- DIY' : ''} {(props.isTop5) ? '- Top 5' : ''}
                    </h1>

                </section>
                <section className={styles.introText}>
                    <h2 className={styles.subTitle}>{Header_box ? Header_box.title : ''}</h2>
                    {Header_box ? parseContentfulText(Header_box.text) : ''}
                </section>
                {renderTipList(currentList)}
            </div>
            <section className={styles.topLinkSection}>
                <ScrollTo className={styles.topLink}>NACH OBEN</ScrollTo>
            </section>
            <Footer/>
        </>
    )
};

function parseContentfulText(text: any) {
    const website_url = window.location.href;
    const options = {
        renderNode: {
            [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
                return ( <a href={node.data.target.fields.file.url} target="_blank"> {children} </a> )
            },
            [INLINES.HYPERLINK]: (node: any) => {
                return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
            }
        }
    };
    return documentToReactComponents(text, options);
}

export default TippsForHome;
