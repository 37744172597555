import React, {useEffect, useState} from 'react';
import Footer from '../../components/Footer/Footer';
import LinkPicture from '../GameSelection/components/LinkPicture/LinkPicture';
import styles from './FAQ.module.scss';
import Boar from './assets/Boar.png';
import BoarBig from './assets/BoarBig.png';
import Horse from './assets/Horse.png';
import Goat from './assets/Goat.png';
import NavigationTopBar from '../../components/NavigationTopBar/NavigationTopBar';
import PageTitle from '../../components/PageTitle/PageTitle';
import Postit from '../../components/Postit/Postit';
import {IFaq} from '../../services/schema/contentful';
import faq, {FaqCollection} from '../../services/faq';
import ScrollTo from "../../components/ScrollTo/ScrollTo";

const FAQ = () => {

    const [faqItemList, setFaqItemList] = useState([] as IFaq[]);

    useEffect(() => {
        faq.fetch().then(parseFaqItemListResponse);
    }, []);

    const parseFaqItemListResponse = (response: FaqCollection) => {
        setFaqItemList(response.entries);
    };

    return (
        <>
            <div className={styles.wrapper}>
                <NavigationTopBar
                    type='besucherinfo'
                    withBackLink={true}
                    backLinkHref="/besucherinfo"
                />
                <LinkPicture text="Plane deinen Besuch" href="/besucherinfo" className={styles.backLink}/>
                <PageTitle>
                    Tipps für deinen Besuch
                </PageTitle>
                <div className={styles.faqContent}>
                    <div className={styles.boarBox}>
                        <p className={styles.boarBubble}>
                            Kann ich im Museum was essen?
                        </p>
                        <img
                            className={styles.boar}
                            src={Boar}
                            alt=''
                        />
                        <div className={styles.boarCard}>
                            <Postit
                                postitTitle='CAFÉ'
                                plainText={faqItemList[0] ? faqItemList[0].fields.foodAnswer : ''}
                                subtext={faqItemList[0] ? faqItemList[0].fields.foodHintText : ''}
                            />
                        </div>
                        <img
                            className={styles.boarBig}
                            src={BoarBig}
                            alt=''
                        />
                    </div>
                    <div className={styles.horseBox}>
                        <p className={styles.horseBubble}>
                            Wie viel Zeit benötige ich für den Besuch?
                        </p>
                        <img
                            className={styles.horse}
                            src={Horse}
                            alt=''
                        />
                        <div className={styles.horseCard}>
                            <Postit
                                postitTitle='Zeitplanung'
                                plainText={faqItemList[0] ? faqItemList[0].fields.timeAnswer : ''}
                            />
                        </div>
                    </div>
                    <div className={styles.goatBox}>
                        <p className={styles.goatBubble}>
                            Was kann ich mit unseren Sachen und Jacken machen?
                        </p>
                        <img
                            className={styles.goat}
                            src={Goat}
                            alt=''
                        />
                        <div className={styles.goatCard}>
                            <Postit
                                postitTitle='Garderobe'
                                plainText={faqItemList[0] ? faqItemList[0].fields.wardrobeAnswer : ''}
                                subtext={faqItemList[0] ? faqItemList[0].fields.wardrobeHintText : ''}
                            />
                        </div>
                    </div>
                    <div className={styles.dogBox}>
                        <p className={styles.dogsBubbleLeft}>
                            Was wird Kindern am besten gefallen?
                        </p>
                        <p className={styles.dogsBubbleRight}>
                            Kann ich mein Baby im Museum wickeln?
                        </p>
                        <div className={styles.dogs}/>
                        <div className={styles.dogsCard}>
                            <Postit
                                postitTitle='Baby wickeln'
                                plainText={faqItemList[0] ? faqItemList[0].fields.babyAnswer : ''}
                                subtext={faqItemList[0] ? faqItemList[0].fields.babyHintText : ''}
                            />
                        </div>
                    </div>
                    <div>
                        <div className={styles.postitCard}>
                            <Postit
                                postitTitle='Kunst für Kinder'
                                plainText={faqItemList[0] ? faqItemList[0].fields.kidsAnswer : ''}
                                plainTextBottom={faqItemList[0] ? faqItemList[0].fields.kidsAnswerBottom : ''}
                                subtext={faqItemList[0] ? faqItemList[0].fields.kidsHintText : ''}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <section className={styles.topLinkSection}>
                <ScrollTo className={styles.topLink}>NACH OBEN</ScrollTo>
            </section>
            <Footer/>
        </>
    );
};

export default FAQ;
