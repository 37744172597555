import {Dispatch} from 'redux';
import {State} from '../interfaces/State';
import {ScreenName} from '../reducers/common';
import TagManager from "react-gtm-module";

export const setCurrentScreen = ({setCurrentScreen}: { setCurrentScreen: string }) => (name: ScreenName) => ({
    type: setCurrentScreen,
    payload: {name},
});

export const setInterfaceLockStatus = ({setInterfaceLockStatus}: { setInterfaceLockStatus: string }) =>
    (on: boolean) => ({
        type: setInterfaceLockStatus,
        payload: {on},
    });

export const setCurrentIntroIndex = ({setCurrentIntroIndex}: { setCurrentIntroIndex: string }) => (index: number) => ({
    type: setCurrentIntroIndex,
    payload: {index},
});

export const setCurrentOutroIndex = ({setCurrentOutroIndex}: { setCurrentOutroIndex: string }) => (index: number) => ({
    type: setCurrentOutroIndex,
    payload: {index},
});

export const decrementCurrentIntroIndex =
    (actionTypes: { setCurrentIntroIndex: string }, selectCurrentIntroIndex: (s: State) => number) =>
        (dispatch: Dispatch, getState: () => State) => {
            const currentIntroIndex = selectCurrentIntroIndex(getState());

            dispatch(setCurrentIntroIndex(actionTypes)(currentIntroIndex - 1));
        };

export const incrementCurrentIntroIndex =
    (actionTypes: { setCurrentIntroIndex: string }, selectCurrentIntroIndex: (s: State) => number) =>
        (dispatch: Dispatch, getState: () => State) => {
            const currentIntroIndex = selectCurrentIntroIndex(getState());

            dispatch(setCurrentIntroIndex(actionTypes)(currentIntroIndex + 1));
        };

export const decrementCurrentOutroIndex =
    (actionTypes: { setCurrentOutroIndex: string }, selectCurrentOutroIndex: (s: State) => number) =>
        (dispatch: Dispatch, getState: () => State) => {
            const currentOutroIndex = selectCurrentOutroIndex(getState());

            dispatch(setCurrentOutroIndex(actionTypes)(currentOutroIndex - 1));
        };

export const incrementCurrentOutroIndex =
    (actionTypes: { setCurrentOutroIndex: string }, selectCurrentOutroIndex: (s: State) => number) =>
        (dispatch: Dispatch, getState: () => State) => {
            const currentOutroIndex = selectCurrentOutroIndex(getState());

            dispatch(setCurrentOutroIndex(actionTypes)(currentOutroIndex + 1));
        };

export const setApplicationCounterTimer = (additional: Object = {}) => {
    let eventName = 'TimeOnPage';

    setTimeout(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: eventName,
                time: '0-10 seconds',
                ...additional
            },
        });
    }, 20);

    setTimeout(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: eventName,
                time: '11-30 seconds',
                ...additional
            },
        });
    }, 11000);

    setTimeout(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: eventName,
                time: '31-60 seconds',
                ...additional
            },
        });
    }, 31000);

    setTimeout(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: eventName,
                time: '61-180 seconds',
                ...additional
            },
        });
    }, 61000);

    setTimeout(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: eventName,
                time: '181-600 seconds',
                ...additional
            },
        });
    }, 181000);

    setTimeout(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: eventName,
                time: '601-1800 seconds',
                ...additional
            },
        });
    }, 601000);

    setTimeout(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: eventName,
                time: '1801+ seconds',
                ...additional
            },
        });
    }, 1801000);
};