import React, {Component} from 'react';
import {Helmet} from "react-helmet";

type MemoryCoreProps = {
};

class ConstructionSiteCore extends Component<MemoryCoreProps> {
    render() {

        return (
            <>
                <Helmet>
                    <script src="/kinder/assets/constructionSite/game.js" type="text/javascript" />
                    <link rel="stylesheet" type="text/css" href="/kinder/assets/constructionSite/game.css" />
                    <script>
                        var outroUrl = "kunstbau/outro"
                    </script>
                </Helmet>
                <div>
                    <div id="game"><canvas id="canvas"></canvas></div>
                </div>
            </>
        )
    }
}


export default (ConstructionSiteCore);
