import cx from 'classnames';
import React, {useEffect, useState} from 'react';
import ImageMapper from 'react-image-mapper';
import {animated, Transition} from 'react-spring/renderprops';
import {TaskData} from '../../../../gameData';
import styles from './Artwork.module.scss';
import HelperSign from '../HelperSign/HelperSign';

type ArtworkProps = {
    hidden?: boolean,
    onNext: () => void,
    task: TaskData,
    className: string,
    helperVisibility: boolean,
    hideHelp: () => void,
    taskId: number,
}

const MAX_IMAGE_WIDTH = .56;
const MAX_IMAGE_HEIGHT = .8;

const Artwork: React.FC<ArtworkProps> = ({
    className,
    hidden,
    onNext,
    task,
    helperVisibility,
    hideHelp,
    taskId,
}) => {
    const {image} = task;
    const {width: artworkWidth, height: artworkHeight, map, url} = image;

    const [currentHeight, setCurrentHeight] = useState(
        artworkHeight / artworkWidth * window.innerWidth * MAX_IMAGE_WIDTH);
    const [currentWidth, setCurrentWidth] = useState(window.innerWidth * MAX_IMAGE_WIDTH);

    const recalculateDimensions = () => {
        if ((artworkHeight / artworkWidth * window.innerWidth * MAX_IMAGE_WIDTH) > window.innerHeight * MAX_IMAGE_HEIGHT) {
            setCurrentHeight(window.innerHeight * MAX_IMAGE_HEIGHT);
            setCurrentWidth(artworkWidth / artworkHeight * window.innerHeight * MAX_IMAGE_HEIGHT);
        } else {
            setCurrentHeight(artworkHeight / artworkWidth * window.innerWidth * MAX_IMAGE_WIDTH);
            setCurrentWidth(window.innerWidth * MAX_IMAGE_WIDTH);
        }
    };

    useEffect(recalculateDimensions);

    useEffect(() => {
        window.addEventListener('resize', recalculateDimensions);
        return () => {
            window.removeEventListener('resize', recalculateDimensions)
        }
    }, []);

    return (
        <div className={cx(className, styles.imageContainer, {[styles.hidden]: hidden})}>
            <Transition
                unique
                reset
                items={task}
                from={{opacity: 0}}
                enter={{opacity: 1}}
                leave={{opacity: 0}}>
                {task => task && (style => (
                    <animated.div className={styles.image} style={style} key={url}>
                        <ImageMapper
                            strokeColor="transparent"
                            lineWidth={0}
                            src={url}
                            map={map}
                            onClick={onNext}
                            width={currentWidth}
                            height={currentHeight}
                            imgWidth={artworkWidth}
                            imgHeight={artworkHeight}
                        />
                    </animated.div>
                ))}
            </Transition>
            <HelperSign
                helperVisibility={helperVisibility}
                hideHelp={hideHelp}
                taskId={taskId}
            />
        </div>

    );
};

export default Artwork;