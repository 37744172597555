import {BubbleData} from '../../../../components/Bubble/Bubble';

export default [
    {
        text: 'Wusstest Du schon …?',
        variant: 'bubble3',
        //voiceFile: Audio1,
    },
    {
        text: 'Bevor der Holzwurm die ganze Tür auffrisst, packen ihn die Restauratorinnen in eine luftdichte Kiste.',
        variant: 'bubble3',
        //voiceFile: Audio1,
    },
    {
        text: 'He Piet, wir haben den Holzwurm gefangen!',
        variant: 'bubble3',
        //voiceFile: Audio1,
    },
] as BubbleData[];
