import React, {CSSProperties, ReactElement} from 'react';
import cx from 'classnames';
import {animated} from 'react-spring';
import ModalButton from './components/ModalButton/ModalButton';
import styles from './Modal.module.scss';

export enum ModalType {
    medium,
    long,
    short,
    withImage,
}

type ModalProps = {
    buttonCopy?: string,
    alternateButtonCopy?: string,
    thirdButtonCopy?: string,
    children: string | ReactElement,
    imgSrc?: string
    style?: CSSProperties,
    type?: ModalType,
    shouldShowGift?: boolean,
    onButtonClick?: () => void,
    onAlternateButtonClick?: () => void,
    onThirdButtonClick?: () => void,
}

const Modal: React.FC<ModalProps> = ({
    buttonCopy,
    alternateButtonCopy,
    thirdButtonCopy,
    children,
    imgSrc,
    onButtonClick,
    onAlternateButtonClick,
    onThirdButtonClick,
    style,
    type,
    shouldShowGift = false,
}) => {
    const shouldAddPadding = buttonCopy && onButtonClick && alternateButtonCopy && onAlternateButtonClick;
    const shouldAddImg = imgSrc && buttonCopy && alternateButtonCopy;
    return (
        <animated.div className={styles.modalWrapper}>
            <animated.div
                style={style}
                className={cx(
                    styles.modal, {
                        [styles.long]: type === ModalType.long,
                        [styles.short]: type === ModalType.short,
                        [styles.withImage]: type === ModalType.withImage,
                        [styles.morePadding]: shouldAddPadding,
                    })}
            >
                <div className={shouldAddImg ? styles.boxImage : ''}>
                    {children}
                    {
                        shouldShowGift && <div className={styles.giftModal} />
                    }
                    {
                        buttonCopy && onButtonClick &&
                        <ModalButton
                            className={cx(styles.button, { [styles.morePadding]: shouldAddPadding })}
                            copy={buttonCopy}
                            onClick={onButtonClick}
                        />
                    }
                    {
                        alternateButtonCopy && onAlternateButtonClick &&
                        <ModalButton
                            className={cx(styles.button, styles.alternate)}
                            type='secondary'
                            copy={alternateButtonCopy}
                            onClick={onAlternateButtonClick}
                        />
                    }
                    {
                        thirdButtonCopy && onThirdButtonClick &&
                        <ModalButton
                            className={cx(styles.button, styles.third)}
                            type='tertiary'
                            copy={thirdButtonCopy}
                            onClick={onThirdButtonClick}
                        />
                    }
                </div>
                {imgSrc &&
                    <img
                        className={styles.image}
                        alt=""
                        src={imgSrc}
                    />
                }
            </animated.div>
        </animated.div>
    )
};

export default Modal;