import React from 'react';
import Helmet from 'react-helmet';
import {Dispatch} from 'redux';
import {setCurrentScreen} from '../../../actions/common';
import {State} from '../../../interfaces/State';
import {ScreenName} from '../../../reducers/common';
import styles from './StudioGame.module.scss';
import ConstructionSiteCore from './StudioCore/StudioCore';
import StudioIntro from './StudioIntro/StudioIntro';
import {connect} from 'react-redux';
import StudioOutro from './StudioOutro/StudioOutro';
import {isGameFinished, selectCurrentScreen} from '../selectors';
import {actionTypes} from '../actions';

type StudioGameProps = {
    isGameFinished: boolean,
    handleGoToCore: () => void,
    handleGoToConversion: () => void,
    currentScreen: ScreenName,
}

const StudioGame: React.FC<StudioGameProps> = ({
   currentScreen,
   handleGoToCore,
   isGameFinished,
}) => {
    return (
        <div className={styles.StudioGame}>
            <Helmet>
                <body className={styles.memoryGameBody} />
            </Helmet>
            {
                !isGameFinished
                &&
                currentScreen === 'intro'
                &&
                <StudioIntro onFinish={handleGoToCore}/>
            }
            {
                !isGameFinished
                &&
                currentScreen === 'core'
                &&
                <ConstructionSiteCore/>
            }
            {
                isGameFinished
                &&
                (currentScreen === 'outro')
                &&
                <StudioOutro />
            }
        </div>
    );
};

const mapStateToProps = (state: State) => ({
    isGameFinished: isGameFinished(state),
    currentScreen: selectCurrentScreen(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    handleGoToCore: () => dispatch(setCurrentScreen(actionTypes)('core')),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudioGame);
