import React, {useState} from 'react';
import cx from 'classnames';
import styles from './Footer.module.scss';
import ErgoLogo from './assets/ergo-logo.svg';
import FacebookIcon from './assets/facebook.svg';
import InstagramIcon from './assets/insta.svg';
import TwitterIcon from './assets/twitter.svg';
import YoutubeIcon from './assets/youtube.svg';
import {getClient} from "../../services/contentfulClient";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {BLOCKS, INLINES} from "@contentful/rich-text-types";

type FooterProps = {
    className?: string,
}

const promise_address = getClient().getEntry('1WGwT7EyqQ1AlCjqowJ66o')
        .then(function (entry: any) {
            return entry.fields.text;
        }),
    promise_contact = getClient().getEntry('7IOisHteMEsivZskCGZl4q')
        .then(function (entry: any) {
            return entry.fields.text;
        }),
    promise_opening_hours = getClient().getEntry('52tJAEFTyn3JQdKvhaTTWF')
        .then(function (entry: any) {
            return entry.fields.text;
        }),
    promise_menu = getClient().getEntry('4knxxb9HwXbQuGO4kKac1T')
        .then(function (entry: any) {
            return entry.fields.text;
        })
;

let address: any,
    contact: any,
    opening_hours: any,
    menu: any;

Promise.all([promise_address, promise_contact, promise_opening_hours, promise_menu]).then(data => {
    address = parseText(data[0]);
    contact = parseText(data[1]);
    opening_hours = parseText(data[2]);
    menu = parseText(data[3]);
});

const Footer: React.FC<FooterProps> = ({className}) => (
    // 1WGwT7EyqQ1AlCjqowJ66o address
    // 7IOisHteMEsivZskCGZl4q contact
    // 52tJAEFTyn3JQdKvhaTTWF opening hours
    // 4knxxb9HwXbQuGO4kKac1T menu
    <div className={cx(styles.footer, className)}>
        <section className={styles.newsletter}>
            <div className={styles.newsletterCopy}>Immer auf dem Laufenden bleiben</div>

            <a href="https://www.kunstpalast.de/de/newsletter-anmeldung"
               target="_blank"
               rel="noopener noreferrer"
               className={styles.newsletterSubscribeLink}
            >
                jetzt unseren Newsletter abonnieren
            </a>
        </section>
        <section className={styles.middleSectionWrapper}>
            <div className={styles.middleSection}>
                <div className={styles.middleSectionLeft}>
                    <section >
                        {address}
                    </section>
                    <section>
                        {contact}
                    </section>
                    <section>
                        {opening_hours}
                    </section>
                </div>
                <section className={styles.middleSectionCenter}>
                    <h1>Folgen Sie uns</h1>
                    <div className={styles.socialMediaIcons}>
                        <a href="https://www.facebook.com/kunstpalastduesseldorf/"
                           rel="noopener noreferrer"
                           target="_blank">
                            <img className={styles.socialMediaIcon} alt="Facebook" src={FacebookIcon}/>
                        </a>
                        <a href="https://www.instagram.com/kunstpalast/"
                           rel="noopener noreferrer"
                           target="_blank">
                            <img className={styles.socialMediaIcon} alt="Instagram" src={InstagramIcon}/>
                        </a>
                        <a href="https://www.youtube.com/user/museumkunstpalast?feature=mhum"
                           rel="noopener noreferrer"
                           target="_blank">
                            <img className={styles.socialMediaIcon} alt="Youtube" src={YoutubeIcon}/>
                        </a>
                        <a href="https://twitter.com/kunstpalast"
                           target="_blank"
                           rel="noopener noreferrer">
                            <img className={styles.socialMediaIcon} alt="Facebook" src={TwitterIcon}/>
                        </a>
                    </div>
                </section>
                <nav className={styles.middleSectionRight}>
                    {menu}
                </nav>
            </div>
        </section>
        <footer className={styles.poweredBy}>
            <p>Powered by <img className={styles.ergoLogo} alt="ERGO" src={ErgoLogo}/></p>
            <p>© {new Date().getFullYear()} Copyright Kunstpalast Düsseldorf</p>
        </footer>
    </div>
);

function parseText(string: any) {
    return documentToReactComponents(string, {
        renderNode: {
            [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
                return ( <a href={node.data.target.fields.file.url} target="_blank"> {children} </a> )
            },
            [INLINES.HYPERLINK]: (node: any) => {
                return <a href={node.data.uri} target={`${node.data.uri.startsWith(window.location.href) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(window.location.href) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
            }

        },
        renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text])
    })
}

export default Footer;
