import React from 'react';
import styles from './QuizQuestionNumber.module.scss';

type QuizQuestionNumberProps = {
    current: number,
    count: number,
}

const QuizQuestionNumber: React.FC<QuizQuestionNumberProps> = ({ current, count }) => (
    <div className={styles.questionNumber}>
        {current}/{count}
    </div>
);

export default QuizQuestionNumber;