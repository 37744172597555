import React, {CSSProperties, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Spritesheet from 'react-responsive-spritesheet';
import {animated, config} from 'react-spring';
import {Transition} from 'react-spring/renderprops-universal';
import OutroModal from '../../../../components/OutroModal/OutroModal';
import playSoundIfNotMuted, {stopSound} from '../../../../helpers/playSound';
import {State} from '../../../../interfaces/State';
import Gift from './assets/desktop/gift.png';
import {
    selectCorrectAnswersCount,
    selectQuestionsCount,
    selectCurrentScreen,
} from '../../selectors';
import styles from './QuizOutro.module.scss';
import OutroSpritesheet from './assets/curator_outro_spritesheet.png';
import OutroDesktopSpritesheet from './assets/desktop/curator_outro_spritesheet.png';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {getClient} from "../../../../services/contentfulClient";

interface QuizOutroProps extends RouteComponentProps {
    correctAnswersCount: number,
    style?: CSSProperties,
    questionsCount: number,
    currentScreen: string,
}
let winning_text: any;
const outroVoiceFile = require('./assets/voice/58.mp3');
const modalVoiceFile = require('./assets/voice/59.mp3');

const getOutroBubbleText = (points: number, pointsMax: number) =>
    <>Du hast <span className={styles.bubbleScore}>{points} von {pointsMax} Materialien</span> erraten.
        Vielen Dank für Deine Hilfe bei der Ausstellung! Klicke auf das Geschenk, um deinen Preis zu bekommen.</>;

const QuizOutro: React.FC<QuizOutroProps> = ({
    correctAnswersCount,
    questionsCount,
    style,
    history,
}) => {
    const [shouldShowBubble, setShouldShowBubble] = useState(false);
    const [shouldShowModal, setShouldShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    getClient().getEntry('3v2p88XnFSRzrLiIk9XtE1')
        .then(function (entry: any) {
            winning_text = entry.fields;
            setIsLoading(false);
        })


    const spriteProps = {
        steps: 6,
        fps: 9,
        loop: true,
        onLoopComplete: (spritesheet: any) => {
            if (spritesheet.getInfo('completeLoopCicles') === 0) {
                spritesheet.setStartAt(3);
                spritesheet.setEndAt(6);
            }
            if (spritesheet.getInfo('completeLoopCicles') === 4) {
                spritesheet.setStartAt(1);
                spritesheet.setEndAt(6);
                spritesheet.setDirection('rewind');
            }
            if (spritesheet.getInfo('completeLoopCicles') === 5) {
                spritesheet.pause();
            }
        },
    };

    useEffect(() => {
        const timer = setTimeout(() => setShouldShowBubble(true), 400);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (shouldShowModal) {
            playSoundIfNotMuted(modalVoiceFile)
        } else {
            (playSoundIfNotMuted(outroVoiceFile))
        }
        return () => stopSound()
    }, [shouldShowModal]);

    const handleOpenModal = () => {
        setShouldShowBubble(false);
        setShouldShowModal(true);
    };

    return (
        <animated.div style={style}>
            <div className={styles.artPieces}>
                <div className={styles.artPiece01}/>
                <div className={styles.artPiece02}/>
                <div className={styles.artPiece03}/>
                <div className={styles.artPiece04}/>
            </div>
            <div className={styles.posts}/>
            <div className={styles.floor}/>
            <Spritesheet
                image={OutroSpritesheet}
                className={styles.spritesheet}
                widthFrame={480}
                heightFrame={316}
                {...spriteProps}
            />
            <Spritesheet
                image={OutroDesktopSpritesheet}
                className={styles.spritesheetDesktop}
                widthFrame={532}
                heightFrame={800}
                {...spriteProps}
            />
            <Transition
                unique
                reset
                items={shouldShowBubble}
                from={{opacity: 0, transform: 'translate3d(30%, 80%, 0) scale(0.00001)'}}
                enter={{opacity: 1, transform: 'translate3d(0, 0, 0) scale(1)'}}
                leave={{opacity: 0}}
                config={config.wobbly}
            >
                {(shouldShowBubble: boolean) => shouldShowBubble && (style => (
                    <animated.div
                        className={styles.bubble}
                        style={style}
                    >
                        {getOutroBubbleText(correctAnswersCount, questionsCount)}
                    </animated.div>
                ))}
            </Transition>
            <Transition
                unique
                reset
                items={shouldShowModal}
                from={{opacity: 0, transform: 'translate3d(-50%, -70%, 0) scale(0.00001)'}}
                enter={{opacity: 1, transform: 'translate3d(-50%, -50%, 0) scale(1)'}}
                leave={{opacity: 0}}
                config={config.wobbly}
            >
                {(shouldShow: boolean) => shouldShow && (style => (
                    <OutroModal style={style} history={history} infoForVisitorsSlideNumber={3} winning_text={winning_text}/>
                ))}
            </Transition>
            <img
                className={styles.gift}
                src={Gift}
                alt=""
                onClick={handleOpenModal}
            />
        </animated.div>
    )
};

const mapStateToProps = (state: State) => ({
    correctAnswersCount: selectCorrectAnswersCount(state),
    questionsCount: selectQuestionsCount(state),
    currentScreen: selectCurrentScreen(state),
});

export default withRouter(connect(mapStateToProps)(QuizOutro));
