import React, {useEffect} from 'react';
import cx from 'classnames';
import styles from './Navigation.module.scss';
import debounce from 'lodash.debounce';
import handleNext from '../../helpers/handleNext';
import handlePrevious from "../../helpers/handlePrevious";

type NavigationProps = {
    currentIndex: number;
    decrementIndex: () => void;
    incrementIndex: () => void;
    mockData: any[];
    onFinish: () => void;
}

const Navigation: React.FC<NavigationProps> = ({
    currentIndex,
    decrementIndex,
    incrementIndex,
    mockData,
    onFinish
}) =>{

    const isFirstItem = (index: number) => index === 0;

    const handleKeyPress = (event: KeyboardEvent) => {
        event.stopImmediatePropagation();
        switch (event.key) {
            case 'ArrowLeft':
                handlePrevious(
                    currentIndex,
                    decrementIndex
                );
                break;
            case 'ArrowRight':
                handleNext(
                    currentIndex,
                    incrementIndex,
                    mockData,
                    onFinish
                );
                break;
        }
    };

    const debouncedHandleKeyPress = debounce(handleKeyPress, 1400, {leading: true});

    useEffect(
        () => {
            document.addEventListener('keyup', debouncedHandleKeyPress);
            return () => {
                document.removeEventListener('keyup', debouncedHandleKeyPress)
            }
        },
    );

    return (
        <>
            <div
                 className={styles.arrowNext}
                 onClick={() => handleNext(
                    currentIndex,
                    incrementIndex,
                    mockData,
                    onFinish)
                 }
            />
            <div
                className={cx(styles.arrowPrevious, {[styles.firstView]: isFirstItem(currentIndex)})}
                onClick={() => handlePrevious(
                    currentIndex,
                    decrementIndex
                )}
            />
        </>
    );
};

export default Navigation;
