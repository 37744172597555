import React, {Component} from 'react';
import cx from 'classnames';
import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import {State} from '../../../../interfaces/State';
import {
    chooseTile,
    getImages,
    setShuffle,
    startRound,
    goToNextRound,
} from '../../actions';
import {Dispatch} from 'redux';
import {MemoryImageData, PlayerName} from '../../interfaces/MemoryState';
import {
    isUserTurn,
    isTileChosen as isTileChosenSelector,
    selectCurrentPlayer,
    selectPlayerScore,
    selectTiles,
    isCurrentPlayer,
    isInterfaceLocked,
    hasRoundJustStarted,
    hasRoundJustFinished,
    selectImagesForCurrentRound,
    shouldShowRoundStartModal,
    selectCurrentRoundNumber,
    selectWinningPlayer,
    selectRoundWinningPlayer,
    selectPlayerGameScore,
} from '../../selectors';
import GameInformation from './components/GameInformation/GameInformation';
import styles from './MemoryCore.module.scss';
import PointsCounter from './components/PointsCounter/PointsCounter';
import RoundModal from './components/RoundModal/RoundModal';
import OptionBox from '../../../../components/OptionBox/OptionBox';
import Logo from '../../../../components/Logo/Logo';

const getTileColorClass = (roundNumber: number): string => {
    if ([2, 5].includes(roundNumber)) {
        return styles.tileYellow;
    }
    if ([3, 6].includes(roundNumber)) {
        return styles.tileGreen;
    }
    return styles.tileBlue;
}


type MemoryCoreProps = {
    chooseTile: (tileId: number) => () => void,
    cpuGameScore: number,
    cpuScore: number,
    currentPlayer: PlayerName,
    getImages: () => void,
    images: MemoryImageData[],
    initShuffle: () => void,
    isCurrentPlayer: (playerName: PlayerName) => boolean,
    isInterfaceLocked: boolean,
    hasRoundJustStarted: boolean,
    hasRoundJustFinished: boolean,
    isUserTurn: boolean,
    isTileChosen: (tileId: number) => boolean,
    roundNumber: number,
    shouldShowRoundStartModal: boolean,
    startRound: () => void,
    finishRound: () => void,
    tiles: Array<number | undefined>,
    userGameScore: number,
    userScore: number,
    winner?: PlayerName,
};

class MemoryCore extends Component<MemoryCoreProps> {

    async componentDidMount() {
        await this.props.getImages();
        this.props.initShuffle();
    }

    render() {
        const {
            chooseTile,
            cpuGameScore,
            cpuScore,
            images,
            isCurrentPlayer,
            isInterfaceLocked,
            hasRoundJustStarted,
            hasRoundJustFinished,
            isTileChosen,
            roundNumber,
            shouldShowRoundStartModal,
            startRound,
            finishRound,
            tiles,
            userGameScore,
            userScore,
            winner,
        } = this.props;

        return (
            <>
                <div className={styles.gameArea}>
                    <div className={styles.scores}>
                        <GameInformation
                            dataLeft={roundNumber+1}
                            text='runde'
                        />
                            <Logo wrapperClassName={styles.logo}/>
                        <div className={styles.margeSign}/>
                        <OptionBox />
                        <GameInformation
                            dataRight={cpuGameScore}
                            dataLeft={userGameScore}
                            divider=':'
                            text='spielstand'
                        />
                    </div>
                    <div className={styles.pointBoxes}>
                        <PointsCounter score={userScore} character="user" isActive={isCurrentPlayer('user')}/>
                        { !hasRoundJustStarted &&
                            <p className={styles.scoreLabel}>
                                {
                                    isCurrentPlayer('user')
                                    ? <span>Du bist am Zug!</span>
                                    : <span className={styles.labelBird}>Der Vogel ist am Zug!</span>
                                }
                            </p>
                        }
                        <PointsCounter score={cpuScore} character="cpu" isActive={isCurrentPlayer('cpu')}/>
                    </div>
                    <div className={styles.blanketArea}>
                        <div className={styles.rhino} />
                        <div className={styles.bird} />
                        <div className={styles.tiles}>{
                            tiles && tiles.map((imageId, index) => (
                                <div
                                    className={cx(styles.tile, {[styles.chosen]: isTileChosen(index)})}
                                    key={index}>{imageId !== undefined ? <><img
                                    className={styles.tileImg}
                                    src={images[imageId].imgUrl}
                                    alt=""
                                />
                                    <div
                                        onClick={!isTileChosen(index) && !isInterfaceLocked ? chooseTile(index) : undefined}
                                        className={`${styles.tileUnflipped} ${getTileColorClass(roundNumber+1)}`}/>
                                </> : undefined}
                                </div>),
                            )
                        }</div>
                    </div>
                </div>
                <RoundModal
                    shouldShowModal={shouldShowRoundStartModal || hasRoundJustFinished}
                    hasRoundJustFinished={hasRoundJustFinished}
                    startRound={startRound}
                    finishRound={finishRound}
                    roundNumber={roundNumber}
                    userScore={userScore}
                    cpuScore={cpuScore}
                    winner={winner}
                />
            </>
        )
    }
}

const mapStateToProps = (state: State) => ({
    currentPlayer: selectCurrentPlayer(state),
    cpuGameScore: selectPlayerGameScore('cpu')(state),
    cpuScore: selectPlayerScore('cpu')(state),
    images: selectImagesForCurrentRound(state),
    isUserTurn: isUserTurn(state),
    isCurrentPlayer: (playerName: PlayerName) => isCurrentPlayer(playerName)(state),
    isInterfaceLocked: isInterfaceLocked(state),
    hasRoundJustStarted: hasRoundJustStarted(state),
    hasRoundJustFinished: hasRoundJustFinished(state),
    isTileChosen: (tileId: number) => isTileChosenSelector(tileId)(state),
    roundNumber: selectCurrentRoundNumber(state),
    roundWinner: selectRoundWinningPlayer(state),
    winner: selectWinningPlayer(state),
    shouldShowRoundStartModal: shouldShowRoundStartModal(state),
    tiles: selectTiles(state),
    userGameScore: selectPlayerGameScore('user')(state),
    userScore: selectPlayerScore('user')(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    chooseTile: (tileId: number) => () => dispatch(chooseTile(tileId) as any),
    getImages: async () => await dispatch(getImages as any),
    initShuffle: () => dispatch(setShuffle(0) as unknown as AnyAction),
    startRound: () => dispatch(startRound),
    finishRound: () => dispatch(goToNextRound as unknown as AnyAction),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemoryCore);