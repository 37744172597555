import React from 'react';
import memoryIntroData from "./memoryIntroData";
import RhinoSpritesheet from "./assets/memory_intro_rhino_spritesheet.png";
import BirdSpritesheet from "./assets/memory_intro_bird_spritesheet.png";
import MemoryDecorationBox from "../components/MemoryDecorationBox/MemoryDecorationBox";
import {setApplicationCounterTimer} from "../../../../actions/common";

type MemoryIntroProps = {
    onFinish: () => void,
}

const MemoryIntro: React.FC<MemoryIntroProps> = ({
    onFinish
}) => {
    setApplicationCounterTimer();
    return (
        <MemoryDecorationBox
            birdWidthFrame={300}
            birdHeightFrame={335}
            rhinoWidthFrame={300}
            rhinoHeightFrame={441}
            BirdSpritesheet={BirdSpritesheet}
            memoryScene={'Intro'}
            bubbleData={memoryIntroData}
            onFinish={onFinish}
            RhinoSpritesheet={RhinoSpritesheet}
        />
    );
};

export default MemoryIntro;