import {BubbleData} from '../../../../components/Bubble/Bubble';
//const Audio1 = require('./assets/3.mp3');

export default [
    {
        text: 'Oh nein, Holzwürmer fressen sich durch unsere wertvolle Tür.',
        variant: 'bubble3',
        //voiceFile: Audio1,
    },
    {
        text: 'Restauratorin Nele will die Kunst schützen.',
        variant: 'bubble3',
        //voiceFile: Audio1,
    },
    {
        text: 'Allerdings ist ein Wurm entwischt!',
        variant: 'bubble3',
        //voiceFile: Audio1,
    },
] as BubbleData[];
