import {BubbleData} from '../../../../components/Bubble/Bubble';
//const Audio1 = require('./assets/3.mp3');

export default [
    {
        text: 'Es gibt Kunstwerke, die sind aus nur einer einzigen Linie gemalt.',
        variant: 'bubble3',
        //voiceFile: Audio1,
    },
    {
        text: 'Ohne den Stift abzusetzen.',
        variant: 'bubble3',
        //voiceFile: Audio1,
    },
    {
        text: 'Also wie das Haus des Nikolaus, nur schwieriger. Und jetzt Du ...',
        variant: 'bubble3',
        //voiceFile: Audio1,
    },
] as BubbleData[];
